<template>
    <VueFinalModal
        content-transition="fade-scale"
        content-class="h-full"
        :lock-scroll="false"
        :reserve-scroll-bar-gap="false">
        <div class="flex h-full w-screen cursor-pointer items-center justify-center overflow-hidden py-16" @click.self="() => emit('close')">
            <div class="relative m-auto h-auto min-h-0 w-11/12 max-w-2xl cursor-auto rounded bg-white lg:max-w-3xl">
                <div class="relative flex h-auto flex-col">
                    <ModalCloseButton class="right-6 top-6 z-50 md:-right-2.5 md:-top-4" @click="emit('close')" />
                    <div class="h-auto max-h-[calc(100vh-15rem)] w-full overflow-y-auto overflow-x-hidden p-6 lg:p-8">
                        <div class="flex flex-col gap-6">
                            <div class="flex items-start justify-between gap-8">
                                <slot />
                            </div>
                        </div>
                    </div>
                    <slot name="bottom" />
                </div>
            </div>
        </div>
    </VueFinalModal>
</template>

<script setup lang="ts">
    import { VueFinalModal } from 'vue-final-modal'
    import ModalCloseButton from './ModalCloseButton.vue'
    const emit = defineEmits<{
        (e: 'close'): void
    }>()
</script>
