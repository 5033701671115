import type { RouterConfig } from '@nuxt/schema'

async function findEl (hash: string, x: number = 0): Promise<Element | null> {
    const element = document.querySelector(hash)
    if (element) {
        return element
    }
    return new Promise<Element | null>(resolve => {
        if (x > 50) {
            return resolve(null)
        }
        setTimeout(() => {
            resolve(findEl(hash, x + 1))
        }, 100)
    })
}

export async function findHashElPosition (hash: string, path: string): Promise<ScrollToOptions | undefined> {
    const el = await findEl(hash)
    if (el && el instanceof HTMLElement) {
        const header = await findEl('#header') as HTMLElement
        if (path.endsWith('shop') || path.endsWith('pickup')) {
            const element = document.getElementById('EmergencyBreakNotification')
            if (typeof (element) !== 'undefined' && element !== null) {
                const shopContainer = await findEl('#shopContainer') as HTMLElement
                const shopNotifications = await findEl('#shopNotifications') as HTMLElement
                return {
                    top: el.offsetTop + (shopNotifications.offsetHeight + Number.parseInt(window.getComputedStyle(shopContainer).getPropertyValue('padding-top'))),
                    left: 0
                }
            }
            return { top: el.offsetTop, left: 0 }
        }
        return { top: (el.offsetTop - header.offsetHeight), left: 0 }
    }
}

export default <RouterConfig>{
    scrollBehavior: async (to, from, savedPosition) => {
        if ((to.path.endsWith('/shop') && from.path.endsWith('/pickup')) || (to.path.endsWith('/pickup') && from.path.endsWith('/shop'))) {
            return null
        }
        if (to.hash && to.hash !== '') {
            return await findHashElPosition(to.hash, to.path)
        }
        if (savedPosition) {
            return savedPosition
        }
        return {
            top: 0,
            left: 0
        }
    }
}
