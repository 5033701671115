<template>
    <div class="grid min-h-screen place-content-center overflow-hidden py-36 font-sans antialiased" :class="webshopSettings().errorBackgroundClasses">
        <div class="z-20 flex max-w-[32.5rem] flex-col items-center justify-center space-y-8 text-center">
            <NuxtImg
                v-if="!webshopSettings().logo('white').includes('foodticket-logo')"
                :src="webshopSettings().logo('white')"
                class="max-h-full w-2/4"
                alt="Logo"
                height="140"
                quality="100" />
            <img v-else :src="!webshopSettings().global ? webshopSettings().logo('white') : webshopSettings().logo()" class="max-h-full w-2/4" alt="Logo" />
            <h1 class="text-8xl font-medium" v-text="error.statusCode" />
            <p
                class="px-8 text-xl font-light leading-tight sm:px-0 sm:text-4xl"
                v-html="
                    error.statusCode === 503
                        ? 'We are experiencing technical issues, please try again in a few minutes.<br/><br/>Our engineers are resolving the issue.'
                        : error.statusMessage
                " />
            <button
                v-if="error.statusCode !== 503"
                class="cursor-pointer rounded-md px-4 py-2 font-bold sm:px-6 sm:py-3 sm:text-xl"
                :class="webshopSettings().errorButtonClasses"
                @click="handleError">
                Back to home
            </button>
            <div v-if="(error.data || error.stack) && displayErrors()" class="space-y-4">
                <template v-if="error.data">
                    <h2 class="italic">
                        Raw GraphQL error data (only visible on local):
                    </h2>
                    <div class="relative rounded-md bg-white p-1">
                        <button class="absolute right-8 top-4 rounded-md bg-black p-2 text-white" @click="copyError(error.data)">
                            Copy
                        </button>
                        <pre class="max-h-96 max-w-4xl overflow-scroll p-4 text-left text-black" v-text="isJSON(error.data) ? JSON.parse(error.data) : error.data" />
                    </div>
                </template>
                <template v-if="error.stack">
                    <h2 class="italic">
                        Nuxt Error (SSR):
                    </h2>
                    <p v-if="error.message">
                        {{ error.message }}
                    </p>
                    <div class="relative rounded-md bg-white p-1">
                        <button class="absolute right-8 top-4 rounded-md bg-black p-2 text-white" @click="copyError(error.stack)">
                            Copy
                        </button>
                        <pre class="max-h-96 max-w-4xl overflow-scroll p-4 text-left text-black" v-html="error.stack" />
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup async lang="ts">
    // TODO: Fix issue with flickering button in production. It has to do with useHead running both on client and server side and has probably something to do with https://github.com/nuxt/nuxt/issues/22091
    // TODO: Link button to translated homepage
    // TODO: Make the back to home button link to the homepage of the current language (or the main language)
    import { webshopSettings } from '~/store/webshopSettings'
    interface Error {
        error: {
            statusCode: number
            statusMessage: string
            message: string
            data: string
            stack: string
        }
    }
    const props = defineProps<Error>()
    function copyError (error: string) {
        navigator.clipboard.writeText(error)
    }
    function isJSON (str) {
        try {
            return (JSON.parse(str) && !!str)
        } catch (e) {
            return false
        }
    }
    const route = useRoute()
    function displayErrors () {
        return route?.query?.showErrors
    }
    const currentLang = useI18n()
    function handleError () {
        const currentLangIsAllowed = currentLang?.locale?.value
            ? webshopSettings().availableLanguages.some(availableLang => availableLang.code === currentLang?.locale?.value)
            : false
        const targetLanguage = currentLangIsAllowed ? currentLang.locale.value : webshopSettings().defaultLanguage
        clearError({ redirect: `/${targetLanguage}` })
    }
    const i18nHead = useLocaleHead({
        addSeoAttributes: {
            canonicalQueries: [ 'foo' ]
        }
    })
    useHead({
        htmlAttrs: {
            lang: i18nHead.value.htmlAttrs!.lang
        },
        title: `${webshopSettings()?.getSeoTitle || 'Foodticket Webshop'} - ${props.error.statusCode}`,
        style: [
            {
                innerHTML: webshopSettings().primaryColorCssVariable
            }
        ]
    })
</script>
