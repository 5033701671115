<template>
    <component
        :is="tag"
        :href="href"
        :to="to"
        :disabled="disabled"
        class="flex w-full items-center justify-center rounded-full border-2 border-solid text-center uppercase"
        :class="{
            'cursor-not-allowed text-white': disabled,
            'border-gray-600 bg-gray-600': disabled && variant === 'primary',
            'border-gray-300 bg-gray-300': disabled && variant === 'secondary',
            'cursor-pointer': !disabled,
            'border-primary bg-primary text-white': !disabled && variant === 'primary',
            'border-primary bg-transparent text-red-500': !disabled && variant === 'secondary',
            'p-4 text-sm font-bold uppercase leading-6': size === 'regular',
            'px-3.5 py-3 leading-5': size === 'small'
        }">
        <slot />
    </component>
</template>

<script setup lang="ts">
    interface BaseButtonProps {
        to?: string
        href?: string
        tag?: string
        disabled?: boolean
        variant?: 'primary' | 'secondary'
        size?: 'small' | 'regular'
    }
    const props = withDefaults(defineProps<BaseButtonProps>(), {
        variant: 'primary',
        size: 'regular'
    })
    const tag = computed(() => {
        if (props.href) {
            return 'a'
        }
        if (props.to) {
            return resolveComponent('NuxtLink')
        }
        if (props.tag) {
            return props.tag
        }
        return 'div'
    })
</script>
