import { type VueGtmUseOptions, createGtm } from '@gtm-support/vue-gtm'
import { defineNuxtPlugin, useRouter } from 'nuxt/app'
import { webshopSettings } from '~/store/webshopSettings'

export default defineNuxtPlugin(nuxtApp => {
    if (process.client) {
        const router = useRouter()
        const googleTagManagerId = webshopSettings()?.global?.googleTagManagerId
        const gaProperty = webshopSettings()?.global?.gaProperty
        if (googleTagManagerId) {
            try {
                nuxtApp.vueApp.use(
                    createGtm({
                        id: googleTagManagerId,
                        vueRouter: router ? (router as VueGtmUseOptions['vueRouter']) : undefined
                    })
                )
            } catch (error) {
                console.warn(error)
            }
        }
        if (gaProperty) {
            useHead({
                script: [
                    {
                        src: `https://www.googletagmanager.com/gtag/js?id=${gaProperty}`,
                        type: 'text/javascript',
                        async: true
                    },
                    {
                        key: 'gaProperty',
                        innerHTML: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}gtag('js', new Date()); gtag('config', '${gaProperty}');`
                    }
                ]
            })
        }
    }
})
