export const registerUser = gql`
    mutation registerUser(
        $email: String!
        $password: String!
        $hasAgreedToTerms: Boolean!
        $firstName: String!
        $lastName: String!
    ) {
        register(
            input: {
                email: $email
                password: $password
                hasAgreedToTerms: $hasAgreedToTerms
                customer: {
                    upsert: {
                        firstName: $firstName
                        lastName: $lastName
                    }
                }
            }
        ) {
            token
            status
        }
    }
`
