import { submit } from '@formkit/inputs'
submit.props = [ 'inverted', 'tight' ]
submit.features = [
    node => {
        node.props.inputClass = (reactiveNode: { props: { inverted?: boolean, tight?: boolean } }) => ({
            'is-inverted': reactiveNode.props.inverted,
            'is-tight': reactiveNode.props.tight
        })
    }
]
export default submit
