import { en, fr, nl } from '@formkit/i18n'
import '@formkit/pro/genesis'
import { defineFormKitConfig } from '@formkit/vue'
import { genesisIcons } from '@formkit/icons'
import { createProPlugin, datepicker } from '@formkit/pro'
import { rootClasses } from './formkit.theme.mjs'
import submit from '~/formkit/inputs/submit'
import stepNumber from '~/formkit/inputs/stepNumber'
import { addAsteriskPlugin } from '~/formkit/plugins/asteriskWhenRequired'
import { scrollToErrors } from '~/formkit/plugins/scrollToErrors'
const pro = createProPlugin('fk-728317b681', { datepicker })
// TODO: move to separate file

export default defineFormKitConfig({
    plugins: [ pro, addAsteriskPlugin, scrollToErrors ],
    locales: { en, fr, nl },
    locale: 'nl',
    icons: {
        ...genesisIcons
    },
    config: {
        rootClasses
    },
    inputs: {
        submit,
        stepNumber
    }
})
