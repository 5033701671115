import type { accountLogin, newUser, userLogin } from '~/types/local-types'
import { apiCallMutation } from '~/composables/apiCall'
import { loginUser } from '~/graphql/mutations/loginUser.Mutation.gql'
import { registerUser } from '~/graphql/mutations/registerUser.mutation.gql'
import { logoutUser } from '~/graphql/mutations/logoutUser.mutation.gql'

export const account = defineStore('account', {
    state: (): userLogin => <userLogin>({
        email: '',
        accountToken: ''
    } as userLogin),
    persist: {
        storage: persistedState.localStorage
    },
    getters: {
        getAuthEnabled (): boolean {
            const { public: { authEnabled } } = useRuntimeConfig()
            if (authEnabled) {
                return authEnabled
            } else {
                return false
            }
        },
        getIsLoggedIn (): boolean {
            return this.accountToken.length > 1
        }
    },
    actions: {
        async setAccountToken (accountToken: string) {
            this.accountToken = accountToken
        },
        async loginUser (accountLogin: accountLogin) {
            const variables = { email: accountLogin.email, password: accountLogin.password }
            const { mutate } = apiCallMutation(loginUser, variables)
            await mutate().then(response => {
                if (response?.data?.login?.token) {
                    this.setAccountToken(response.data.login.token)
                }
            }).catch(error => {
                return error
            })
        },
        async registerUser (newUser: newUser) {
            const variables = {
                email: newUser.email,
                password: newUser.password,
                hasAgreedToTerms: newUser.hasAgreedToTerms,
                firstName: newUser.firstName,
                lastName: newUser.lastName
            }
            const { mutate } = apiCallMutation(registerUser, variables)
            await mutate().then(response => {
                const localePath = useLocalePath()
                if (response?.data?.register?.status === 'SUCCESS') {
                    this.setAccountToken(response.data.register.token)
                    navigateTo(localePath('/account'))
                }
            }).catch(error => {
                return error
            })
        },
        async logoutUser () {
            const { mutate } = apiCallMutation(logoutUser, {}, true)
            await mutate().then(response => {
                if (response?.data?.logout?.status === 'TOKEN_REVOKED') {
                    this.setAccountToken('')
                }
            }).catch(error => {
                return error
            })
        }
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(account, import.meta.hot))
}
