import type { FormKitNode } from '@formkit/core'

export function scrollToErrors (node: FormKitNode) {
    if (node.props.type === 'form') {
        function scrollTo (node: FormKitNode) {
            if (node.props.id) {
                // Scroll to the element with the id, or the element with the name (for groups). For groups, please wrap the group in a div with the id.
                const el = document.getElementById(node.props.id) || document.getElementById(node.name)
                if (el) {
                    el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
                }
            }
        }

        function scrollToErrors () {
            let scrolled = false
            node.walk((child: FormKitNode) => {
                // Check if this child has errors
                if (child.type !== 'group' && (child.ledger.value('blocking') || child.ledger.value('errors'))) {
                    if (!scrolled) {
                        // We found an input with validation errors
                        scrollTo(child)
                        scrolled = true
                    }
                    // Stop searching
                    return false
                } else if (child.type === 'group' && child.name !== 'customer' && (child.ledger.value('blocking') || child.ledger.value('errors'))) {
                    scrollTo(child)
                    scrolled = true
                }
            }, true)
        }

        if (node?.props?.onSubmitInvalid) {
            node.props.onSubmitInvalid = () => {
                scrollToErrors()
            }
            node.on('unsettled:errors', scrollToErrors)
        }
    }
    return false
}
