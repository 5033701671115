export const categoriesQuery = gql`
    query Menu($dispatchType: DispatchType!) {
        menu(dispatchType: $dispatchType) {
            categories {
                id
                title
                description
                allergies
                isVegan
                spiceLevel
                isBio
                isHalal
                hasAlcohol
                packagingCosts
                image {
                    path
                }
                products {
                    id
                    title
                    category {
                        id
                    }
                    description
                    descriptionExtras
                    available
                    availableFor
                    price
                    originalPrice
                    deposit
                    packagingCosts
                    allergies
                    isVegan
                    spiceLevel
                    isBio
                    isHalal
                    hasAlcohol
                    image {
                        path
                    }
                    minimumOrderQuantity
                    maximumOrderQuantity
                    extras {
                        id
                        title
                        viewType
                        showPrices
                        isVisible
                        minimumSelectionQuantity
                        maximumSelectionQuantity
                        isConditional
                        showOnExtraId
                        showOnItemId
                        items {
                            id
                            title
                            price
                            description
                            isSelected
                            isDisabled
                            packagingCosts
                            maximumSelectionQuantity
                            image {
                                path
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`
