
const map = {}
let count = 0
let _nuxt_worker

function initWorker () {
  const worker = new Worker(new URL("/opt/atlassian/pipelines/agent/build/workers/calculatorLocal.ts", import.meta.url), { type: 'module' })
  worker.onmessage = (e) => {
    const [resolve, reject] = map[e.data.id]
    if ('error' in e.data) {
      reject(new Error(e.data.error))
    } else {
      resolve(e.data.result)
    }
  }
  return worker
}

export async function calculateLocal (...args) {
  _nuxt_worker ||= initWorker()

  const id = count++
  return new Promise((resolve, reject) => {
    map[id] = [resolve, reject]
    _nuxt_worker.postMessage({ name: "calculateLocal", args, id })
  })
}
