export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: { input: string, output: string }
    String: { input: string, output: string }
    Boolean: { input: boolean, output: boolean }
    Int: { input: number, output: number }
    Float: { input: number, output: number }
}

export interface CalculateCalculatorService {
    __typename?: 'Calculate'
    cart?: Maybe<CartCalculatorService>
    choices?: Maybe<ChoicesCalculatorService>
    costs?: Maybe<CostsCalculatorService>
    delivery_fee_conditions?: Maybe<deliveryFeeConditionsCalculatorService>
    discount?: Maybe<Scalars['Float']['output']>
    discounts?: Maybe<Array<Maybe<DiscountResponseCalculatorService>>>
    points?: Maybe<PointsCalculatorService>
    subtotal?: Maybe<Scalars['Float']['output']>
    total?: Maybe<Scalars['Float']['output']>
    vat?: Maybe<VatCalculatorService>
}

export interface CalculateInputCalculatorService {
    cart?: InputMaybe<CartInputCalculatorService>
    clientUuid?: InputMaybe<Scalars['String']['input']>
    source?: InputMaybe<Scalars['String']['input']>
}

export interface CartCalculatorService {
    __typename?: 'Cart'
    products: Array<Maybe<ProductCalculatorService>>
}

export interface CartInputCalculatorService {
    additional_delivery_cost?: InputMaybe<Scalars['Float']['input']>
    discounts?: InputMaybe<Array<InputMaybe<DiscountInputCalculatorService>>>
    free_products?: InputMaybe<Array<InputMaybe<FreeProductInputCalculatorService>>>
    packaging_costs_override?: InputMaybe<Scalars['Float']['input']>
    payment_method?: InputMaybe<PaymentMethodCalculatorService>
    points?: InputMaybe<Scalars['Float']['input']>
    products?: InputMaybe<Array<InputMaybe<ProductInputCalculatorService>>>
    remarks?: InputMaybe<Scalars['String']['input']>
    schedule?: InputMaybe<ScheduleCalculatorService>
    tip?: InputMaybe<Scalars['Float']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    zip_code?: InputMaybe<Scalars['String']['input']>
}

export interface ChoicesCalculatorService {
    __typename?: 'Choices'
    count?: Maybe<Scalars['Int']['output']>
    discount?: Maybe<Scalars['String']['output']>
    label?: Maybe<Scalars['String']['output']>
    product_ids?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

export interface CostsCalculatorService {
    __typename?: 'Costs'
    delivery?: Maybe<Scalars['Float']['output']>
    deposit?: Maybe<Scalars['Float']['output']>
    packaging?: Maybe<Scalars['Float']['output']>
    payment?: Maybe<Scalars['Float']['output']>
    tip?: Maybe<Scalars['Float']['output']>
}

export interface DiscountInputCalculatorService {
    code?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
}

export interface DiscountResponseCalculatorService {
    __typename?: 'DiscountResponse'
    amount?: Maybe<Scalars['Float']['output']>
    amount_type?: Maybe<Scalars['String']['output']>
    code?: Maybe<Scalars['String']['output']>
    customer_exceeds_maximum_usage?: Maybe<Scalars['Boolean']['output']>
    id?: Maybe<Scalars['String']['output']>
    label?: Maybe<Scalars['String']['output']>
    type?: Maybe<Scalars['String']['output']>
}

export interface FreeProductInputCalculatorService {
    id?: InputMaybe<Scalars['Int']['input']>
}

export interface ItemCalculatorService {
    __typename?: 'Item'
    deposit?: Maybe<Scalars['Float']['output']>
    extra_id?: Maybe<Scalars['Int']['output']>
    id?: Maybe<Scalars['Int']['output']>
    packaging_costs?: Maybe<Scalars['Float']['output']>
    product_id?: Maybe<Scalars['Int']['output']>
    quantity?: Maybe<Scalars['Int']['output']>
    total?: Maybe<Scalars['Float']['output']>
}

export interface ItemInputCalculatorService {
    deposit?: InputMaybe<Scalars['Float']['input']>
    extra_id?: InputMaybe<Scalars['Int']['input']>
    id?: InputMaybe<Scalars['Int']['input']>
    packaging_costs?: InputMaybe<Scalars['Float']['input']>
    product_id?: InputMaybe<Scalars['Int']['input']>
    quantity?: InputMaybe<Scalars['Int']['input']>
    remarks?: InputMaybe<Scalars['String']['input']>
    total?: InputMaybe<Scalars['Float']['input']>
}

export enum PaymentMethodCalculatorService {
    ACCOUNT = 'ACCOUNT',
    BANK_TRANSFER = 'BANK_TRANSFER',
    CASH = 'CASH',
    CREDIT_CARD = 'CREDIT_CARD',
    EPS = 'EPS',
    IDEAL = 'IDEAL',
    MISTERCASH = 'MISTERCASH',
    MISTERCASH_MOBILE_APP = 'MISTERCASH_MOBILE_APP',
    MISTERCASH_MOBILE_QR = 'MISTERCASH_MOBILE_QR',
    PAYPAL = 'PAYPAL',
    PIN = 'PIN',
    TWINT = 'TWINT'
}

export interface PointsCalculatorService {
    __typename?: 'Points'
    earned?: Maybe<Scalars['Int']['output']>
    spent?: Maybe<Scalars['Int']['output']>
}

export interface PriceCalculatorService {
    default?: InputMaybe<Scalars['Float']['input']>
}

export interface ProductCalculatorService {
    __typename?: 'Product'
    category_packaging_costs?: Maybe<Scalars['Float']['output']>
    deposit?: Maybe<Scalars['Float']['output']>
    discount?: Maybe<Scalars['Float']['output']>
    discount_id?: Maybe<Scalars['Int']['output']>
    id?: Maybe<Scalars['Int']['output']>
    items?: Maybe<Array<Maybe<ItemCalculatorService>>>
    order_product_id?: Maybe<Scalars['Int']['output']>
    packaging_costs?: Maybe<Scalars['Float']['output']>
    price?: Maybe<Scalars['Float']['output']>
    quantity?: Maybe<Scalars['Int']['output']>
    remarks?: Maybe<Scalars['String']['output']>
    total?: Maybe<Scalars['Float']['output']>
    total_extras?: Maybe<Scalars['Float']['output']>
}

export interface ProductInputCalculatorService {
    category_packaging_costs?: InputMaybe<Scalars['Float']['input']>
    deposit?: InputMaybe<Scalars['Float']['input']>
    discount?: InputMaybe<Scalars['Float']['input']>
    discount_id?: InputMaybe<Scalars['Int']['input']>
    id?: InputMaybe<Scalars['Int']['input']>
    items?: InputMaybe<Array<InputMaybe<ItemInputCalculatorService>>>
    order_product_id?: InputMaybe<Scalars['Int']['input']>
    packaging_costs?: InputMaybe<Scalars['Float']['input']>
    price?: InputMaybe<PriceCalculatorService>
    quantity?: InputMaybe<Scalars['Int']['input']>
    remarks?: InputMaybe<Scalars['String']['input']>
    total?: InputMaybe<Scalars['Float']['input']>
    total_extras?: InputMaybe<Scalars['Float']['input']>
    vat?: InputMaybe<VatInputCalculatorService>
}

export interface QueryCalculatorService {
    __typename?: 'Query'
    calculate?: Maybe<CalculateCalculatorService>
}

export interface QuerycalculateCalculatorServiceArgsCalculatorService {
    input?: InputMaybe<CalculateInputCalculatorService>
}

export interface ScheduleCalculatorService {
    asap?: InputMaybe<Scalars['Boolean']['input']>
    date?: InputMaybe<Scalars['String']['input']>
    time?: InputMaybe<Scalars['String']['input']>
}

export interface VatCalculatorService {
    __typename?: 'Vat'
    total?: Maybe<Scalars['Float']['output']>
}

export interface VatInputCalculatorService {
    amount?: InputMaybe<Scalars['Float']['input']>
    rate?: InputMaybe<Scalars['Int']['input']>
}

export interface deliveryFeeConditionsCalculatorService {
    __typename?: 'deliveryFeeConditions'
    free_from?: Maybe<Scalars['Float']['output']>
    minimum?: Maybe<Scalars['Float']['output']>
}
