import { webshopSettings } from '~/store/webshopSettings'
import { account } from '~/store/account'

function getCustomHeaders (isAuth: boolean) {
    return {
        'Foodticket-Host': webshopSettings().currentHost,
        'Accept-Language': webshopSettings().currentLang,
        ...(isAuth && { authorization: `Bearer ${account().accountToken}` })
    }
}

export async function apiCallSsr (query: any, returnObject?: string, variables: object = {}, cache: boolean = true, isAuth: boolean = false) {
    const { data } = await useAsyncQuery({
        query,
        variables,
        cache,
        context: {
            headers: {
                ...getCustomHeaders(isAuth)
            }
        }
    })
    if (data?.value && returnObject) {
        return data?.value[returnObject]
    }
    return data?.value
}

export async function apiCallClient (query: any, returnObject?: string, variables: object = {}, cache: boolean = true, isAuth: boolean = false) {
    const { resolveClient } = useApolloClient()
    const client = resolveClient()
    const { data } = await client.query({
        query,
        variables,
        fetchPolicy: cache ? 'cache-first' : 'no-cache',
        context: {
            headers: {
                ...getCustomHeaders(isAuth)
            }
        }
    })
    if (data && returnObject) {
        return data[returnObject]
    }
    return data?.value
}

export async function apiCall (query: any, returnObject?: string, variables: object = {}, cache: boolean = true) {
    if (process.server) {
        return await apiCallSsr(query, returnObject, variables, cache)
    }
    return await apiCallClient(query, returnObject, variables, cache)
}

export function apiCallMutation (query: any, variables: object = {}, isAuth: boolean = false) {
    return useMutation(query, {
        variables,
        context: {
            headers: {
                ...getCustomHeaders(isAuth)
            }
        }
    })
}
