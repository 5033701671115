import revive_payload_client_q7HfQkUZwm from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.13.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_typ_zbvvoyunohqgjrsyhyqxfkitmq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ak8xoVRa1i from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.13.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_typ_zbvvoyunohqgjrsyhyqxfkitmq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_OeYKIeEhUn from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.13.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_typ_zbvvoyunohqgjrsyhyqxfkitmq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_vBkM59Jk6P from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.21.2_vite@5.4.7_vue@3.5.12/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_XIClvjxt1z from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.13.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_typ_zbvvoyunohqgjrsyhyqxfkitmq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2XTzKBGfoC from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.13.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_typ_zbvvoyunohqgjrsyhyqxfkitmq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_S0GWIvQJRf from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.13.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_typ_zbvvoyunohqgjrsyhyqxfkitmq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_L8ycUSrdsW from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.13.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_typ_zbvvoyunohqgjrsyhyqxfkitmq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_OZkOh42tqV from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.21.2_typescript@5.6.3_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_LBW5s4qCTu from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.13.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_typ_zbvvoyunohqgjrsyhyqxfkitmq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/atlassian/pipelines/agent/build/.nuxt/pwa-icons-plugin.ts";
import pwa_client_GkM7612EUV from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_rollup@4.21.2_vite@5.4.7_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_62AylWkLq1 from "/opt/atlassian/pipelines/agent/build/modules/metaPixel/runtime/plugin.ts";
import plugin_hyqxmeg06o from "/opt/atlassian/pipelines/agent/build/modules/googleTrackers/runtime/plugin.ts";
import floating_vue_EIcJ7xiw0h from "/opt/atlassian/pipelines/agent/build/.nuxt/floating-vue.mjs";
import plugin_zApcTOIdx9 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_rollup@4.21.2_typescript@5.6.3_vue@3.5.12/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/opt/atlassian/pipelines/agent/build/.nuxt/formkitPlugin.mjs";
import switch_locale_path_ssr_uUJNOmOkBS from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.21.2_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_5rAKHzpAW3 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.21.2_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import sentry_client_rfYJnGnhCI from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@sentry+nuxt@8.35.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_cn4npzibdqyoimrygxjmud4uci/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/atlassian/pipelines/agent/build/.nuxt/sentry-client-config.mjs";
import plugin_ECrKpM8ZCc from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_pinia@2.2.5_rollup@4.21.2/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import apollo_error_xteZhA1Hlb from "/opt/atlassian/pipelines/agent/build/plugins/apollo-error.ts";
import mitt_event_bus_LbQ9A4wOim from "/opt/atlassian/pipelines/agent/build/plugins/mitt-event-bus.ts";
import vue_final_modal_pML93k5qcp from "/opt/atlassian/pipelines/agent/build/plugins/vue-final-modal.ts";
 
function __PARALLEL_WRAPPER__ (plugin) {
  if (!plugin)
    return plugin;

    if(typeof plugin === 'function' && typeof plugin.parallel === 'undefined') {
      plugin.parallel = true
    }
}

__PARALLEL_WRAPPER__(_0_siteConfig_vBkM59Jk6P)
__PARALLEL_WRAPPER__(components_plugin_KR1HBZs4kY)
__PARALLEL_WRAPPER__(pwa_icons_plugin_LnrhIJeMG7)
__PARALLEL_WRAPPER__(plugin_62AylWkLq1)
__PARALLEL_WRAPPER__(plugin_hyqxmeg06o)
__PARALLEL_WRAPPER__(floating_vue_EIcJ7xiw0h)
__PARALLEL_WRAPPER__(plugin_zApcTOIdx9)
__PARALLEL_WRAPPER__(formkitPlugin_pZqjah0RUG)
__PARALLEL_WRAPPER__(switch_locale_path_ssr_uUJNOmOkBS)
__PARALLEL_WRAPPER__(i18n_5rAKHzpAW3)
__PARALLEL_WRAPPER__(sentry_client_rfYJnGnhCI)
__PARALLEL_WRAPPER__(sentry_client_config_o34nk2sJbg)
__PARALLEL_WRAPPER__(apollo_error_xteZhA1Hlb)
__PARALLEL_WRAPPER__(mitt_event_bus_LbQ9A4wOim)
__PARALLEL_WRAPPER__(vue_final_modal_pML93k5qcp)

export default [
  revive_payload_client_q7HfQkUZwm,
  unhead_ak8xoVRa1i,
  router_OeYKIeEhUn,
  _0_siteConfig_vBkM59Jk6P,
  payload_client_XIClvjxt1z,
  navigation_repaint_client_2XTzKBGfoC,
  check_outdated_build_client_S0GWIvQJRf,
  chunk_reload_client_L8ycUSrdsW,
  plugin_vue3_OZkOh42tqV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_LBW5s4qCTu,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_GkM7612EUV,
  plugin_62AylWkLq1,
  plugin_hyqxmeg06o,
  floating_vue_EIcJ7xiw0h,
  plugin_zApcTOIdx9,
  formkitPlugin_pZqjah0RUG,
  switch_locale_path_ssr_uUJNOmOkBS,
  i18n_5rAKHzpAW3,
  sentry_client_rfYJnGnhCI,
  sentry_client_config_o34nk2sJbg,
  plugin_ECrKpM8ZCc,
  apollo_error_xteZhA1Hlb,
  mitt_event_bus_LbQ9A4wOim,
  vue_final_modal_pML93k5qcp
]
