/**
 * @privateRemarks
 * This file was generated by the FormKit CLI and should not be manually
 * edited unless you’d like to 'eject' from the CLI’s ability to update it.
 *
 * @checksum - 08a2d5798b57a58e322945c84f51824824de9bd9382736ca3e867c97e153f542
 * @variables - radius=rounded-none,spacing=2,scale=base
 * @theme - starter
 */

/**
 * These classes have already been merged with globals using tailwind-merge
 * and are ready to be used directly in the theme.
 */
const classes = {
    'family:button__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'family:button__wrapper': {},
    'family:button__label': {
        'block': true,
        'text-neutral-900': true,
        'text-sm': true,
        'mb-2': true
    },
    'family:button__prefixIcon': {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        '-ml-1': true,
        'mr-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true,
        'text-neutral-900': true
    },
    'family:button__suffixIcon': {
        'flex': true,
        'items-center': true,
        '-mr-1': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true,
        'text-neutral-900': true
    },
    'family:button__input': {
        'appearance-none': true,
        '[color-scheme:light]': true,
        'placeholder:text-neutral-400': true,
        'flex': true,
        'items-center': true,
        'rounded-none cursor-pointer': false,
        'px-7': true,
        'py-3': true,
        'text-neutral-900': true,
        'border': false,
        'border-neutral-900': false,
        'group-data-[disabled]:!cursor-not-allowed': true,
        'group-data-[prefix-icon]:pl-5': true,
        'group-data-[suffix-icon]:pr-5': true
    },
    'family:button__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'family:button__messages': {
        '': true
    },
    'family:button__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'family:box__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'family:box__wrapper': {
        'inline-flex': true,
        'items-center': true,
        'group-data-[multiple]:mb-0': true
    },
    'family:box__fieldset': {
        '': true
    },
    'family:box__legend': {
        'block': true,
        'text-neutral-900': true,
        'text-sm': true,
        'mb-2': true
    },
    'family:box__help': {
        'text-neutral-900': true,
        'text-xs': true,
        'group-data-[multiple]:mb-2': true,
        'group-data-[multiple]:-mt-1.5': true
    },
    'family:box__inner': {
        'flex items-center': true
    },
    'family:box__input': {
        'appearance-none': true,
        '[color-scheme:light]': true,
        'placeholder:text-neutral-400': true,
        'peer': true,
        'absolute': true,
        'h-0': true,
        'w-0': true,
        'overflow-hidden': true,
        'opacity-0': true
    },
    'family:box__decorator': {
        'relative': true,
        'block': true,
        'select-none': true,
        'w-5 h-5': true,
        'aspect-square': true,
        'border': false,
        'border-neutral-900': true,
        'mr-1.5': true,
        'text-transparent': true,
        'peer-checked:text-neutral-900': true,
        'peer-disabled:cursor-not-allowed': true
    },
    'family:box__decoratorIcon': {
        'absolute': true,
        'left-1/2': true,
        'top-1/2': true,
        'flex': true,
        'h-full': true,
        'w-full': true,
        '-translate-x-1/2': true,
        '-translate-y-1/2': true
    },
    'family:box__options': {
        '': true
    },
    'family:box__option': {
        'data-[disabled]:opacity-50': true,
        'group-data-[disabled]:data-[disabled]:opacity-100': true
    },
    'family:box__label': {
        'block': true,
        'text-neutral-900': true,
        '!mb-0': true,
        'mb-2': true
    },
    'family:box__optionHelp': {
        'text-neutral-600': true,
        'text-xs': true,
        '-mt-1': true
    },
    'family:box__messages': {
        '': true
    },
    'family:box__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'family:text__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'mb-4': false,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'family:text__wrapper': {
        'flex': true,
        'flex-col': true,
        'items-start': true,
        'justify-start': true,
        'last:mb-0': true
    },
    'family:text__label': {
        'block': true,
        'text-gray-700': true,
        'text-lg': true,
        'font-bold': true,
        'mb-2': true
    },
    'family:text__prefixIcon': {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        '-ml-1': true,
        'mr-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true,
        'text-neutral-600': true
    },
    'family:text__suffixIcon': {
        'flex': true,
        'items-center': true,
        '-mr-1': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true,
        'text-neutral-600': true
    },
    'family:text__inner': {
        'text-base': true,
        'w-full': true,
        'bg-gray-50': true,
        'rounded': true,
        'flex': true,
        'items-center': true,
        'focus-within:ring-2': false,
        'group-data-[disabled]:!cursor-not-allowed': true
    },
    'family:text__input': {
        'appearance-none': true,
        '[color-scheme:light]': true,
        'p-4': true,
        'border-b-2': true,
        'border-slate-300': true,
        'rounded': true,
        'focus:border-slate-400': true,
        'focus:outline-[1px]': true,
        'focus:outline-slate-400': true,
        'placeholder:text-neutral-400': true,
        'text-base': true,
        'text-neutral-900': true,
        'bg-transparent': true,
        'w-full': true,
        'grow': true,
        'p-0': true,
        'focus:ring-0': true
    },
    'family:text__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'family:text__messages': {
        'mt-2': true
    },
    'family:text__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'family:dropdown__wrapper': {
        'mb-1.5': true
    },
    'family:dropdown__inner': {
        'relative': true,
        '!bg-gray-50': true,
        'flex': true,
        'text-base': true,
        'w-full': true,
        'bg-gray-50': true,
        'items-center': true,
        'bg-white': false,
        'border': false,
        '!border-b-2': true,
        '!border-slate-300': true,
        'rounded': true,
        'group-data-[is-multiline]:!rounded': true,
        'group-data-[is-multiline]:!border': true,
        '!focus:border-slate-400': true,
        '!focus:outline-[1px]': true,
        '!focus:outline-slate-400': true,
        'group-data-[invalid]:border-red-500': true,
        'group-data-[invalid]:ring-1': true,
        'group-data-[invalid]:ring-red-500': true,
        'group-data-[disabled]:bg-neutral-100': true,
        'group-data-[disabled]:!cursor-not-allowed': true,
        'shadow': false,
        'group-[]/repeater:shadow-none': true,
        'group-[]/multistep:shadow-none': true
    },
    'family:dropdown__selector': {
        '!p-4': true,
        '!leading-6': true
    },
    'family:dropdown__input': {
        'appearance-none': true,
        'selection:text-neutral-700': true,
        'group-data-[has-overlay]:selection:!text-transparent': true,
        'grow': true,
        'p-4': true,
        'pr-0': true,
        'pl-3': true,
        'text-base': true,
        'text-ellipsis': true,
        'min-w-0': true,
        'bg-transparent': true,
        'group-data-[disabled]:!cursor-not-allowed': true,
        'group-data-[prefix-icon]:!pl-0': true,
        'group-data-[suffix-icon]:!pr-0': true,
        'placeholder:text-neutral-400': true,
        'selection:bg-blue-100': true,
        'border-none': true,
        'focus:ring-0': true
    },
    'family:dropdown__listboxButton': {
        'w-[2.5em]': true,
        'self-stretch': true,
        'text-base': true,
        'flex': true,
        'items-center': true,
        'text-neutral-700': true,
        'z-10': true,
        'data-[disabled]:cursor-not-allowed': true
    },
    'family:dropdown__removeSelection': {
        'w-[2.5em]': true,
        'self-stretch': true,
        'text-base': true,
        'flex': true,
        'items-center': true,
        'text-neutral-700': true,
        'hover:text-red-400': true,
        'z-10': true
    },
    'family:dropdown__selectIcon': {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        'mr-2': false,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true,
        'ml-2': false
    },
    'family:dropdown__closeIcon': {
        'text-base': true,
        'w-[0.75em]': true,
        'relative': true,
        'm-auto': true
    },
    'family:dropdown__prefixIcon': {
        'flex': true,
        'items-center': true,
        '-ml-1': true,
        'mr-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true,
        '!ml-2': true,
        '!mr-0': true,
        'text-neutral-600': true
    },
    'family:dropdown__suffixIcon': {
        'flex': true,
        'items-center': true,
        '-mr-1': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true,
        '!mr-2': true,
        '!ml-0': true,
        'text-neutral-600': true
    },
    'family:dropdown__dropdownWrapper': {
        'rounded': true,
        'shadow-lg': true,
        'mt-1': true,
        'overflow-clip': true,
        'empty:hidden': true,
        'border': true,
        'border-neutral-300': true,
        'group-data-[expanded]:opacity-100': true,
        'group-data-[overscroll]:m-0': true,
        'group-data-[overscroll]:shadow-none': true,
        'group-data-[overscroll]:border-none': true
    },
    'family:dropdown__listbox': {
        'bg-white': true,
        'rounded': true,
        'empty:hidden': true,
        'group-data-[overscroll]:shadow-lg': true,
        'group-data-[overscroll]:border': true,
        'group-data-[overscroll]:border-neutral-300': true
    },
    'family:dropdown__listitem': {
        'relative': true,
        'flex': true,
        'items-center': true,
        'p-2': true,
        'text-base': true,
        'aria-selected:!bg-primary': true,
        'aria-selected:data-[is-active]:hover:!bg-primary': true,
        'before:content-[\'\']': true,
        'before:absolute': true,
        'before:inset-0': true
    },
    'family:dropdown__selectedIcon': {
        'flex': true,
        'absolute': true,
        'items-center': true,
        'right-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true
    },
    'family:dropdown__option': {
        'ml-[0.5em]': true
    },
    'family:dropdown__loadMoreInner': {
        'flex': true,
        'text-sm': true,
        'text-neutral-500': true,
        'p-2': true,
        'items-center': true,
        'justify-center': true,
        '[&>span]:mr-2': true,
        'hover:text-blue-600': true,
        'cursor-pointer': true
    },
    'family:dropdown__selectionWrapper': {
        'grow': true,
        'flex': true,
        'items-center': true,
        'text-neutral-700': true
    },
    'family:dropdown__selection': {
        'grow': true,
        'group-data-[multiple]:p-2': true
    },
    'family:dropdown__tagsWrapper': {
        'w-full': true
    },
    'family:dropdown__tagWrapper': {
        'group/tag': true,
        'rounded': true,
        'mr-1': true,
        'mb-1': true,
        'outline-none': true,
        'data-[active-selection=true]:ring-2': true,
        'data-[active-selection=true]:ring-blue-500': true
    },
    'family:dropdown__tags': {
        'inline-flex': true,
        'flex-wrap': true,
        'items-center': true,
        'w-full': true,
        '-mb-1': true,
        'empty:mb-0': true
    },
    'family:dropdown__tag': {
        'flex': true,
        'items-center': true,
        'cursor-default': true,
        'rounded': true,
        'text-sm': true,
        'px-1.5': true,
        'py-0.5': true,
        'bg-blue-600': true,
        'text-white': true,
        '[&>[type=button]]:!w-[0.5em]': true,
        '[&>[type=button]]:aspect-square': true,
        '[&>[type=button]]:!text-inherit': true,
        '[&>[type=button]]:cursor-pointer': true,
        'group-data-[active-selection=true]/tag:bg-blue-400': true,
        'group-data-[active-selection=true]/tag:text-neutral-700': true
    },
    'family:dropdown__tagLabel': {
        'mr-1': true
    },
    'family:dropdown__emptyMessage': {
        'flex': true,
        'items-center': true,
        'px-2': true,
        'py-1.5': true,
        'first:pt-2': true,
        'last:pb-2': true,
        'text-neutral-700': true,
        'text-sm': true,
        'aria-selected:text-white': true,
        'aria-selected:bg-blue-600': true
    },
    'dropdown__placeholder': {
        'text-neutral-400': true,
        'grow': true
    },
    'dropdown__selector': {
        'flex': true,
        'grow': true,
        'justify-between': true,
        'w-full': true,
        'text-base': true,
        'text-neutral-700': true,
        'text-left': true,
        'group-data-[disabled]:!cursor-not-allowed': true,
        'group-data-[prefix-icon]:!pl-0': true,
        'group-data-[suffix-icon]:!pr-0': true,
        'data-[placeholder]:text-neutral-400': true,
        'selection:bg-blue-100': true
    },
    'dropdown__selectIcon': {
        'shrink-0': true
    },
    'dropdown__selectionsWrapper': {
        'w-[85%]': true,
        'overflow-hidden': true
    },
    'dropdown__selection': {
        '[&>*]:ml-0': true
    },
    'dropdown__selections': {
        'inline-flex': true,
        'items-center': true
    },
    'dropdown__selectionsItem': {
        'whitespace-nowrap': true,
        'mr-1': true
    },
    'dropdown__tagWrapper': {
        '[&.formkit-dropZone_.formkit-tag]:opacity-25': true,
        '[&.formkit-touchDropZone_.formkit-tag]:opacity-25': true
    },
    'dropdown__truncationCount': {
        'flex': true,
        'items-center': true,
        'justify-center': true,
        'h-[1.5em]': true,
        'rounded': true,
        'bg-neutral-400': true,
        'text-white': true,
        'whitespace-nowrap': true,
        'text-[11px]': true,
        '[line-height:1em]': true,
        'tracking-tighter': true,
        'leading-0': true,
        'py-1': true,
        'px-1': true,
        'shrink-0': true,
        'my-auto': true
    },
    'button__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'button__wrapper': {},
    'button__label': {
        'block': true,
        'text-sm': true,
        'mb-2': true
    },
    'button__prefixIcon': {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        '-ml-1': true,
        'mr-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true
    },
    'button__suffixIcon': {
        'flex': true,
        'items-center': true,
        '-mr-1': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true
    },
    'button__input': {
        'appearance-none': true,
        '[color-scheme:light]': true,
        'placeholder:text-neutral-400': true
    },
    'button__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'button__messages': {
        '': true
    },
    'button__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'checkbox__outer': {
        'group/checkbox': true,
        'cursor-pointer': true,
        'flex': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true,
        'hover:bg-gray-100': false,
        'relative': true,
        'gap-4': true,
        'w-full': true
    },
    'checkbox__wrapper': {
        'flex': true,
        'p-4': true,
        'w-full': true,
        'cursor-pointer': true
    },
    'checkbox__fieldset': {
        '': true
    },
    'checkbox__legend': {
        'block': true,
        'text-neutral-900': true,
        'text-sm': true
    },
    'checkbox__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'checkbox__inner': {
        '': true
    },
    'checkbox__options': {
        '': true
    },
    'checkbox__option': {
        '': true
    },
    'checkbox__decorator': {
        'cursor-pointer': true,
        'rounded-sm': true,
        'border-2': true,
        'border-gray-200': true,
        'lg:group-hover/checkbox:border-gray-400': true,
        'mr-1.5': false,
        'mr-2': true
    },
    'checkbox__decoratorIcon': {
        'max-w-[66.66%]': true
    },
    'checkbox__label': {
        'block': true,
        'text-neutral-900': true,
        'text-sm': true,
        'mb-2': true
    },
    'checkbox__optionHelp': {
        '': true
    },
    'checkbox__messages': {
        'mt-2': true
    },
    'checkbox__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'date__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'date__wrapper': {},
    'date__label': {
        'block': true,
        'text-sm': true,
        'mb-2': true
    },
    'date__prefixIcon': {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        '-ml-1': true,
        'mr-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true
    },
    'date__suffixIcon': {
        'flex': true,
        'items-center': true,
        '-mr-1': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true
    },
    'date__inner': {
        '': true
    },
    'date__input': {
        'appearance-none': true,
        '[color-scheme:light]': true,
        'placeholder:text-neutral-400': true
    },
    'date__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'date__messages': {
        'mt-2': true
    },
    'date__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'datetime-local__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'datetime-local__wrapper': {},
    'datetime-local__label': {
        'block': true,
        'text-neutral-900': true,
        'text-sm': true,
        'mb-2': true
    },
    'datetime-local__prefixIcon': {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        '-ml-1': true,
        'mr-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true
    },
    'datetime-local__suffixIcon': {
        'flex': true,
        'items-center': true,
        '-mr-1': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true
    },
    'datetime-local__inner': {
        '': true
    },
    'datetime-local__input': {
        'appearance-none': true,
        '[color-scheme:light]': true,
        'placeholder:text-neutral-400': true
    },
    'datetime-local__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'datetime-local__messages': {
        'mt-2': true
    },
    'datetime-local__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'email__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'email__wrapper': {},
    'email__label': {
        'block': true,
        'text-gray-700': true,
        'text-lg': true,
        'font-bold': true,
        'mb-2': true
    },
    'email__prefixIcon': {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        '-ml-1': true,
        'mr-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true
    },
    'email__suffixIcon': {
        'flex': true,
        'items-center': true,
        '-mr-1': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true
    },
    'email__inner': {
        '': true
    },
    'email__input': {
        'appearance-none': true,
        '[color-scheme:light]': true,
        'placeholder:text-neutral-400': true
    },
    'email__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'email__messages': {
        '': true
    },
    'email__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'form__form': {
        '': true
    },
    'form__actions': {
        '': true
    },
    'form__summaryInner': {
        'group/summary': true,
        'border': true,
        'border-neutral-900': true,
        'rounded-none': true,
        'py-2': true,
        'px-3': true
    },
    'form__summaryHeader': {
        'text-lg': true,
        'text-neutral-900': true,
        'mb-2': true
    },
    'form__messages': {
        '': true
    },
    'form__message': {
        'text-red-600': true,
        'text-xs': true,
        'group-[]/summary:text-sm': true
    },
    'month__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'month__wrapper': {},
    'month__label': {
        'block': true,
        'text-neutral-900': true,
        'text-sm': true,
        'mb-2': true
    },
    'month__prefixIcon': {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        '-ml-1': true,
        'mr-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true
    },
    'month__suffixIcon': {
        'flex': true,
        'items-center': true,
        '-mr-1': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true
    },
    'month__inner': {
        '': true
    },
    'month__input': {
        'appearance-none': true,
        '[color-scheme:light]': true,
        'placeholder:text-neutral-400': true
    },
    'month__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'month__messages': {
        '': true
    },
    'month__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'number__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'number__wrapper': {},
    'number__label': {
        'block': true,
        'text-gray-700': true,
        'text-lg': true,
        'font-bold': true,
        'mb-2': true
    },
    'number__prefixIcon': {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        '-ml-1': true,
        'mr-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true
    },
    'number__suffixIcon': {
        'flex': true,
        'items-center': true,
        '-mr-1': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true
    },
    'number__inner': {
        '': true
    },
    'number__input': {
        'appearance-none': true,
        '[color-scheme:light]': true,
        'placeholder:text-neutral-400': true
    },
    'number__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'number__messages': {
        'mt-2': true
    },
    'number__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'password__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'password__wrapper': {},
    'password__label': {
        'block': true,
        'text-neutral-900': true,
        'text-sm': true,
        'mb-2': true
    },
    'password__prefixIcon': {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        '-ml-1': true,
        'mr-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true
    },
    'password__suffixIcon': {
        'flex': true,
        'items-center': true,
        '-mr-1': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true
    },
    'password__inner': {
        '': true
    },
    'password__input': {
        'appearance-none': true,
        '[color-scheme:light]': true,
        'placeholder:text-neutral-400': true
    },
    'password__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'password__messages': {
        'mt-2': true
    },
    'password__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'radio__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'radio__wrapper': {
        'formkit-disabled:cursor-not-allowed': true,
        'cursor-pointer': true,
        'formkit-disabled:hover:bg-white': true,
        'hover:bg-gray-100': true,
        'transition-all': true,
        'h-[4.375rem]': true,
        'px-4': true,
        'duration-300': true,
        'flex': true,
        'items-center': true,
        'justify-center': true,
        'w-full': true,
        'bg-transparent': true,
        'border': true,
        'border-gray-300': true,
        'rounded-md': true
    },
    'radio__fieldset': {
        'border-none': true
    },
    'radio__legend': {
        'block': true,
        'text-neutral-900': true,
        'text-sm': true
    },
    'radio__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'radio__inner': {
        '': true
    },
    'radio__options': {
        'grid': true,
        'gap-7': true
    },
    'radio__option': {
        'flex': true,
        'flex-col': true,
        'border': true,
        'border-gray-300': true,
        'rounded-md': true
    },
    'radio__decorator': {
        'block relative h-5 w-5 mr-2 bg-white checkbox focus:opacity-100 ring-1 ring-gray-200 peer-checked:ring-primary peer-checked:text-primary outline-none border rounded-full border-gray-200 absolute cursor-pointer checked:border-none': true
    },
    'radio__decoratorIcon': {
        'checked-icon border-3 border-white rounded-full text-white flex p-[3px] w-full h-full formkit-checked:text-primary': true
    },
    'radio__label': {
        'w-full': true,
        'font-bold': true,
        'text-neutral-700': true,
        'text-md': true
    },
    'radio__optionHelp': {
        '': true
    },
    'radio__messages': {
        'mt-2': true
    },
    'radio__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'range__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'range__wrapper': {},
    'range__label': {
        'block': true,
        'text-neutral-900': true,
        'text-sm': true,
        'mb-2': true
    },
    'range__prefixIcon': {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        '-ml-1': true,
        'mr-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true
    },
    'range__suffixIcon': {
        'flex': true,
        'items-center': true,
        '-mr-1': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true
    },
    'range__inner': {
        'relative': true,
        '!border-none': true,
        '!ring-0': true,
        '!px-0': true,
        '!bg-transparent': true
    },
    'range__input': {
        'appearance-none': true,
        '[color-scheme:light]': true,
        'placeholder:text-neutral-400': true,
        '[&::-webkit-slider-runnable-track]:bg-neutral-300': true,
        '[&::-webkit-slider-runnable-track]:h-1.5': true,
        '[&::-webkit-slider-runnable-track]:rounded-none': true,
        '[&::-moz-range-track]:bg-neutral-300': true,
        '[&::-moz-range-track]:h-1.5': true,
        '[&::-moz-range-track]:rounded-none': true,
        '[&::-webkit-slider-thumb]:appearance-none': true,
        '[&::-webkit-slider-thumb]:w-3.5': true,
        '[&::-webkit-slider-thumb]:h-3.5': true,
        '[&::-webkit-slider-thumb]:bg-neutral-600': true,
        '[&::-webkit-slider-thumb]:rounded-full': true,
        '[&::-webkit-slider-thumb]:relative': true,
        '[&::-webkit-slider-thumb]:top-1/2': true,
        '[&::-webkit-slider-thumb]:-translate-y-1/2': true,
        '[&::-webkit-slider-thumb]:focus-visible:ring-2': true,
        '[&::-moz-range-thumb]:appearance-none': true,
        '[&::-moz-range-thumb]:border-none': true,
        '[&::-moz-range-thumb]:w-3.5': true,
        '[&::-moz-range-thumb]:h-3.5': true,
        '[&::-moz-range-thumb]:bg-neutral-600': true,
        '[&::-moz-range-thumb]:rounded-full': true,
        '[&::-moz-range-thumb]:focus-visible:ring-2': true
    },
    'range__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'range__messages': {
        'mt-2': true
    },
    'range__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'search__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'search__wrapper': {},
    'search__label': {
        'block': true,
        'text-neutral-900': true,
        'text-sm': true,
        'mb-2': true
    },
    'search__prefixIcon': {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        '-ml-1': true,
        'mr-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true
    },
    'search__suffixIcon': {
        'flex': true,
        'items-center': true,
        '-mr-1': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true
    },
    'search__inner': {
        '': true
    },
    'search__input': {
        'appearance-none': true,
        'border-b-2': false,
        'border-slate-300': false,
        '[color-scheme:light]': true,
        'focus:outline-[1px]': false,
        'placeholder:text-neutral-400': true,
        'focus:ring-0': false,
        'focus:border-slate-400': false,
        'focus:outline-slate-400': false,
        'focus:outline-none': true
    },
    'search__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'search__messages': {
        'mt-2': true
    },
    'search__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'select__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'select__wrapper': {},
    'select__label': {
        'block': true,
        'text-gray-700': true,
        'text-lg': true,
        'font-bold': true,
        'mb-2': true
    },
    'select__prefixIcon': {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        'mr-2': false,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true,
        'ml-2': false,
        'text-red-700': true
    },
    'select__suffixIcon': {
        'flex': true,
        'items-center': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true,
        'mr-2': false,
        'text-red-700': true
    },
    'select__inner': {
        'relative': true,
        'flex': true,
        'items-center': true,
        'bg-gray-50': true,
        'border-b-2': true,
        'border-slate-300': true,
        'rounded': true,
        'focus-within:border-slate-400': false,
        'focus-within:ring-2': false,
        'group-data-[multiple]:rounded-none': true
    },
    'select__input': {
        'appearance-none': true,
        '[color-scheme:light]': true,
        'placeholder:text-neutral-400': true,
        'grow': true,
        'p-4': true,
        'pr-[2em]': true,
        'text-base': true,
        'text-ellipsis': true,
        'bg-transparent': true,
        'group-data-[prefix-icon]:!pl-0': true,
        'group-data-[suffix-icon]:!pr-0': true,
        'data-[placeholder]:text-neutral-900': true,
        'border-none': true,
        'focus:ring-0': true,
        'bg-none': true
    },
    'select__selectIcon': {
        'absolute': true,
        'w-[1em]': true,
        'text-neutral-900': true,
        'pointer-events-none': true,
        'right-4': true,
        'group-data-[suffix-icon]:mr-[1.5em]': true
    },
    'select__optGroup': {
        'group/optgroup': true,
        'font-bold': true,
        'text-sm': true
    },
    'select__option': {
        'group-data-[multiple]:text-sm': true,
        'group-data-[multiple]:outline-none': true,
        'group-data-[multiple]:border-none': true,
        'group-data-[multiple]:py-1.5': true,
        'group-data-[multiple]:px-2': true
    },
    'select__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'select__messages': {
        'mt-2': true
    },
    'select__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'submit__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': false
    },
    'submit__wrapper': {},
    'submit__label': {
        'block': true,
        'text-neutral-900': true,
        'text-sm': true,
        'mb-2': true
    },
    'submit__prefixIcon': {
        'block w-4 -ml-2 mr-2 stretch': true
    },
    'submit__suffixIcon': {
        'block w-4 -ml-2 mr-2 stretch': true
    },
    'submit__input': {
        'w-full': true,
        'appearance-none rounded-full flex text-center justify-center items-center bg-primary transition-all bg-primary hover:bg-primary-200 text-white text-sm leading-6 font-bold uppercase py-4 px-6 rounded-full formkit-disabled:bg-gray-400 formkit-loading:before:w-4 formkit-loading:before:h-4 formkit-loading:before:mr-2 formkit-loading:before:border formkit-loading:before:border-2 formkit-loading:before:border-r-transparent formkit-loading:before:rounded-3xl formkit-loading:before:border-white formkit-loading:before:animate-spin [&.is-inverted]:border-2 [&.is-inverted]:border-primary [&.is-inverted:disabled]:border-transparent [&.is-inverted]:text-primary [&.is-inverted]:bg-white [&.is-inverted]:hover:border-black [&.is-inverted]:hover:text-black [&.is-tight]:px-2 [&.is-tight]:text-sm [&.is-tight]:leading-none': true
    },
    'submit__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'submit__messages': {
        '': true
    },
    'submit__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'tel__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'tel__wrapper': {},
    'tel__label': {
        'block': true,
        'text-gray-700': true,
        'text-lg': true,
        'font-bold': true,
        'mb-2': true
    },
    'tel__prefixIcon': {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        '-ml-1': true,
        'mr-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true
    },
    'tel__suffixIcon': {
        'flex': true,
        'items-center': true,
        '-mr-1': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true
    },
    'tel__inner': {
        '': true
    },
    'tel__input': {
        'appearance-none': true,
        '[color-scheme:light]': true,
        'placeholder:text-neutral-400': true
    },
    'tel__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'tel__messages': {
        '': true
    },
    'tel__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'text__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'text__wrapper': {},
    'text__label': {
        'block': true,
        'mb-2': true
    },
    'text__prefixIcon': {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        '-ml-1': true,
        'mr-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true
    },
    'text__suffixIcon': {
        'flex': true,
        'items-center': true,
        '-mr-1': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true
    },
    'text__inner': {
        '': true
    },
    'text__input': {
        'appearance-none': true,
        '[color-scheme:light]': true,
        'placeholder:text-neutral-400': true
    },
    'text__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'text__messages': {
        '': true
    },
    'text__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'textarea__outer': {
        'group': true,
        'flex': true,
        'flex-col': true,
        'flex-grow': true,
        'gap-2': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'textarea__wrapper': {},
    'textarea__label': {
        'block': true,
        'text-gray-700': true,
        'text-lg': true,
        'font-bold': true,
        'mb-2': true
    },
    'textarea__prefixIcon': {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        '-ml-1': true,
        'mr-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true,
        '!ml-2': true,
        '!mr-0': true,
        'text-neutral-600': true
    },
    'textarea__suffixIcon': {
        'flex': true,
        'items-center': true,
        '-mr-1': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true,
        '!mr-2': true,
        '!ml-0': true,
        'text-neutral-600': true
    },
    'textarea__inner': {
        'flex': true,
        'items-center': true,
        'focus-within:outline-none': true
    },
    'textarea__input': {
        'appearance-none': true,
        'p-4': true,
        'border-b-2': true,
        'border-slate-300': true,
        'rounded': true,
        'focus:border-slate-400': true,
        'focus:outline-[1px]': true,
        'focus:outline-slate-400': true,
        'text-base': true,
        'bg-gray-50': true,
        'h-24': true,
        'grow': true,
        'shrink': true,
        'focus-within:outline-none': false
    },
    'textarea__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'textarea__messages': {
        'mt-2': true
    },
    'textarea__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'time__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'time__wrapper': {},
    'time__label': {
        'block': true,
        'text-neutral-900': true,
        'text-sm': true,
        'mb-2': true
    },
    'time__prefixIcon': {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        '-ml-1': true,
        'mr-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true
    },
    'time__suffixIcon': {
        'flex': true,
        'items-center': true,
        '-mr-1': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true
    },
    'time__inner': {
        '': true
    },
    'time__input': {
        'appearance-none': true,
        '[color-scheme:light]': true,
        'placeholder:text-neutral-400': true
    },
    'time__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'time__messages': {
        'mt-2': true
    },
    'time__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'week__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'week__wrapper': {},
    'week__label': {
        'block': true,
        'text-neutral-900': true,
        'text-sm': true,
        'mb-2': true
    },
    'week__prefixIcon': {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        '-ml-1': true,
        'mr-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true
    },
    'week__suffixIcon': {
        'flex': true,
        'items-center': true,
        '-mr-1': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true
    },
    'week__inner': {
        '': true
    },
    'week__input': {
        'appearance-none': true,
        '[color-scheme:light]': true,
        'placeholder:text-neutral-400': true
    },
    'week__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'week__messages': {
        '': true
    },
    'week__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'autocomplete__outer': {
        'group': true,
        'flex-grow': true,
        'text-base': true,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    'autocomplete__wrapper': {
        '!mb-0': true
    },
    'autocomplete__label': {
        'block': true,
        'text-neutral-900': true,
        'text-sm': true,
        'mb-2': true
    },
    'autocomplete__inner': {
        '!w-20': true,
        '!h-full': true
    },
    'autocomplete__prefixIcon': {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        '-ml-1': true,
        'mr-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true
    },
    'autocomplete__suffixIcon': {
        'flex': true,
        'items-center': true,
        '-mr-1': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true
    },
    'autocomplete__input': {
        'appearance-none': true,
        '[color-scheme:light]': true,
        'placeholder:text-neutral-400': true,
        'w-full': true
    },
    'autocomplete__selections': {
        'flex': true,
        'absolute': true,
        'inset-0': true,
        'group-data-[multiple]:static': true,
        'group-data-[multiple]:block': true,
        'group-data-[empty]:hidden': true,
        'group-data-[multiple]:mt-1.5': true
    },
    'autocomplete__selectionWrapper': {
        'bg-neutral-100': true,
        'rounded-none': true,
        'group-data-[multiple]:border': true,
        'group-data-[multiple]:border-neutral-900': true,
        'group-data-[multiple]:mb-1.5': true,
        'data-[active-selection=true]:ring-2': true
    },
    'autocomplete__selection': {
        'rounded-none': true,
        'pl-2': true,
        '[&>*]:ml-0': true
    },
    'autocomplete__loaderIcon': {
        'animate-spin': true,
        'flex': true,
        'items-center': true,
        'my-auto': true,
        'ml-2': true,
        'text-base': true,
        'text-neutral-900': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true
    },
    'autocomplete__removeSelection': {
        '': true
    },
    'autocomplete__listboxButton': {
        '': true
    },
    'autocomplete__selectIcon': {
        '': true
    },
    'autocomplete__dropdownWrapper': {
        '': true
    },
    'autocomplete__listbox': {
        '': true
    },
    'autocomplete__listitem': {
        '!pl-0': true
    },
    'autocomplete__selectedIcon': {
        '': true
    },
    'autocomplete__option': {
        '': true
    },
    'autocomplete__emptyMessage': {
        '': true
    },
    'autocomplete__emptyMessageInner': {
        '': true
    },
    'autocomplete__help': {
        'text-neutral-900': true,
        'text-xs': true
    },
    'autocomplete__messages': {
        '': true
    },
    'autocomplete__message': {
        'text-red-600': true,
        'text-xs': true
    },
    'datepicker__inner': {
        'relative': true,
        'pl-0': true
    },
    'datepicker__removeSelection': {
        'self-stretch': true,
        'text-base': true,
        'flex': true,
        'items-center': true,
        'ml-1': true,
        'mr-2': true,
        'text-neutral-700': true,
        'hover:text-red-400': true,
        'z-10': true
    },
    'datepicker__clearIcon': {
        '[&>svg]:w-[0.75em]': true
    },
    'datepicker__panelWrapper': {
        'group/panel': true,
        'absolute': true,
        'min-w-[20em]': true,
        'top-[calc(100%_-_4em)]': true,
        'shadow-[0_0_1.25em_rgba(0,0,0,.25)]': true,
        'rounded': true,
        'p-4': true,
        'bg-white': true,
        'z-50': true,
        '[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:!fixed': true,
        '[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:top-auto': true,
        '[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:max-w-none': true,
        '[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:bottom-16': true,
        '[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:left-0': true,
        '[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:rounded-none': true,
        '[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:w-full': true
    },
    'datepicker__panelHeader': {
        'grid': true,
        'grid-cols-[2.5em_1fr_2.5em]': true,
        'justify-center': true,
        'items-center': true,
        '[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:grid-cols-[2.5em_1fr_2.5em_2.5em]': true,
        '[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:group-data-[panel=time]/panel:grid-cols-[2.5em_1fr_2.5em]': true,
        '[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:group-data-[panel=month]/panel:grid-cols-[2.5em_1fr_2.5em]': true
    },
    'datepicker__panelClose': {
        'aspect-[1/1]': true,
        'border': true,
        'border-neutral-400': true,
        'rounded-none': true,
        'flex': true,
        'items-center': true,
        'justify-center': true,
        'text-neutral-900': true,
        '[&_svg]:w-[1.25em]': true,
        '[@media(max-width:431px)_and_(hover:none)]:group-data-[panel=time]/panel:col-start-3': true,
        '[@media(max-width:431px)_and_(hover:none)]:group-data-[panel=month]/panel:col-start-3': true
    },
    'datepicker__panel': {
        'flex': true,
        'justify-center': true
    },
    'datepicker__input': {
        'appearance-none': true,
        'selection:bg-sky-100': true,
        'selection:text-neutral-700': true,
        'group-data-[has-overlay]:selection:!text-transparent': true,
        'pl-3': true,
        'placeholder:text-neutral-400': true
    },
    'datepicker__monthsHeader': {
        'flex': true,
        'items-center': true,
        'justify-center': true,
        'col-start-2': true,
        'col-end-2': true
    },
    'datepicker__timeHeader': {
        'flex': true,
        'items-center': true,
        'justify-center': true,
        'col-start-2': true,
        'col-end-2': true
    },
    'datepicker__months': {
        'grid': true,
        'grid-cols-3': true,
        'w-full': true
    },
    'datepicker__month': {
        'm-1.5': true,
        'p-1.5': true,
        'text-center': true,
        'text-neutral-700': true,
        'rounded': true,
        'bg-neutral-200': true,
        'aria-selected:!bg-sky-600': true,
        'aria-selected:!text-white': true,
        'focus:outline': true,
        'focus:outline-2': true,
        'focus:outline-sky-600': true,
        'focus:outline-offset-2': true,
        'focus:bg-white': true,
        'focus:text-neutral-700': true,
        'data-[is-extra=true]:opacity-25': true,
        'group-data-[disabled=true]:opacity-50': true,
        'group-data-[disabled=true]:cursor-default': true,
        'group-data-[disabled=true]:pointer-events-none': true
    },
    'datepicker__yearsHeader': {
        'flex': true,
        'items-center': true,
        'justify-center': true,
        'text-neutral-700': true,
        'col-start-2': true,
        'col-end-2': true
    },
    'datepicker__years': {
        'grid': true,
        'grid-cols-5': true,
        'w-full': true
    },
    'datepicker__year': {
        'text-base': true,
        'text-center': true,
        'text-neutral-700': true,
        'items-center': true,
        'm-1.5': true,
        'p-1.5': true,
        'rounded': true,
        'bg-neutral-200': true,
        'aria-selected:!bg-sky-600': true,
        'aria-selected:!text-white': true,
        'focus:outline': true,
        'focus:outline-2': true,
        'focus:outline-sky-600': true,
        'focus:outline-offset-2': true,
        'focus:bg-white': true,
        'data-[is-extra=true]:opacity-25': true,
        'group-data-[disabled=true]:opacity-50': true,
        'group-data-[disabled=true]:cursor-default': true,
        'group-data-[disabled=true]:pointer-events-none': true
    },
    'datepicker__weekDays': {
        'grid': true,
        'grid-cols-7': true
    },
    'datepicker__weekDay': {
        'w-[2.25em]': true,
        'text-neutral-700': true,
        'm-1.5': true,
        'rounded': true,
        'font-medium': true,
        'lowercase': true
    },
    'datepicker__calendarWeeks': {
        '': true
    },
    'datepicker__week': {
        'grid': true,
        'grid-cols-7': true,
        'group-data-[disabled=true]:opacity-50': true,
        'group-data-[disabled=true]:cursor-default': true,
        'group-data-[disabled=true]:pointer-events-none': true
    },
    'datepicker__day': {
        '!bg-transparent': true,
        '!border-none': true
    },
    'datepicker__dayCell': {
        'flex': true,
        'items-center': true,
        'justify-center': true,
        'text-center': true,
        'text-neutral-700': true,
        'w-[2.25em]': true,
        'h-[2.25em]': true,
        'm-1': true,
        'p-2': true,
        'rounded': true,
        'bg-neutral-200': true,
        'aria-selected:bg-neutral-200': true,
        'aria-selected:text-primary': true,
        'focus:outline': true,
        'focus:outline-2': true,
        'focus:outline-sky-600': true,
        'focus:text-neutral-900': true,
        'focus:bg-neutral-200': true,
        'focus:outline-offset-2': true,
        'focus:bg-white': true,
        'data-[is-extra=true]:opacity-25': true,
        'data-[disabled=true]:opacity-25': true,
        'data-[disabled=true]:cursor-default': true,
        'data-[disabled=true]:pointer-events-none': true
    },
    'datepicker__timeInput': {
        'w-full': true,
        'border-2': true,
        'text-neutral-700': true,
        'border-neutral-300': true,
        'rounded': true,
        'p-1.5': true,
        'my-2.5': true,
        'focus-visible:outline-sky-600': true
    },
    'datepicker__daysHeader': {
        'flex': true,
        'items-center': true,
        'justify-center': true
    },
    'datepicker__prev': {
        'mr-auto': true,
        'px-2.5': true,
        'py-0.5': true,
        'hover:bg-neutral-100': true,
        'rounded': true,
        'col-start-1': true,
        'col-end-1': true,
        'focus-visible:outline-none': true,
        'focus-visible:ring-2': true,
        'focus-visible:ring-sky-500': true,
        'focus-visible:ring-offset-2': true
    },
    'datepicker__prevLabel': {
        hidden: true
    },
    'datepicker__prevIcon': {
        'flex': true,
        'w-[0.75em]': true,
        'select-none': true,
        'text-neutral-700': true,
        '[&>svg]:w-full': true
    },
    'datepicker__dayButton': {
        'appearance-none': true,
        'text-neutral-700': true,
        'cursor-pointer': true,
        'px-2.5': true,
        'py-0.5': true,
        'border-2': true,
        'border-neutral-300': true,
        'rounded': true,
        'mx-1': true,
        'hover:border-sky-600': true,
        'focus-visible:outline-none': true,
        'focus-visible:ring-2': true,
        'focus-visible:ring-sky-500': true,
        'focus-visible:ring-offset-2': true
    },
    'datepicker__monthButton': {
        'appearance-none mr-2 cursor-pointer': true,
        'text-neutral-700': true
    },
    'datepicker__yearButton': {
        'appearance-none mr-2 cursor-pointer': true,
        'text-neutral-700': true
    },
    'datepicker__next': {
        'ml-auto': true,
        'px-2.5': true,
        'py-0.5': true,
        'rounded': true,
        'hover:bg-neutral-100': true,
        'hover:rounded': true,
        'col-start-3': true,
        'col-end-3': true,
        'focus-visible:outline-none': true,
        'focus-visible:ring-2': true,
        'focus-visible:ring-sky-500': true,
        'focus-visible:ring-offset-2': true
    },
    'datepicker__nextLabel': {
        hidden: true
    },
    'datepicker__nextIcon': {
        'flex': true,
        'w-[0.75em]': true,
        'select-none': true,
        'text-neutral-700': true,
        '[&>svg]:w-full': true
    },
    'datepicker__openButton': {
        'appearance-none': true,
        'border-0': true,
        'bg-transparent': true,
        'flex': true,
        'absolute': true,
        'right-4': true,
        'top-1/2': true,
        'z-10': true,
        'transform': true,
        'translate-y-[-50%]': true,
        'p-0': true,
        'self-stretch': true,
        'cursor-pointer': true,
        'focus-visible:outline-none': true,
        'focus-visible:ring-2': true,
        'focus-visible:ring-sky-500': true,
        'focus-visible:ring-offset-2': true,
        'focus-visible:rounded': true
    },
    'datepicker__calendarIcon': {
        'text-neutral-600': true,
        'focus-visible:text-sky-600': true,
        'flex': true,
        'w-[1em]': true,
        'grow-0': true,
        'shrink-0': true,
        'self-stretch': true,
        'select-none': true,
        '[&>svg]:w-full': true,
        '[&>svg]:m-auto': true,
        '[&>svg]:max-h-[1em]': true,
        '[&>svg]:max-w-[1em]': true
    },
    'stepNumber__outer': {
        'flex ': true,
        'items-center': true,
        'justify-between': true,
        'rounded-full': false,
        'bg-gray-100': false,
        '[&:disabled]:select-none': true,
        '[&:disabled]:opacity-50': true,
        '[&:disabled]:cursor-not-allowed': true,
        '[&.is-small]:text-xs': true,
        '[&.is-small_.formkit-minus]:p-1.5': true,
        '[&.is-small_.formkit-plus]:p-1.5': true,
        '[&.is-small_.formkit-container]:flex-col': true
    },
    'stepNumber__wrapper': {
        'flex items-center': true
    },
    'stepNumber__inner': {
        flex: true
    },
    'stepNumber__inputContainer': {
        'flex': true,
        'h-10 rounded-3xl shadow-[0px_3px_2px_rgba(206,214,224,1)]': true
    },
    'stepNumber__container': {
        'flex': true,
        'items-center': true,
        'gap-2': true
    },
    'stepNumber__minus': {
        'p-3 bg-gray-100 rounded-l-full [&:disabled]:select-none [&:disabled]:opacity-50 [&:disabled]:cursor-not-allowed': true
    },
    'stepNumber__plus': {
        'p-3 bg-gray-100 rounded-r-full [&:disabled]:select-none [&:disabled]:opacity-50 [&:disabled]:cursor-not-allowed': true
    },
    'stepNumber__step': {
        'flex text-center w-8 bg-gray-100 [-moz-appearance:_textfield] [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&:disabled]:select-none [&:disabled]:opacity-50 [&:disabled]:cursor-not-allowed': true
    },
    'stepNumber__error': {
        'text-gray-500 text-xxs text-center': true
    }
}

const globals = {
    outer: {
        'group': true,
        'flex-grow': true,
        'w-full': true,
        'text-base': true,
        'mb-4': false,
        'data-[disabled]:select-none': true,
        'data-[disabled]:opacity-50': true,
        'data-[disabled]:pointer-events-none': true
    },
    wrapper: {},
    input: {
        'appearance-none': true,
        'border': true,
        '[color-scheme:light]': true,
        'placeholder:text-neutral-400': true
    },
    placeholder: {
        'text-neutral-400': true
    },
    prefixIcon: {
        'flex': true,
        'shrink-0': true,
        'items-center': true,
        '-ml-1': true,
        'mr-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        '[&>svg]:w-full': true
    },
    suffixIcon: {
        'flex': true,
        'items-center': true,
        '-mr-1': true,
        'ml-2': true,
        'text-base': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true
    },
    loaderIcon: {
        'animate-spin': true,
        'flex': true,
        'items-center': true,
        'my-auto': true,
        'ml-2': true,
        'text-base': true,
        'text-neutral-900': true,
        'h-[1em]': true,
        'w-[1em]': true,
        'shrink-0': true,
        '[&>svg]:w-full': true
    },
    loadMoreInner: {
        'flex': true,
        'text-sm': true,
        'text-neutral-900': true,
        'p-2': true,
        '[&>span]:mr-2': true
    },
    help: {
        'text-neutral-900': true,
        'text-xs': true
    },
    messages: {
        '': true
    },
    message: {
        'text-red-600': true,
        'text-xs': true
    },
    overlay: {
        'text-neutral-900': true
    },
    overlayPlaceholder: {
        'text-neutral-900': true
    },
    overlayLiteral: {
        'text-neutral-900': true
    },
    overlayChar: {
        'text-neutral-900': true
    },
    overlayEnum: {
        'text-neutral-900': true
    }
}

// TODO: Fix issues where global classes are not being applied
export function rootClasses (sectionName, node) {
    const key = `${node.props.type}__${sectionName}`
    const semanticKey = `formkit-${sectionName}`
    const familyKey = node.props.family ? `family:${node.props.family}__${sectionName}` : ''
    const memoKey = `${key}__${familyKey}`
    if (!(memoKey in classes)) {
        const sectionClasses = classes[key] ?? globals[sectionName] ?? {}
        sectionClasses[semanticKey] = true
        if (familyKey in classes) {
            classes[memoKey] = { ...classes[familyKey], ...sectionClasses }
        } else {
            classes[memoKey] = sectionClasses
        }
    }
    return classes[memoKey] ?? { [semanticKey]: true }
}
