import validate from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.13.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_typ_zbvvoyunohqgjrsyhyqxfkitmq/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45webshop_45settings_45global from "/opt/atlassian/pipelines/agent/build/middleware/01-webshopSettings.global.ts";
import _02_45default_45lang_45global from "/opt/atlassian/pipelines/agent/build/middleware/02-default-lang.global.ts";
import _03_45unavailable_45lang_45404_45global from "/opt/atlassian/pipelines/agent/build/middleware/03-unavailable-lang-404.global.ts";
import _04_45menu_45global from "/opt/atlassian/pipelines/agent/build/middleware/04-menu.global.ts";
import manifest_45route_45rule from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.13.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_typ_zbvvoyunohqgjrsyhyqxfkitmq/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45webshop_45settings_45global,
  _02_45default_45lang_45global,
  _03_45unavailable_45lang_45404_45global,
  _04_45menu_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}