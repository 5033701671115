export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: { input: string, output: string }
    String: { input: string, output: string }
    Boolean: { input: boolean, output: boolean }
    Int: { input: number, output: number }
    Float: { input: number, output: number }
    Date: { input: any, output: any }
    DateTime: { input: any, output: any }
    Duration: { input: any, output: any }
    Time: { input: any, output: any }
    UUID: { input: any, output: any }
}

export interface AccessTokenOrderBuddy {
    __typename?: 'AccessToken'
    token: Scalars['String']['output']
}

export interface AddressOrderBuddy {
    __typename?: 'Address'
    address?: Maybe<Scalars['String']['output']>
    city?: Maybe<Scalars['String']['output']>
    country_code?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    postalCode?: Maybe<Scalars['String']['output']>
    state_code?: Maybe<Scalars['String']['output']>
}

export interface AddressInputOrderBuddy {
    address2?: InputMaybe<Scalars['String']['input']>
    address3?: InputMaybe<Scalars['String']['input']>
    city: Scalars['String']['input']
    company?: InputMaybe<Scalars['String']['input']>
    country: Scalars['String']['input']
    email: Scalars['String']['input']
    firstName: Scalars['String']['input']
    lastName: Scalars['String']['input']
    phone: Scalars['String']['input']
    postalCode: Scalars['String']['input']
    street: Scalars['String']['input']
    streetNumber: Scalars['String']['input']
}

export enum AllergenOrderBuddy {
    ALCOHOL = 'ALCOHOL',
    CELERY = 'CELERY',
    CRAB = 'CRAB',
    EGGS = 'EGGS',
    FISH = 'FISH',
    HALAL = 'HALAL',
    LUPINE = 'LUPINE',
    MILK = 'MILK',
    MUSTARD = 'MUSTARD',
    NO_GLUTEN = 'NO_GLUTEN',
    NUT = 'NUT',
    NUT_TRACES = 'NUT_TRACES',
    PEANUTS = 'PEANUTS',
    PEANUTS_TRACES = 'PEANUTS_TRACES',
    SESAME = 'SESAME',
    SHELLFISH = 'SHELLFISH',
    SO2 = 'SO2',
    SOY = 'SOY',
    VEGAN = 'VEGAN',
    VEGGAN = 'VEGGAN',
    WHEAT = 'WHEAT'
}

export interface AssetOrderBuddy {
    __typename?: 'Asset'
    disk?: Maybe<Scalars['String']['output']>
    path?: Maybe<Scalars['String']['output']>
    url?: Maybe<Scalars['String']['output']>
}

export interface BillingContactInputOrderBuddy {
    address2?: InputMaybe<Scalars['String']['input']>
    address3?: InputMaybe<Scalars['String']['input']>
    city?: InputMaybe<Scalars['String']['input']>
    cocNr?: InputMaybe<Scalars['String']['input']>
    company?: InputMaybe<Scalars['String']['input']>
    country?: InputMaybe<Scalars['String']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    phoneCountry?: InputMaybe<Scalars['String']['input']>
    postalCode?: InputMaybe<Scalars['String']['input']>
    street?: InputMaybe<Scalars['String']['input']>
    streetNumber?: InputMaybe<Scalars['String']['input']>
    vatNr?: InputMaybe<Scalars['String']['input']>
}

/** Allowed column names for Query.categories.orderBy */
export enum CategoriesColumnOrderBuddy {
    ID = 'ID',
    PRIORITY = 'PRIORITY',
    TITLE = 'TITLE'
}

export interface CategoryOrderBuddy {
    __typename?: 'Category'
    allergies?: Maybe<Array<Maybe<AllergenOrderBuddy>>>
    autoPopCustomer?: Maybe<Scalars['Int']['output']>
    childCategories?: Maybe<Array<CategoryOrderBuddy>>
    days?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    defaultPriority?: Maybe<Scalars['Int']['output']>
    deliveryCosts?: Maybe<Scalars['Float']['output']>
    description?: Maybe<Scalars['String']['output']>
    endDate?: Maybe<Scalars['DateTime']['output']>
    extras?: Maybe<Array<ExtraOrderBuddy>>
    hasAlcohol?: Maybe<Scalars['Boolean']['output']>
    hasProductsCentered?: Maybe<Scalars['String']['output']>
    hasRunners?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['Int']['output']
    image?: Maybe<AssetOrderBuddy>
    isBio?: Maybe<Scalars['Boolean']['output']>
    isHalal?: Maybe<Scalars['Boolean']['output']>
    isPopularCategory?: Maybe<Scalars['Boolean']['output']>
    isVegan?: Maybe<Scalars['Boolean']['output']>
    menuType?: Maybe<MenuTypeOrderBuddy>
    minimumAge?: Maybe<Scalars['Int']['output']>
    packagingCosts?: Maybe<Scalars['Float']['output']>
    parentCategory?: Maybe<CategoryOrderBuddy>
    products?: Maybe<Array<ProductOrderBuddy>>
    showType?: Maybe<Scalars['String']['output']>
    sortby?: Maybe<CategorySortbyOrderBuddy>
    spiceLevel?: Maybe<Scalars['Int']['output']>
    startDate?: Maybe<Scalars['DateTime']['output']>
    title?: Maybe<Scalars['String']['output']>
}

export interface CategoryextrasOrderBuddyArgs {
    orderBy?: InputMaybe<Array<CategoryExtrasOrderByOrderByClauseOrderBuddy>>
}

export interface CategoryproductsOrderBuddyArgs {
    orderBy?: InputMaybe<Array<CategoryProductsOrderByOrderByClauseOrderBuddy>>
}

export interface CategoryBelongsToOrderBuddy {
    connect?: InputMaybe<Scalars['ID']['input']>
}

/** Order by clause for Category.extras.orderBy. */
export interface CategoryExtrasOrderByOrderByClauseOrderBuddy {
    /** The column that is used for ordering. */
    column: ExtrasColumnOrderBuddy
    /** The direction that is used for ordering. */
    order: SortOrderOrderBuddy
}

export interface CategoryFilterOrderBuddy {
    menuType?: InputMaybe<MenuTypeOrderBuddy>
    title?: InputMaybe<Scalars['String']['input']>
}

export interface CategoryHasManyOrderBuddy {
    connect?: InputMaybe<Scalars['ID']['input']>
}

/** Order by clause for Category.products.orderBy. */
export interface CategoryProductsOrderByOrderByClauseOrderBuddy {
    /** The column that is used for ordering. */
    column: ProductsColumnOrderBuddy
    /** The direction that is used for ordering. */
    order: SortOrderOrderBuddy
}

export enum CategorySortbyOrderBuddy {
    NONE = 'NONE',
    PRICE_ASC = 'PRICE_ASC',
    PRICE_DESC = 'PRICE_DESC',
    TITLE_ASC = 'TITLE_ASC',
    TITLE_DESC = 'TITLE_DESC'
}

export interface ClientOrderBuddy {
    __typename?: 'Client'
    address?: Maybe<Scalars['String']['output']>
    altTel?: Maybe<Scalars['String']['output']>
    city?: Maybe<Scalars['String']['output']>
    cocNumber?: Maybe<Scalars['String']['output']>
    company?: Maybe<Scalars['String']['output']>
    contact?: Maybe<Scalars['String']['output']>
    coord?: Maybe<CoordinatesOrderBuddy>
    country_code?: Maybe<Scalars['String']['output']>
    dateCreate?: Maybe<Scalars['DateTime']['output']>
    domain?: Maybe<Scalars['String']['output']>
    email?: Maybe<Scalars['String']['output']>
    https?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['ID']['output']
    msgRead?: Maybe<Scalars['String']['output']>
    postalCode?: Maybe<Scalars['String']['output']>
    reseller?: Maybe<ResellerOrderBuddy>
    state_code?: Maybe<Scalars['String']['output']>
    status?: Maybe<ClientStatusOrderBuddy>
    subdomain?: Maybe<Scalars['String']['output']>
    tel?: Maybe<Scalars['String']['output']>
    titleCounter?: Maybe<Scalars['String']['output']>
    uuid?: Maybe<Scalars['String']['output']>
    vatNumber?: Maybe<Scalars['String']['output']>
    website?: Maybe<Scalars['String']['output']>
}

export interface ClientBelongsToOrderBuddy {
    connect?: InputMaybe<Scalars['ID']['input']>
}

export interface ClientFilterOrderBuddy {
    company?: InputMaybe<Scalars['String']['input']>
}

export interface ClientOpenOrderBuddy {
    __typename?: 'ClientOpen'
    date?: Maybe<Scalars['String']['output']>
    dayName?: Maybe<WeekDayOrderBuddy>
    dayNumber?: Maybe<Scalars['Int']['output']>
    dispatchType?: Maybe<DispatchTypeOrderBuddy>
    isOpen?: Maybe<Scalars['Boolean']['output']>
    openingHoursRange?: Maybe<Array<Maybe<OpeningHoursOrderBuddy>>>
    reasonClosed?: Maybe<Scalars['String']['output']>
}

export interface ClientOpenBelongsToOrderBuddy {
    connect?: InputMaybe<Scalars['ID']['input']>
}

export interface ClientOpenFilterOrderBuddy {
    dispatchType?: InputMaybe<DispatchTypeOrderBuddy>
}

/** A paginated list of ClientOpen items. */
export interface ClientOpenPaginatorOrderBuddy {
    __typename?: 'ClientOpenPaginator'
    /** A list of ClientOpen items. */
    data: Array<ClientOpenOrderBuddy>
    /** Pagination information about the list of items. */
    paginatorInfo: PaginatorInfoOrderBuddy
}

export enum ClientStatusOrderBuddy {
    FINANCIAL_OVERVIEW = 'FINANCIAL_OVERVIEW',
    LIVE = 'LIVE',
    LIVE_CONDITIONAL = 'LIVE_CONDITIONAL',
    PROSPECT = 'PROSPECT',
    SPLASH = 'SPLASH',
    TEST = 'TEST'
}

export interface ContentBlockOrderBuddy {
    __typename?: 'ContentBlock'
    buttonText?: Maybe<Scalars['String']['output']>
    buttonUrl?: Maybe<Scalars['String']['output']>
    image?: Maybe<AssetOrderBuddy>
    imagePosition?: Maybe<Scalars['String']['output']>
    shouldShowButton?: Maybe<Scalars['Boolean']['output']>
    text?: Maybe<Scalars['String']['output']>
    title?: Maybe<Scalars['String']['output']>
}

export interface CoordinatesOrderBuddy {
    __typename?: 'Coordinates'
    coordinates?: Maybe<LatLongOrderBuddy>
    type?: Maybe<Scalars['String']['output']>
}

export interface CountryOrderBuddy {
    __typename?: 'Country'
    alpha2Code: Scalars['String']['output']
    alpha3Code: Scalars['String']['output']
    currencyCode?: Maybe<Scalars['String']['output']>
    currencyName?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isEea: Scalars['Boolean']['output']
    isdCode?: Maybe<Scalars['String']['output']>
    name: Scalars['String']['output']
    numeric: Scalars['String']['output']
    region: Scalars['String']['output']
    uuid: Scalars['UUID']['output']
}

export interface CountryBelongsToOrderBuddy {
    connect?: InputMaybe<Scalars['ID']['input']>
}

export interface CountryFilterOrderBuddy {
    alpha2Code?: InputMaybe<Scalars['String']['input']>
    alpha2Codes?: InputMaybe<Array<Scalars['String']['input']>>
    currencyCode?: InputMaybe<Scalars['String']['input']>
    currencyCodes?: InputMaybe<Array<Scalars['String']['input']>>
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
    isEea?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    region?: InputMaybe<Scalars['String']['input']>
    regions?: InputMaybe<Array<Scalars['String']['input']>>
}

export interface CustomerOrderBuddy {
    __typename?: 'Customer'
    address2?: Maybe<Scalars['String']['output']>
    address3?: Maybe<Scalars['String']['output']>
    ageRange?: Maybe<Scalars['String']['output']>
    altPhone?: Maybe<Scalars['String']['output']>
    altPhoneType?: Maybe<PhoneTypeOrderBuddy>
    birthdate?: Maybe<Scalars['Date']['output']>
    city?: Maybe<Scalars['String']['output']>
    client?: Maybe<ClientOrderBuddy>
    company?: Maybe<Scalars['String']['output']>
    coordinates?: Maybe<CoordinatesOrderBuddy>
    country?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['DateTime']['output']>
    email?: Maybe<Scalars['String']['output']>
    firstName?: Maybe<Scalars['String']['output']>
    gender?: Maybe<GenderOrderBuddy>
    id: Scalars['ID']['output']
    invAddress2?: Maybe<Scalars['String']['output']>
    invAddress3?: Maybe<Scalars['String']['output']>
    invAddressSame?: Maybe<Scalars['Boolean']['output']>
    invCity?: Maybe<Scalars['String']['output']>
    invCocnr?: Maybe<Scalars['String']['output']>
    invCompany?: Maybe<Scalars['String']['output']>
    invCountry?: Maybe<Scalars['String']['output']>
    invEmail?: Maybe<Scalars['String']['output']>
    invName?: Maybe<Scalars['String']['output']>
    invPostalCode?: Maybe<Scalars['String']['output']>
    invStreet?: Maybe<Scalars['String']['output']>
    invStreetnumber?: Maybe<Scalars['String']['output']>
    invTel?: Maybe<Scalars['String']['output']>
    invTelType?: Maybe<PhoneTypeOrderBuddy>
    invVatnr?: Maybe<Scalars['String']['output']>
    isBlocked?: Maybe<Scalars['Boolean']['output']>
    language?: Maybe<LanguageOrderBuddy>
    lastName?: Maybe<Scalars['String']['output']>
    mailing?: Maybe<Scalars['Boolean']['output']>
    mailingBounce?: Maybe<Scalars['Boolean']['output']>
    mailingpref?: Maybe<Array<Maybe<MailingTypeOrderBuddy>>>
    notes?: Maybe<Scalars['String']['output']>
    orders?: Maybe<Array<Maybe<OrderOrderBuddy>>>
    paymentMethods?: Maybe<Scalars['String']['output']>
    paymentMethodsRequest?: Maybe<Scalars['String']['output']>
    phone?: Maybe<Scalars['String']['output']>
    phoneType?: Maybe<PhoneTypeOrderBuddy>
    points?: Maybe<Scalars['Int']['output']>
    postalCode?: Maybe<Scalars['String']['output']>
    prevOrders?: Maybe<Scalars['Int']['output']>
    smsBounce?: Maybe<Scalars['Boolean']['output']>
    street?: Maybe<Scalars['String']['output']>
    streetNumber?: Maybe<Scalars['String']['output']>
    type?: Maybe<CustomerTypeOrderBuddy>
    uuid?: Maybe<Scalars['UUID']['output']>
}

export interface CustomerBelongsToOrderBuddy {
    connect?: InputMaybe<Scalars['ID']['input']>
    create?: InputMaybe<CustomerInputOrderBuddy>
    update?: InputMaybe<CustomerInputOrderBuddy>
    upsert?: InputMaybe<CustomerInputOrderBuddy>
}

export interface CustomerFilterOrderBuddy {
    clientId?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    zipcode?: InputMaybe<Scalars['String']['input']>
}

export interface CustomerInputOrderBuddy {
    address2?: InputMaybe<Scalars['String']['input']>
    address3?: InputMaybe<Scalars['String']['input']>
    ageRange?: InputMaybe<Scalars['String']['input']>
    altPhone?: InputMaybe<Scalars['String']['input']>
    altPhoneCountry?: InputMaybe<Scalars['String']['input']>
    birthdate?: InputMaybe<Scalars['Date']['input']>
    city?: InputMaybe<Scalars['String']['input']>
    company?: InputMaybe<Scalars['String']['input']>
    country?: InputMaybe<Scalars['String']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    firstName?: InputMaybe<Scalars['String']['input']>
    gender?: InputMaybe<GenderOrderBuddy>
    id?: InputMaybe<Scalars['ID']['input']>
    invAddress2?: InputMaybe<Scalars['String']['input']>
    invAddress3?: InputMaybe<Scalars['String']['input']>
    invAddressSame?: InputMaybe<Scalars['Boolean']['input']>
    invCity?: InputMaybe<Scalars['String']['input']>
    invCocnr?: InputMaybe<Scalars['String']['input']>
    invCompany?: InputMaybe<Scalars['String']['input']>
    invCountry?: InputMaybe<Scalars['String']['input']>
    invEmail?: InputMaybe<Scalars['String']['input']>
    invName?: InputMaybe<Scalars['String']['input']>
    invStreet?: InputMaybe<Scalars['String']['input']>
    invStreetnumber?: InputMaybe<Scalars['String']['input']>
    invTel?: InputMaybe<Scalars['String']['input']>
    invTelType?: InputMaybe<PhoneTypeOrderBuddy>
    invVatnr?: InputMaybe<Scalars['String']['input']>
    invZipcode?: InputMaybe<Scalars['String']['input']>
    language?: InputMaybe<LanguageOrderBuddy>
    lastName?: InputMaybe<Scalars['String']['input']>
    mailing?: InputMaybe<Scalars['Boolean']['input']>
    mailingpref?: InputMaybe<Array<InputMaybe<MailingTypeOrderBuddy>>>
    notes?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    phoneCountry?: InputMaybe<Scalars['String']['input']>
    postalCode?: InputMaybe<Scalars['String']['input']>
    street?: InputMaybe<Scalars['String']['input']>
    streetNumber?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<CustomerTypeOrderBuddy>
    uuid?: InputMaybe<Scalars['UUID']['input']>
}

/** A paginated list of Customer items. */
export interface CustomerPaginatorOrderBuddy {
    __typename?: 'CustomerPaginator'
    /** A list of Customer items. */
    data: Array<CustomerOrderBuddy>
    /** Pagination information about the list of items. */
    paginatorInfo: PaginatorInfoOrderBuddy
}

export interface CustomerReviewOrderBuddy {
    __typename?: 'CustomerReview'
    client?: Maybe<ClientOrderBuddy>
    comment?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['DateTime']['output']>
    firstName?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    rating?: Maybe<Scalars['Float']['output']>
    updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export interface CustomerReviewBelongsToOrderBuddy {
    connect?: InputMaybe<Scalars['ID']['input']>
}

export enum CustomerReviewColumnOrderBuddy {
    DATE = 'DATE',
    ID = 'ID'
}

/** A paginated list of CustomerReview items. */
export interface CustomerReviewPaginatorOrderBuddy {
    __typename?: 'CustomerReviewPaginator'
    /** A list of CustomerReview items. */
    data: Array<CustomerReviewOrderBuddy>
    /** Pagination information about the list of items. */
    paginatorInfo: PaginatorInfoOrderBuddy
}

export interface CustomerReviewStatisticsOrderBuddy {
    __typename?: 'CustomerReviewStatistics'
    averageRating?: Maybe<Scalars['Float']['output']>
    hasReviewsEnabled?: Maybe<Scalars['Boolean']['output']>
    ratingPercentages?: Maybe<Array<Maybe<RatingPercentageOrderBuddy>>>
    totalReviews?: Maybe<Scalars['Int']['output']>
}

export enum CustomerTypeOrderBuddy {
    BUSINESS = 'BUSINESS',
    CONSUMER = 'CONSUMER'
}

export interface DelivererOrderBuddy {
    __typename?: 'Deliverer'
    avgInterval?: Maybe<Scalars['Int']['output']>
    avgN?: Maybe<Scalars['Int']['output']>
    client?: Maybe<ClientOrderBuddy>
    coord?: Maybe<CoordinatesOrderBuddy>
    coordUpdate?: Maybe<Scalars['DateTime']['output']>
    dateCreate?: Maybe<Scalars['String']['output']>
    delLanguage?: Maybe<LanguageOrderBuddy>
    email?: Maybe<Scalars['String']['output']>
    firstName?: Maybe<Scalars['String']['output']>
    googleDirectionsMode?: Maybe<GeoDirectionModeOrderBuddy>
    hasTrackingActivated?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['ID']['output']
    int1amount?: Maybe<Scalars['Float']['output']>
    int2amount?: Maybe<Scalars['Float']['output']>
    int3amount?: Maybe<Scalars['Float']['output']>
    isDeleted?: Maybe<Scalars['Boolean']['output']>
    lastLogin?: Maybe<Scalars['DateTime']['output']>
    lastName?: Maybe<Scalars['String']['output']>
    lastUa?: Maybe<Scalars['String']['output']>
    license?: Maybe<Scalars['String']['output']>
    login?: Maybe<Scalars['String']['output']>
    loginType?: Maybe<Scalars['String']['output']>
    ordersColor?: Maybe<Scalars['String']['output']>
    push?: Maybe<Scalars['String']['output']>
    rights?: Maybe<Scalars['String']['output']>
    startAmount?: Maybe<Scalars['Float']['output']>
    tel?: Maybe<Scalars['String']['output']>
    voipExt?: Maybe<Scalars['String']['output']>
}

export interface DelivererBelongsToOrderBuddy {
    connect?: InputMaybe<Scalars['ID']['input']>
}

export interface DelivererFilterOrderBuddy {
    clientId?: InputMaybe<Scalars['Int']['input']>
}

/** A paginated list of Deliverer items. */
export interface DelivererPaginatorOrderBuddy {
    __typename?: 'DelivererPaginator'
    /** A list of Deliverer items. */
    data: Array<DelivererOrderBuddy>
    /** Pagination information about the list of items. */
    paginatorInfo: PaginatorInfoOrderBuddy
}

export interface DeliveryOrderBuddy {
    __typename?: 'Delivery'
    deliveryAreas?: Maybe<Array<Maybe<DeliveryAreaOrderBuddy>>>
    deliveryCosts?: Maybe<Scalars['Float']['output']>
    deliveryCostsPerCategory?: Maybe<Scalars['Boolean']['output']>
    freeDeliveryThreshold?: Maybe<Scalars['Float']['output']>
    minimumOrderThreshold?: Maybe<Scalars['Float']['output']>
}

export interface DeliveryAreaOrderBuddy {
    __typename?: 'DeliveryArea'
    deliveryCosts?: Maybe<Scalars['Float']['output']>
    deliveryTime?: Maybe<Scalars['Duration']['output']>
    end?: Maybe<Scalars['String']['output']>
    freeDeliveryThreshold?: Maybe<Scalars['Float']['output']>
    id: Scalars['ID']['output']
    isAvailable?: Maybe<Scalars['Boolean']['output']>
    minimumOrderThreshold?: Maybe<Scalars['Float']['output']>
    name?: Maybe<Scalars['String']['output']>
    start?: Maybe<Scalars['String']['output']>
}

export interface DiscountProductOrderBuddy {
    __typename?: 'DiscountProduct'
    amount?: Maybe<Scalars['Float']['output']>
    code?: Maybe<Scalars['String']['output']>
    codeString?: Maybe<Scalars['String']['output']>
    count?: Maybe<Scalars['Int']['output']>
    customRules?: Maybe<Scalars['String']['output']>
    customVars?: Maybe<Scalars['String']['output']>
    days?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
    discount?: Maybe<Scalars['String']['output']>
    discountApp?: Maybe<Scalars['String']['output']>
    discountCounter?: Maybe<Scalars['String']['output']>
    discountKiosk?: Maybe<Scalars['String']['output']>
    discountQr?: Maybe<Scalars['String']['output']>
    discountQrTable?: Maybe<Scalars['String']['output']>
    end?: Maybe<Scalars['String']['output']>
    endTime?: Maybe<Scalars['String']['output']>
    id: Scalars['Int']['output']
    maximum?: Maybe<Scalars['Int']['output']>
    name?: Maybe<Scalars['String']['output']>
    productCount?: Maybe<Scalars['Int']['output']>
    products?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
    showAction?: Maybe<Scalars['String']['output']>
    showButton?: Maybe<Scalars['Boolean']['output']>
    showButtonAction?: Maybe<Scalars['String']['output']>
    showButtonText?: Maybe<Scalars['String']['output']>
    showCategories?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    showCategoryIdDeliver?: Maybe<Scalars['Int']['output']>
    showCategoryIdDinein?: Maybe<Scalars['Int']['output']>
    showCategoryIdPickup?: Maybe<Scalars['Int']['output']>
    showDescription?: Maybe<Scalars['String']['output']>
    showImage?: Maybe<AssetOrderBuddy>
    showImageBackground?: Maybe<AssetOrderBuddy>
    showImageBackgroundHeight?: Maybe<Scalars['Int']['output']>
    showIn?: Maybe<Array<Maybe<OrderInternalSourceOrderBuddy>>>
    showPosition?: Maybe<DiscountShowPositionOrderBuddy>
    showProductIdDeliver?: Maybe<Scalars['Int']['output']>
    showProductIdDinein?: Maybe<Scalars['Int']['output']>
    showProductIdPickup?: Maybe<Scalars['Int']['output']>
    showShop?: Maybe<Scalars['Boolean']['output']>
    showTitle?: Maybe<Scalars['String']['output']>
    start?: Maybe<Scalars['String']['output']>
    startTime?: Maybe<Scalars['String']['output']>
    used?: Maybe<Scalars['Int']['output']>
    validFor?: Maybe<Array<Maybe<DiscountValidForOrderBuddy>>>
}

export interface DiscountProductProductOrderBuddy {
    __typename?: 'DiscountProductProduct'
    amount?: Maybe<Scalars['Float']['output']>
    code?: Maybe<Scalars['String']['output']>
    codeString?: Maybe<Scalars['String']['output']>
    count?: Maybe<Scalars['Int']['output']>
    customRules?: Maybe<Scalars['String']['output']>
    customVars?: Maybe<Scalars['String']['output']>
    days?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
    discount?: Maybe<Scalars['String']['output']>
    end?: Maybe<Scalars['String']['output']>
    endTime?: Maybe<Scalars['String']['output']>
    freeProducts?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
    id: Scalars['Int']['output']
    inclExtras?: Maybe<Scalars['Boolean']['output']>
    matchItems?: Maybe<Scalars['String']['output']>
    maximum?: Maybe<Scalars['Int']['output']>
    name?: Maybe<Scalars['String']['output']>
    productCount?: Maybe<Scalars['Int']['output']>
    products?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
    showAction?: Maybe<Scalars['String']['output']>
    showButton?: Maybe<Scalars['Boolean']['output']>
    showButtonAction?: Maybe<Scalars['String']['output']>
    showButtonText?: Maybe<Scalars['String']['output']>
    showCategories?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    showCategoryIdDeliver?: Maybe<Scalars['Int']['output']>
    showCategoryIdDinein?: Maybe<Scalars['Int']['output']>
    showCategoryIdPickup?: Maybe<Scalars['Int']['output']>
    showDescription?: Maybe<Scalars['String']['output']>
    showImage?: Maybe<AssetOrderBuddy>
    showImageBackground?: Maybe<AssetOrderBuddy>
    showImageBackgroundHeight?: Maybe<Scalars['Int']['output']>
    showIn?: Maybe<Array<Maybe<OrderInternalSourceOrderBuddy>>>
    showPosition?: Maybe<DiscountShowPositionOrderBuddy>
    showProductIdDeliver?: Maybe<Scalars['Int']['output']>
    showProductIdDinein?: Maybe<Scalars['Int']['output']>
    showProductIdPickup?: Maybe<Scalars['Int']['output']>
    showShop?: Maybe<Scalars['Boolean']['output']>
    showTitle?: Maybe<Scalars['String']['output']>
    start?: Maybe<Scalars['String']['output']>
    startTime?: Maybe<Scalars['String']['output']>
    validFor?: Maybe<Array<Maybe<DiscountValidForOrderBuddy>>>
}

export enum DiscountShowPositionOrderBuddy {
    CART = 'CART',
    MENU = 'MENU',
    MENU_BOTTOM = 'MENU_BOTTOM',
    PRODUCTS = 'PRODUCTS'
}

export enum DiscountValidForOrderBuddy {
    DELIVER = 'DELIVER',
    DINE_IN = 'DINE_IN',
    PICKUP = 'PICKUP'
}

export enum DispatchTypeOrderBuddy {
    DELIVER = 'DELIVER',
    /**
     * Legacy status, don't use
     *
     * @deprecated
     */
    DELIVERY = 'DELIVERY',
    DINE_IN = 'DINE_IN',
    /**
     * Legacy status, don't use
     *
     * @deprecated
     */
    EAT_IN = 'EAT_IN',
    NONE = 'NONE',
    PICK_UP = 'PICK_UP'
}

export interface EmergencyBrakeOrderBuddy {
    __typename?: 'EmergencyBrake'
    closedTemporarily?: Maybe<Scalars['Boolean']['output']>
    deliveryClosedTemporarily?: Maybe<Scalars['Boolean']['output']>
    deliveryMinTimeTemporarily?: Maybe<Scalars['Float']['output']>
    pickupClosedTemporarily?: Maybe<Scalars['Boolean']['output']>
    pickupMinTimeTemporarily?: Maybe<Scalars['Float']['output']>
    temporarilySet?: Maybe<Scalars['Boolean']['output']>
}

export interface ExtraOrderBuddy {
    __typename?: 'Extra'
    extraId?: Maybe<Scalars['Int']['output']>
    hasSizeModifier?: Maybe<Scalars['Boolean']['output']>
    id?: Maybe<Scalars['Int']['output']>
    isAvailable?: Maybe<Scalars['Boolean']['output']>
    isConditional?: Maybe<Scalars['Boolean']['output']>
    isPizza?: Maybe<Scalars['Boolean']['output']>
    isVisible?: Maybe<Scalars['Boolean']['output']>
    items?: Maybe<Array<ItemOrderBuddy>>
    maximumSelectionQuantity?: Maybe<Scalars['Int']['output']>
    menuType?: Maybe<MenuTypeOrderBuddy>
    minimumSelectionQuantity?: Maybe<Scalars['Int']['output']>
    priceOverrides?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    priority?: Maybe<Scalars['Int']['output']>
    showOnExtraId?: Maybe<Scalars['Int']['output']>
    showOnItemId?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
    showPrices?: Maybe<Scalars['Boolean']['output']>
    title?: Maybe<Scalars['String']['output']>
    viewType?: Maybe<ViewTypeOrderBuddy>
}

export interface ExtraitemsOrderBuddyArgs {
    orderBy?: InputMaybe<Array<ExtraItemsOrderByOrderByClauseOrderBuddy>>
}

export interface ExtraBelongsToOrderBuddy {
    connect?: InputMaybe<Scalars['ID']['input']>
}

export interface ExtraFilterOrderBuddy {
    categoryId?: InputMaybe<Scalars['Int']['input']>
    clientId?: InputMaybe<Scalars['Int']['input']>
    productId?: InputMaybe<Scalars['Int']['input']>
    title?: InputMaybe<Scalars['String']['input']>
}

/** Order by clause for Extra.items.orderBy. */
export interface ExtraItemsOrderByOrderByClauseOrderBuddy {
    /** The column that is used for ordering. */
    column: ItemsColumnOrderBuddy
    /** The direction that is used for ordering. */
    order: SortOrderOrderBuddy
}

export enum ExtrasColumnOrderBuddy {
    ID = 'ID',
    PRIORITY = 'PRIORITY',
    TITLE = 'TITLE'
}

export interface FacebookSettingsOrderBuddy {
    __typename?: 'FacebookSettings'
    pixel?: Maybe<Scalars['String']['output']>
    url?: Maybe<Scalars['String']['output']>
}

export interface FinancialInstitutionOrderBuddy {
    __typename?: 'FinancialInstitution'
    id: Scalars['ID']['output']
    issuerId: Scalars['String']['output']
    name: Scalars['String']['output']
    syncedAt: Scalars['DateTime']['output']
    type: Scalars['String']['output']
}

/** A paginated list of FinancialInstitution items. */
export interface FinancialInstitutionPaginatorOrderBuddy {
    __typename?: 'FinancialInstitutionPaginator'
    /** A list of FinancialInstitution items. */
    data: Array<FinancialInstitutionOrderBuddy>
    /** Pagination information about the list of items. */
    paginatorInfo: PaginatorInfoOrderBuddy
}

export interface ForgotPasswordInputOrderBuddy {
    email?: InputMaybe<Scalars['String']['input']>
}

export interface ForgotPasswordResponseOrderBuddy {
    __typename?: 'ForgotPasswordResponse'
    message?: Maybe<Scalars['String']['output']>
    status: ForgotPasswordStatusOrderBuddy
}

export enum ForgotPasswordStatusOrderBuddy {
    /** EMAIL_SENT */
    EMAIL_SENT = 'EMAIL_SENT'
}

export enum GenderOrderBuddy {
    FEMALE = 'FEMALE',
    MALE = 'MALE',
    NONE = 'NONE',
    OTHER = 'OTHER'
}

export enum GeoDirectionModeOrderBuddy {
    BICYCLING = 'BICYCLING',
    DRIVING = 'DRIVING',
    WALKING = 'WALKING'
}

export interface HeroOrderBuddy {
    __typename?: 'Hero'
    image?: Maybe<AssetOrderBuddy>
    subtitle?: Maybe<Scalars['String']['output']>
    title?: Maybe<Scalars['String']['output']>
}

export interface InstagramSettingsOrderBuddy {
    __typename?: 'InstagramSettings'
    url?: Maybe<Scalars['String']['output']>
}

export interface ItemOrderBuddy {
    __typename?: 'Item'
    allergies?: Maybe<Array<Maybe<AllergenOrderBuddy>>>
    deposit?: Maybe<Scalars['Float']['output']>
    description?: Maybe<Scalars['String']['output']>
    hasAlcohol?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['String']['output']
    image?: Maybe<AssetOrderBuddy>
    isAvailable?: Maybe<Scalars['Boolean']['output']>
    isDisabled?: Maybe<Scalars['Boolean']['output']>
    isFirstQuantityFree?: Maybe<Scalars['Boolean']['output']>
    isSelected?: Maybe<Scalars['Boolean']['output']>
    maximumSelectionQuantity?: Maybe<Scalars['Int']['output']>
    packagingCosts?: Maybe<Scalars['Float']['output']>
    price?: Maybe<Scalars['Float']['output']>
    priority?: Maybe<Scalars['Int']['output']>
    title?: Maybe<Scalars['String']['output']>
}

export interface ItemBelongsToOrderBuddy {
    connect?: InputMaybe<Scalars['ID']['input']>
}

export interface ItemFilterOrderBuddy {
    categoryId?: InputMaybe<Scalars['Int']['input']>
    extraId?: InputMaybe<Scalars['Int']['input']>
    productId?: InputMaybe<Scalars['Int']['input']>
    title?: InputMaybe<Scalars['String']['input']>
}

export enum ItemsColumnOrderBuddy {
    ID = 'ID',
    PRIORITY = 'PRIORITY',
    TITLE = 'TITLE'
}

export interface KioskOrderBuddy {
    __typename?: 'Kiosk'
    kioskActive?: Maybe<Scalars['Boolean']['output']>
    kioskBarcode?: Maybe<Scalars['Boolean']['output']>
    kioskBuzzer?: Maybe<KioskBuzzerBlockOrderBuddy>
    kioskInfo?: Maybe<Array<KioskContentBlockOrderBuddy>>
    kioskLanguage?: Maybe<Scalars['String']['output']>
    kioskLanguageOptions?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    kioskLanguageShow?: Maybe<Scalars['Boolean']['output']>
    kioskLogo?: Maybe<AssetOrderBuddy>
    kioskMenuDinein?: Maybe<Scalars['Int']['output']>
    kioskMenuPickup?: Maybe<Scalars['Int']['output']>
    kioskNightmode?: Maybe<Scalars['Boolean']['output']>
    kioskNightmodeBrightness?: Maybe<Scalars['Float']['output']>
    kioskNightmodeRange?: Maybe<Scalars['String']['output']>
    kioskNologo?: Maybe<Scalars['Boolean']['output']>
    kioskPaymentCash?: Maybe<Scalars['Boolean']['output']>
    kioskPaymentPin?: Maybe<Scalars['Boolean']['output']>
    kioskPrint?: Maybe<Scalars['Boolean']['output']>
    kioskQrcode?: Maybe<Scalars['Boolean']['output']>
    kioskReceiptEmail?: Maybe<Scalars['Boolean']['output']>
    kioskReceiptPrint?: Maybe<Scalars['Boolean']['output']>
    kioskReceiptRemember?: Maybe<Scalars['Boolean']['output']>
    kioskReceiptScan?: Maybe<Scalars['Boolean']['output']>
    kioskReview?: Maybe<Scalars['Boolean']['output']>
    kioskReviewHide1?: Maybe<Scalars['Boolean']['output']>
    kioskReviewHide5?: Maybe<Scalars['Boolean']['output']>
    kioskShowSoldout?: Maybe<Scalars['Boolean']['output']>
    kioskSlideVideo?: Maybe<Scalars['String']['output']>
    kioskSlides?: Maybe<Array<Maybe<KioskSlideOrderBuddy>>>
    kioskSlideshow?: Maybe<Scalars['Float']['output']>
    kioskTitle?: Maybe<Scalars['String']['output']>
}

export interface KioskBuzzerBlockOrderBuddy {
    __typename?: 'KioskBuzzerBlock'
    kioskBuzzerText?: Maybe<kioskBuzzerTextOrderBuddy>
    kioskBuzzerTitle?: Maybe<KioskBuzzerTitleOrderBuddy>
    kioskBuzzerWhen?: Maybe<Scalars['String']['output']>
}

export interface KioskBuzzerTitleOrderBuddy {
    __typename?: 'KioskBuzzerTitle'
    ar?: Maybe<Scalars['String']['output']>
    de?: Maybe<Scalars['String']['output']>
    default?: Maybe<Scalars['String']['output']>
    en?: Maybe<Scalars['String']['output']>
    fr?: Maybe<Scalars['String']['output']>
    it?: Maybe<Scalars['String']['output']>
    nl?: Maybe<Scalars['String']['output']>
}

export interface KioskContentBlockOrderBuddy {
    __typename?: 'KioskContentBlock'
    kioskInfoBgImg?: Maybe<Scalars['String']['output']>
    kioskInfoButtonAction?: Maybe<Scalars['String']['output']>
    kioskInfoButtonActionCategoryIdDinein?: Maybe<Scalars['Int']['output']>
    kioskInfoButtonActionCategoryIdPickup?: Maybe<Scalars['Int']['output']>
    kioskInfoButtonActionProductIdDinein?: Maybe<Scalars['Int']['output']>
    kioskInfoButtonActionProductIdPickup?: Maybe<Scalars['Int']['output']>
    kioskInfoButtonPos?: Maybe<Scalars['String']['output']>
    kioskInfoButtonText?: Maybe<kioskInfoButtonTextOrderBuddy>
    kioskInfoShow?: Maybe<Scalars['Boolean']['output']>
    kioskInfoShowButton?: Maybe<Scalars['Boolean']['output']>
    kioskInfoShowWhen?: Maybe<Scalars['String']['output']>
    kioskInfoText?: Maybe<KioskInfoTextOrderBuddy>
    kioskInfoTitle?: Maybe<KioskInfoTitleOrderBuddy>
}

export interface KioskInfoTextOrderBuddy {
    __typename?: 'KioskInfoText'
    de?: Maybe<Scalars['String']['output']>
    default?: Maybe<Scalars['String']['output']>
    en?: Maybe<Scalars['String']['output']>
    fr?: Maybe<Scalars['String']['output']>
    it?: Maybe<Scalars['String']['output']>
    nl?: Maybe<Scalars['String']['output']>
}

export interface KioskInfoTitleOrderBuddy {
    __typename?: 'KioskInfoTitle'
    de?: Maybe<Scalars['String']['output']>
    default?: Maybe<Scalars['String']['output']>
    en?: Maybe<Scalars['String']['output']>
    fr?: Maybe<Scalars['String']['output']>
    it?: Maybe<Scalars['String']['output']>
    nl?: Maybe<Scalars['String']['output']>
}

export interface KioskSlideOrderBuddy {
    __typename?: 'KioskSlide'
    kioskSlide?: Maybe<AssetOrderBuddy>
    kioskSlideDuration?: Maybe<Scalars['Float']['output']>
}

export enum LanguageOrderBuddy {
    ARABIC = 'ARABIC',
    CHINESE = 'CHINESE',
    DUTCH = 'DUTCH',
    ENGLISH = 'ENGLISH',
    FRENCH = 'FRENCH',
    GERMAN = 'GERMAN',
    ITALIAN = 'ITALIAN',
    SPANISH = 'SPANISH'
}

export interface LatLongOrderBuddy {
    __typename?: 'LatLong'
    latitude?: Maybe<Scalars['String']['output']>
    longitude?: Maybe<Scalars['String']['output']>
}

export interface LoginInputOrderBuddy {
    email?: InputMaybe<Scalars['String']['input']>
    password?: InputMaybe<Scalars['String']['input']>
}

export interface LoginResponseOrderBuddy {
    __typename?: 'LoginResponse'
    token: Scalars['String']['output']
    user: UserOrderBuddy
}

export interface LogoutResponseOrderBuddy {
    __typename?: 'LogoutResponse'
    message: Scalars['String']['output']
    status: LogoutStatusOrderBuddy
}

export enum LogoutStatusOrderBuddy {
    /** TOKEN_REVOKED */
    TOKEN_REVOKED = 'TOKEN_REVOKED'
}

export enum MailingTypeOrderBuddy {
    EMAIL = 'EMAIL',
    PUSH = 'PUSH',
    REVIEW = 'REVIEW',
    SMS = 'SMS'
}

export interface MenuOrderBuddy {
    __typename?: 'Menu'
    categories?: Maybe<Array<CategoryOrderBuddy>>
    dispatchType?: Maybe<DispatchTypeOrderBuddy>
    menuType?: Maybe<MenuTypeOrderBuddy>
}

export enum MenuTypeOrderBuddy {
    DELIVERY = 'DELIVERY',
    PICKUP = 'PICKUP',
    RESTAURANT = 'RESTAURANT'
}

export interface MobileAppsOrderBuddy {
    __typename?: 'MobileApps'
    android?: Maybe<Scalars['String']['output']>
    ios?: Maybe<Scalars['String']['output']>
}

export interface MutationOrderBuddy {
    __typename?: 'Mutation'
    createOrder?: Maybe<OrderCreatedOrderBuddy>
    forgotPassword: ForgotPasswordResponseOrderBuddy
    login: LoginResponseOrderBuddy
    logout: LogoutResponseOrderBuddy
    register: RegisterResponseOrderBuddy
    resendEmailVerification: ResendEmailVerificationResponseOrderBuddy
    resetPassword: ResetPasswordResponseOrderBuddy
    updateMe?: Maybe<UserOrderBuddy>
}

export interface MutationcreateOrderOrderBuddyArgs {
    input: OrderCheckoutInputOrderBuddy
}

export interface MutationforgotPasswordOrderBuddyArgs {
    input: ForgotPasswordInputOrderBuddy
}

export interface MutationloginOrderBuddyArgs {
    input: LoginInputOrderBuddy
}

export interface MutationregisterOrderBuddyArgs {
    input?: InputMaybe<RegisterInputOrderBuddy>
}

export interface MutationresendEmailVerificationOrderBuddyArgs {
    input: ResendEmailVerificationInputOrderBuddy
}

export interface MutationresetPasswordOrderBuddyArgs {
    input: ResetPasswordInputOrderBuddy
}

export interface MutationupdateMeOrderBuddyArgs {
    input: UserInputOrderBuddy
}

export interface OpeningHoursOrderBuddy {
    __typename?: 'OpeningHours'
    end?: Maybe<Scalars['String']['output']>
    start?: Maybe<Scalars['String']['output']>
}

export interface OrderOrderBuddy {
    __typename?: 'Order'
    address2?: Maybe<Scalars['String']['output']>
    address3?: Maybe<Scalars['String']['output']>
    billingAddress2?: Maybe<Scalars['String']['output']>
    billingAddress3?: Maybe<Scalars['String']['output']>
    billingCity?: Maybe<Scalars['String']['output']>
    billingCompany?: Maybe<Scalars['String']['output']>
    billingCountry?: Maybe<Scalars['String']['output']>
    billingEmail?: Maybe<Scalars['String']['output']>
    billingName?: Maybe<Scalars['String']['output']>
    billingPhone?: Maybe<Scalars['String']['output']>
    billingPostalCode?: Maybe<Scalars['String']['output']>
    billingStreet?: Maybe<Scalars['String']['output']>
    billingStreetNumber?: Maybe<Scalars['String']['output']>
    city?: Maybe<Scalars['String']['output']>
    client?: Maybe<ClientOrderBuddy>
    company?: Maybe<Scalars['String']['output']>
    country?: Maybe<Scalars['String']['output']>
    customer?: Maybe<CustomerOrderBuddy>
    deliveredAt?: Maybe<Scalars['DateTime']['output']>
    deliverer?: Maybe<DelivererOrderBuddy>
    deliveryCosts?: Maybe<Scalars['Float']['output']>
    discountCode?: Maybe<Scalars['String']['output']>
    dispatchType?: Maybe<DispatchTypeOrderBuddy>
    email?: Maybe<Scalars['String']['output']>
    expectedDeliveryAt?: Maybe<Scalars['DateTime']['output']>
    firstName?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    lastName?: Maybe<Scalars['String']['output']>
    number?: Maybe<Scalars['Int']['output']>
    paidAt?: Maybe<Scalars['DateTime']['output']>
    paymentCosts?: Maybe<Scalars['Float']['output']>
    paymentMethod?: Maybe<Scalars['String']['output']>
    paymentOption?: Maybe<Scalars['String']['output']>
    paymentStatus?: Maybe<PaymentStatusOrderBuddy>
    phone?: Maybe<Scalars['String']['output']>
    pointsEarned?: Maybe<Scalars['Int']['output']>
    pointsPaid?: Maybe<Scalars['Int']['output']>
    postalCode?: Maybe<Scalars['String']['output']>
    products?: Maybe<Array<Maybe<OrderProductOrderBuddy>>>
    reason?: Maybe<Scalars['String']['output']>
    remarks?: Maybe<Scalars['String']['output']>
    requestedDeliveryAt?: Maybe<Scalars['DateTime']['output']>
    secondaryPaymentAmount?: Maybe<Scalars['Float']['output']>
    secondaryPaymentMethod?: Maybe<Scalars['String']['output']>
    setAt?: Maybe<Scalars['String']['output']>
    status?: Maybe<OrderStatusOrderBuddy>
    street?: Maybe<Scalars['String']['output']>
    streetNumber?: Maybe<Scalars['String']['output']>
    subtotal?: Maybe<Scalars['Float']['output']>
    tip?: Maybe<Scalars['Float']['output']>
    total?: Maybe<Scalars['Float']['output']>
}

export interface OrderAheadOrderBuddy {
    __typename?: 'OrderAhead'
    orderAheadActive?: Maybe<Scalars['Boolean']['output']>
    orderAheadMaximumWeeks?: Maybe<Scalars['Int']['output']>
    orderAheadMinimumDaysAfterHour?: Maybe<Scalars['String']['output']>
    orderAheadOnFridayStartingFrom?: Maybe<WeekDayOrderBuddy>
    orderAheadOnMondayStartingFrom?: Maybe<WeekDayOrderBuddy>
    orderAheadOnSaturdayStartingFrom?: Maybe<WeekDayOrderBuddy>
    orderAheadOnSundayStartingFrom?: Maybe<WeekDayOrderBuddy>
    orderAheadOnThursdayStartingFrom?: Maybe<WeekDayOrderBuddy>
    orderAheadOnTuesdayStartingFrom?: Maybe<WeekDayOrderBuddy>
    orderAheadOnWednesdayStartingFrom?: Maybe<WeekDayOrderBuddy>
    orderAheadToday?: Maybe<Scalars['Boolean']['output']>
}

export interface OrderBelongsToOrderBuddy {
    connect?: InputMaybe<Scalars['ID']['input']>
    create?: InputMaybe<OrderInputOrderBuddy>
    update?: InputMaybe<OrderInputOrderBuddy>
    upsert?: InputMaybe<OrderInputOrderBuddy>
}

/** Allows ordering a list of records. */
export interface OrderByClauseOrderBuddy {
    /** The column that is used for ordering. */
    column: Scalars['String']['input']
    /** The direction that is used for ordering. */
    order: SortOrderOrderBuddy
}

export interface OrderByReferenceInputOrderBuddy {
    reference?: InputMaybe<Scalars['String']['input']>
}

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunctionOrderBuddy {
    /** Amount of items. */
    COUNT = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunctionOrderBuddy {
    /** Average. */
    AVG = 'AVG',
    /** Amount of items. */
    COUNT = 'COUNT',
    /** Maximum. */
    MAX = 'MAX',
    /** Minimum. */
    MIN = 'MIN',
    /** Sum. */
    SUM = 'SUM'
}

export interface OrderCartFreeProductInputOrderBuddy {
    id?: InputMaybe<Scalars['Int']['input']>
}

export interface OrderCartInputOrderBuddy {
    freeProducts?: InputMaybe<Array<OrderCartFreeProductInputOrderBuddy>>
    products: Array<OrderCartProductInputOrderBuddy>
}

export interface OrderCartProductInputOrderBuddy {
    discountId?: InputMaybe<Scalars['Int']['input']>
    id?: InputMaybe<Scalars['Int']['input']>
    items?: InputMaybe<Array<InputMaybe<OrderCartProductItemInputOrderBuddy>>>
    quantity?: InputMaybe<Scalars['Int']['input']>
    remarks?: InputMaybe<Scalars['String']['input']>
}

export interface OrderCartProductItemInputOrderBuddy {
    extraId?: InputMaybe<Scalars['Int']['input']>
    id?: InputMaybe<Scalars['Int']['input']>
    productId?: InputMaybe<Scalars['Int']['input']>
    quantity?: InputMaybe<Scalars['Int']['input']>
}

export interface OrderCheckoutInputOrderBuddy {
    billingContact?: InputMaybe<BillingContactInputOrderBuddy>
    cart: OrderCartInputOrderBuddy
    comment?: InputMaybe<Scalars['String']['input']>
    customer: CustomerInputOrderBuddy
    deliveryAtDate?: InputMaybe<Scalars['Date']['input']>
    deliveryAtTime?: InputMaybe<Scalars['String']['input']>
    discounts?: InputMaybe<Array<InputMaybe<OrderDiscountInputOrderBuddy>>>
    dispatchType: DispatchTypeOrderBuddy
    payment?: InputMaybe<OrderPaymentInputOrderBuddy>
    returnUrl: Scalars['String']['input']
    tip?: InputMaybe<Scalars['Float']['input']>
}

export interface OrderCreatedOrderBuddy {
    __typename?: 'OrderCreated'
    redirect?: Maybe<Scalars['String']['output']>
    reference?: Maybe<Scalars['String']['output']>
    status: Scalars['String']['output']
}

export interface OrderDiscountInputOrderBuddy {
    amount?: InputMaybe<Scalars['Float']['input']>
    code?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['Int']['input']>
    type?: InputMaybe<Scalars['String']['input']>
}

export interface OrderFilterOrderBuddy {
    clientId?: InputMaybe<Scalars['Int']['input']>
    customerId?: InputMaybe<Scalars['Int']['input']>
}

export interface OrderInputOrderBuddy {
    client?: InputMaybe<ClientBelongsToOrderBuddy>
    customer?: InputMaybe<CustomerBelongsToOrderBuddy>
    deliverer?: InputMaybe<DelivererBelongsToOrderBuddy>
    dispatchType?: InputMaybe<DispatchTypeOrderBuddy>
    id?: InputMaybe<Scalars['ID']['input']>
}

export enum OrderInternalSourceOrderBuddy {
    APP = 'APP',
    COUNTER = 'COUNTER',
    KIOSK = 'KIOSK',
    ONLINE = 'ONLINE',
    QR = 'QR',
    QR_TABLE = 'QR_TABLE'
}

/** A paginated list of Order items. */
export interface OrderPaginatorOrderBuddy {
    __typename?: 'OrderPaginator'
    /** A list of Order items. */
    data: Array<OrderOrderBuddy>
    /** Pagination information about the list of items. */
    paginatorInfo: PaginatorInfoOrderBuddy
}

export interface OrderPaymentInputOrderBuddy {
    cashAmount?: InputMaybe<Scalars['Float']['input']>
    financialInstitution?: InputMaybe<Scalars['String']['input']>
    method: PaymentMethodOrderBuddy
}

export interface OrderPaymentStatusInputOrderBuddy {
    redirectResult?: InputMaybe<Scalars['String']['input']>
    reference?: InputMaybe<Scalars['String']['input']>
}

export interface OrderProductOrderBuddy {
    __typename?: 'OrderProduct'
    client?: Maybe<ClientOrderBuddy>
    description?: Maybe<Scalars['String']['output']>
    discount?: Maybe<Scalars['Float']['output']>
    extras?: Maybe<Array<Maybe<OrderProductExtraOrderBuddy>>>
    id: Scalars['ID']['output']
    isDeleted?: Maybe<Scalars['Boolean']['output']>
    order?: Maybe<OrderOrderBuddy>
    price: Scalars['Float']['output']
    product?: Maybe<ProductOrderBuddy>
    quantity?: Maybe<Scalars['Int']['output']>
    remarks?: Maybe<Scalars['String']['output']>
    total?: Maybe<Scalars['Float']['output']>
    vat?: Maybe<Scalars['Int']['output']>
    vatDone?: Maybe<Scalars['Boolean']['output']>
    vatTotal?: Maybe<Scalars['Float']['output']>
}

export interface OrderProductExtraOrderBuddy {
    __typename?: 'OrderProductExtra'
    item?: Maybe<ItemOrderBuddy>
    product?: Maybe<ProductOrderBuddy>
}

export enum OrderSourceOrderBuddy {
    APP = 'APP',
    BESTEL_THUIS = 'BESTEL_THUIS',
    CASH_CORRECTION = 'CASH_CORRECTION',
    COUNTER = 'COUNTER',
    DELIVEROO = 'DELIVEROO',
    DT = 'DT',
    FACEBOOK = 'FACEBOOK',
    HEERLIJK_BEZORGEN = 'HEERLIJK_BEZORGEN',
    HUNGRY = 'HUNGRY',
    JUSTEAT = 'JUSTEAT',
    KIOSK = 'KIOSK',
    LIEFERANDO = 'LIEFERANDO',
    LIEFERSERVICE = 'LIEFERSERVICE',
    LIEFER_HELD = 'LIEFER_HELD',
    ONLINE = 'ONLINE',
    PIZZA = 'PIZZA',
    QR = 'QR',
    QR_TABLE = 'QR_TABLE',
    SNELETEN = 'SNELETEN',
    TANTEBEP = 'TANTEBEP',
    THUISBEZORGD = 'THUISBEZORGD',
    THUISHALALETEN = 'THUISHALALETEN',
    UBER_EATS = 'UBER_EATS'
}

export enum OrderStatusOrderBuddy {
    /** Order has been cancelled */
    CANCELLED = 'CANCELLED',
    /** Order has been delivered */
    DELIVERED = 'DELIVERED',
    /** Order is being delivered */
    ENROUTE = 'ENROUTE',
    /** New order, not yet accepted */
    INIT = 'INIT',
    /**
     * Legacy status, don't use
     *
     * @deprecated
     */
    NEW = 'NEW',
    /**
     * Legacy status, don't use
     *
     * @deprecated
     */
    PAID = 'PAID',
    /** Accepted order, currently in the kitchen */
    PENDING = 'PENDING',
    /** Order is ready in the kitchen, ready to be picked up by customer or deliverer */
    PENDING_READY = 'PENDING_READY'
}

export interface OrderingDatesOrderBuddy {
    __typename?: 'OrderingDates'
    disallowedDates?: Maybe<Array<Maybe<Scalars['Date']['output']>>>
    endDate?: Maybe<Scalars['Date']['output']>
    startDate?: Maybe<Scalars['Date']['output']>
}

export interface OrderingTimesOrderBuddy {
    __typename?: 'OrderingTimes'
    times?: Maybe<Array<Maybe<Scalars['Time']['output']>>>
}

/** Information about pagination using a fully featured paginator. */
export interface PaginatorInfoOrderBuddy {
    __typename?: 'PaginatorInfo'
    /** Number of items in the current page. */
    count: Scalars['Int']['output']
    /** Index of the current page. */
    currentPage: Scalars['Int']['output']
    /** Index of the first item in the current page. */
    firstItem?: Maybe<Scalars['Int']['output']>
    /** Are there more pages after this one? */
    hasMorePages: Scalars['Boolean']['output']
    /** Index of the last item in the current page. */
    lastItem?: Maybe<Scalars['Int']['output']>
    /** Index of the last available page. */
    lastPage: Scalars['Int']['output']
    /** Number of items per page. */
    perPage: Scalars['Int']['output']
    /** Number of total available items. */
    total: Scalars['Int']['output']
}

export interface PaymentCostsOrderBuddy {
    __typename?: 'PaymentCosts'
    ideal?: Maybe<Scalars['Float']['output']>
}

export enum PaymentMethodOrderBuddy {
    ACCOUNT = 'ACCOUNT',
    BANK_TRANSFER = 'BANK_TRANSFER',
    CASH = 'CASH',
    CREDIT_CARD = 'CREDIT_CARD',
    DELIVEROO = 'DELIVEROO',
    EPS = 'EPS',
    GIFTCARD = 'GIFTCARD',
    IDEAL = 'IDEAL',
    MISTERCASH = 'MISTERCASH',
    MISTERCASH_MOBILE_APP = 'MISTERCASH_MOBILE_APP',
    MISTERCASH_MOBILE_QR = 'MISTERCASH_MOBILE_QR',
    PAYPAL = 'PAYPAL',
    PIN = 'PIN',
    SEPADIRECT_DEBIT = 'SEPADIRECT_DEBIT',
    THUISBEZORGD = 'THUISBEZORGD',
    TWINT = 'TWINT',
    UBER_EATS = 'UBER_EATS'
}

export enum PaymentResultCodeOrderBuddy {
    AUTHENTICATION_FINISHED = 'AUTHENTICATION_FINISHED',
    AUTHENTICATION_NOT_REQUIRED = 'AUTHENTICATION_NOT_REQUIRED',
    AUTHORISED = 'AUTHORISED',
    CANCELLED = 'CANCELLED',
    CHALLENGE_SHOPPER = 'CHALLENGE_SHOPPER',
    ERROR = 'ERROR',
    IDENTIFY_SHOPPER = 'IDENTIFY_SHOPPER',
    PARTIALLY_AUTHORISED = 'PARTIALLY_AUTHORISED',
    PENDING = 'PENDING',
    PRESENT_TO_SHOPPER = 'PRESENT_TO_SHOPPER',
    RECEIVED = 'RECEIVED',
    REDIRECT_SHOPPER = 'REDIRECT_SHOPPER',
    REFUSED = 'REFUSED'
}

export enum PaymentStatusOrderBuddy {
    /** @deprecated Legacy status, don't use */
    DELIVERED = 'DELIVERED',
    INIT = 'INIT',
    PAID = 'PAID',
    PENDING = 'PENDING',
    REFUNDED = 'REFUNDED'
}

export enum PhoneTypeOrderBuddy {
    FIXED = 'FIXED',
    MOBILE = 'MOBILE',
    UNKNOWN = 'UNKNOWN'
}

export interface ProductOrderBuddy {
    __typename?: 'Product'
    allergies?: Maybe<Array<Maybe<AllergenOrderBuddy>>>
    available?: Maybe<ProductAvailabilityOrderBuddy>
    availableFor?: Maybe<DispatchTypeOrderBuddy>
    category?: Maybe<CategoryOrderBuddy>
    days?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    defExtras?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    deposit?: Maybe<Scalars['Float']['output']>
    description?: Maybe<Scalars['String']['output']>
    descriptionExtras?: Maybe<Scalars['String']['output']>
    ean?: Maybe<Scalars['String']['output']>
    externalOpt?: Maybe<Scalars['String']['output']>
    extras?: Maybe<Array<ExtraOrderBuddy>>
    hasAlcohol?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['Int']['output']
    image?: Maybe<AssetOrderBuddy>
    isBio?: Maybe<Scalars['Boolean']['output']>
    isHalal?: Maybe<Scalars['Boolean']['output']>
    isVegan?: Maybe<Scalars['Boolean']['output']>
    maximumOrderQuantity?: Maybe<Scalars['Int']['output']>
    menuType?: Maybe<MenuTypeOrderBuddy>
    minimumOrderQuantity?: Maybe<Scalars['Int']['output']>
    originalPrice?: Maybe<Scalars['Float']['output']>
    packagingCosts?: Maybe<Scalars['Float']['output']>
    preparationTime?: Maybe<Scalars['Int']['output']>
    price: Scalars['Float']['output']
    priority?: Maybe<Scalars['Int']['output']>
    showExtrasCat?: Maybe<ShowExtrasCategoryOrderBuddy>
    showType?: Maybe<Scalars['String']['output']>
    spiceLevel?: Maybe<Scalars['Int']['output']>
    stock?: Maybe<Scalars['Int']['output']>
    stockAutoOff?: Maybe<Scalars['Boolean']['output']>
    title?: Maybe<Scalars['String']['output']>
    titleExtra?: Maybe<Scalars['String']['output']>
    vat?: Maybe<Scalars['Int']['output']>
    vatAlt?: Maybe<Scalars['Int']['output']>
}

export interface ProductextrasOrderBuddyArgs {
    orderBy?: InputMaybe<Array<ProductExtrasOrderByOrderByClauseOrderBuddy>>
}

export enum ProductAvailabilityOrderBuddy {
    AVAILABLE = 'AVAILABLE',
    NO_SHOW = 'NO_SHOW',
    UNAVAILABLE = 'UNAVAILABLE'
}

/** Order by clause for Product.extras.orderBy. */
export interface ProductExtrasOrderByOrderByClauseOrderBuddy {
    /** The column that is used for ordering. */
    column: ExtrasColumnOrderBuddy
    /** The direction that is used for ordering. */
    order: SortOrderOrderBuddy
}

export interface ProductFilterOrderBuddy {
    menuType?: InputMaybe<MenuTypeOrderBuddy>
    title?: InputMaybe<Scalars['String']['input']>
}

export enum ProductsColumnOrderBuddy {
    ID = 'ID',
    PRIORITY = 'PRIORITY',
    TITLE = 'TITLE'
}

export interface QueryOrderBuddy {
    __typename?: 'Query'
    addressAutoComplete?: Maybe<addressAutoCompleteResultOrderBuddy>
    checkDeliveryArea?: Maybe<checkDeliveryAreaResultOrderBuddy>
    clientOpen: ClientOpenPaginatorOrderBuddy
    countries: Array<CountryOrderBuddy>
    country?: Maybe<CountryOrderBuddy>
    customer?: Maybe<CustomerOrderBuddy>
    customerReview?: Maybe<CustomerReviewOrderBuddy>
    customerReviews: CustomerReviewPaginatorOrderBuddy
    customers: CustomerPaginatorOrderBuddy
    deliverer?: Maybe<DelivererOrderBuddy>
    deliverers: DelivererPaginatorOrderBuddy
    deliveryAreas: Array<DeliveryAreaOrderBuddy>
    discountProduct: Array<DiscountProductOrderBuddy>
    discountProductProduct: Array<DiscountProductProductOrderBuddy>
    extras?: Maybe<Array<Maybe<ExtraOrderBuddy>>>
    financialInstitution?: Maybe<FinancialInstitutionOrderBuddy>
    financialInstitutions: FinancialInstitutionPaginatorOrderBuddy
    getCustomerReviewStatistics?: Maybe<CustomerReviewStatisticsOrderBuddy>
    me?: Maybe<UserOrderBuddy>
    menu?: Maybe<MenuOrderBuddy>
    order: OrderOrderBuddy
    orderByReference?: Maybe<OrderOrderBuddy>
    orderPaymentStatus?: Maybe<PaymentResultCodeOrderBuddy>
    orderingDates?: Maybe<OrderingDatesOrderBuddy>
    orderingTimes?: Maybe<OrderingTimesOrderBuddy>
    orders: OrderPaginatorOrderBuddy
    products?: Maybe<Array<Maybe<ProductOrderBuddy>>>
    reseller?: Maybe<ResellerOrderBuddy>
    resellers: ResellerPaginatorOrderBuddy
    webshopSettings: WebshopSettingsOrderBuddy
}

export interface QueryaddressAutoCompleteOrderBuddyArgs {
    postalCode: Scalars['String']['input']
    streetNumber: Scalars['String']['input']
}

export interface QuerycheckDeliveryAreaOrderBuddyArgs {
    postalCode: Scalars['String']['input']
    streetNumber?: InputMaybe<Scalars['String']['input']>
}

export interface QueryclientOpenOrderBuddyArgs {
    filter?: InputMaybe<ClientOpenFilterOrderBuddy>
    first?: Scalars['Int']['input']
    orderBy?: InputMaybe<Array<QueryClientOpenOrderByOrderByClauseOrderBuddy>>
    page?: InputMaybe<Scalars['Int']['input']>
}

export interface QuerycountriesOrderBuddyArgs {
    filter?: InputMaybe<CountryFilterOrderBuddy>
    orderBy?: InputMaybe<Array<QueryCountriesOrderByOrderByClauseOrderBuddy>>
}

export interface QuerycountryOrderBuddyArgs {
    id?: InputMaybe<Scalars['ID']['input']>
}

export interface QuerycustomerOrderBuddyArgs {
    id?: InputMaybe<Scalars['ID']['input']>
}

export interface QuerycustomerReviewOrderBuddyArgs {
    id?: InputMaybe<Scalars['ID']['input']>
}

export interface QuerycustomerReviewsOrderBuddyArgs {
    first?: Scalars['Int']['input']
    orderBy?: InputMaybe<Array<QueryCustomerReviewsOrderByOrderByClauseOrderBuddy>>
    page?: InputMaybe<Scalars['Int']['input']>
}

export interface QuerycustomersOrderBuddyArgs {
    filter?: InputMaybe<CustomerFilterOrderBuddy>
    first?: Scalars['Int']['input']
    orderBy?: InputMaybe<Array<QueryCustomersOrderByOrderByClauseOrderBuddy>>
    page?: InputMaybe<Scalars['Int']['input']>
}

export interface QuerydelivererOrderBuddyArgs {
    id?: InputMaybe<Scalars['ID']['input']>
}

export interface QuerydeliverersOrderBuddyArgs {
    filter?: InputMaybe<DelivererFilterOrderBuddy>
    first?: Scalars['Int']['input']
    orderBy?: InputMaybe<Array<QueryDeliverersOrderByOrderByClauseOrderBuddy>>
    page?: InputMaybe<Scalars['Int']['input']>
}

export interface QuerydeliveryAreasOrderBuddyArgs {
    orderBy?: InputMaybe<Array<QueryDeliveryAreasOrderByOrderByClauseOrderBuddy>>
}

export interface QuerydiscountProductOrderBuddyArgs {
    orderBy?: InputMaybe<Array<QueryDiscountProductOrderByOrderByClauseOrderBuddy>>
}

export interface QuerydiscountProductProductOrderBuddyArgs {
    orderBy?: InputMaybe<Array<QueryDiscountProductProductOrderByOrderByClauseOrderBuddy>>
}

export interface QueryfinancialInstitutionOrderBuddyArgs {
    id?: InputMaybe<Scalars['ID']['input']>
}

export interface QueryfinancialInstitutionsOrderBuddyArgs {
    filter?: InputMaybe<financialInstitutionFilterOrderBuddy>
    first?: Scalars['Int']['input']
    orderBy?: InputMaybe<Array<QueryFinancialInstitutionsOrderByOrderByClauseOrderBuddy>>
    page?: InputMaybe<Scalars['Int']['input']>
}

export interface QuerymenuOrderBuddyArgs {
    dispatchType?: InputMaybe<DispatchTypeOrderBuddy>
}

export interface QueryorderOrderBuddyArgs {
    id?: InputMaybe<Scalars['ID']['input']>
}

export interface QueryorderByReferenceOrderBuddyArgs {
    input: OrderByReferenceInputOrderBuddy
}

export interface QueryorderPaymentStatusOrderBuddyArgs {
    input: OrderPaymentStatusInputOrderBuddy
}

export interface QueryorderingDatesOrderBuddyArgs {
    dispatchType: DispatchTypeOrderBuddy
}

export interface QueryorderingTimesOrderBuddyArgs {
    date?: InputMaybe<Scalars['Date']['input']>
    dispatchType: DispatchTypeOrderBuddy
}

export interface QueryordersOrderBuddyArgs {
    filter?: InputMaybe<OrderFilterOrderBuddy>
    first?: Scalars['Int']['input']
    orderBy?: InputMaybe<Array<QueryOrdersOrderByOrderByClauseOrderBuddy>>
    page?: InputMaybe<Scalars['Int']['input']>
}

export interface QueryresellerOrderBuddyArgs {
    id?: InputMaybe<Scalars['ID']['input']>
}

export interface QueryresellersOrderBuddyArgs {
    filter?: InputMaybe<ResellerFilterOrderBuddy>
    first?: Scalars['Int']['input']
    orderBy?: InputMaybe<Array<QueryResellersOrderByOrderByClauseOrderBuddy>>
    page?: InputMaybe<Scalars['Int']['input']>
}

export interface QueryCategoriesOrderByOrderByClauseOrderBuddy {
    column: CategoriesColumnOrderBuddy
    order: SortOrderOrderBuddy
}

/** Allowed column names for Query.clientOpen.orderBy. */
export enum QueryClientOpenOrderByColumnOrderBuddy {
    ID = 'ID'
}

/** Order by clause for Query.clientOpen.orderBy. */
export interface QueryClientOpenOrderByOrderByClauseOrderBuddy {
    /** The column that is used for ordering. */
    column: QueryClientOpenOrderByColumnOrderBuddy
    /** The direction that is used for ordering. */
    order: SortOrderOrderBuddy
}

/** Allowed column names for Query.countries.orderBy. */
export enum QueryCountriesOrderByColumnOrderBuddy {
    NAME = 'NAME'
}

/** Order by clause for Query.countries.orderBy. */
export interface QueryCountriesOrderByOrderByClauseOrderBuddy {
    /** The column that is used for ordering. */
    column: QueryCountriesOrderByColumnOrderBuddy
    /** The direction that is used for ordering. */
    order: SortOrderOrderBuddy
}

/** Order by clause for Query.customerReviews.orderBy. */
export interface QueryCustomerReviewsOrderByOrderByClauseOrderBuddy {
    /** The column that is used for ordering. */
    column: CustomerReviewColumnOrderBuddy
    /** The direction that is used for ordering. */
    order: SortOrderOrderBuddy
}

/** Allowed column names for Query.customers.orderBy. */
export enum QueryCustomersOrderByColumnOrderBuddy {
    ID = 'ID'
}

/** Order by clause for Query.customers.orderBy. */
export interface QueryCustomersOrderByOrderByClauseOrderBuddy {
    /** The column that is used for ordering. */
    column: QueryCustomersOrderByColumnOrderBuddy
    /** The direction that is used for ordering. */
    order: SortOrderOrderBuddy
}

/** Allowed column names for Query.deliverers.orderBy. */
export enum QueryDeliverersOrderByColumnOrderBuddy {
    ID = 'ID'
}

/** Order by clause for Query.deliverers.orderBy. */
export interface QueryDeliverersOrderByOrderByClauseOrderBuddy {
    /** The column that is used for ordering. */
    column: QueryDeliverersOrderByColumnOrderBuddy
    /** The direction that is used for ordering. */
    order: SortOrderOrderBuddy
}

/** Allowed column names for Query.deliveryAreas.orderBy. */
export enum QueryDeliveryAreasOrderByColumnOrderBuddy {
    ID = 'ID'
}

/** Order by clause for Query.deliveryAreas.orderBy. */
export interface QueryDeliveryAreasOrderByOrderByClauseOrderBuddy {
    /** The column that is used for ordering. */
    column: QueryDeliveryAreasOrderByColumnOrderBuddy
    /** The direction that is used for ordering. */
    order: SortOrderOrderBuddy
}

/** Allowed column names for Query.discountProduct.orderBy. */
export enum QueryDiscountProductOrderByColumnOrderBuddy {
    ID = 'ID'
}

/** Order by clause for Query.discountProduct.orderBy. */
export interface QueryDiscountProductOrderByOrderByClauseOrderBuddy {
    /** The column that is used for ordering. */
    column: QueryDiscountProductOrderByColumnOrderBuddy
    /** The direction that is used for ordering. */
    order: SortOrderOrderBuddy
}

/** Allowed column names for Query.discountProductProduct.orderBy. */
export enum QueryDiscountProductProductOrderByColumnOrderBuddy {
    ID = 'ID'
}

/** Order by clause for Query.discountProductProduct.orderBy. */
export interface QueryDiscountProductProductOrderByOrderByClauseOrderBuddy {
    /** The column that is used for ordering. */
    column: QueryDiscountProductProductOrderByColumnOrderBuddy
    /** The direction that is used for ordering. */
    order: SortOrderOrderBuddy
}

export interface QueryExtrasOrderByOrderByClauseOrderBuddy {
    column: ExtrasColumnOrderBuddy
    order: SortOrderOrderBuddy
}

/** Allowed column names for Query.financialInstitutions.orderBy. */
export enum QueryFinancialInstitutionsOrderByColumnOrderBuddy {
    ID = 'ID'
}

/** Order by clause for Query.financialInstitutions.orderBy. */
export interface QueryFinancialInstitutionsOrderByOrderByClauseOrderBuddy {
    /** The column that is used for ordering. */
    column: QueryFinancialInstitutionsOrderByColumnOrderBuddy
    /** The direction that is used for ordering. */
    order: SortOrderOrderBuddy
}

export interface QueryItemsOrderByOrderByClauseOrderBuddy {
    column: ItemsColumnOrderBuddy
    order: SortOrderOrderBuddy
}

/** Allowed column names for Query.orders.orderBy. */
export enum QueryOrdersOrderByColumnOrderBuddy {
    ID = 'ID'
}

/** Order by clause for Query.orders.orderBy. */
export interface QueryOrdersOrderByOrderByClauseOrderBuddy {
    /** The column that is used for ordering. */
    column: QueryOrdersOrderByColumnOrderBuddy
    /** The direction that is used for ordering. */
    order: SortOrderOrderBuddy
}

export interface QueryProductsOrderByOrderByClauseOrderBuddy {
    column: ProductsColumnOrderBuddy
    order: SortOrderOrderBuddy
}

/** Allowed column names for Query.resellers.orderBy. */
export enum QueryResellersOrderByColumnOrderBuddy {
    ID = 'ID'
}

/** Order by clause for Query.resellers.orderBy. */
export interface QueryResellersOrderByOrderByClauseOrderBuddy {
    /** The column that is used for ordering. */
    column: QueryResellersOrderByColumnOrderBuddy
    /** The direction that is used for ordering. */
    order: SortOrderOrderBuddy
}

export interface RatingPercentageOrderBuddy {
    __typename?: 'RatingPercentage'
    count?: Maybe<Scalars['Int']['output']>
    percentage?: Maybe<Scalars['Int']['output']>
    rating?: Maybe<Scalars['Int']['output']>
}

export interface RegisterInputOrderBuddy {
    customer?: InputMaybe<CustomerBelongsToOrderBuddy>
    email?: InputMaybe<Scalars['String']['input']>
    hasAgreedToTerms?: InputMaybe<Scalars['Boolean']['input']>
    password?: InputMaybe<Scalars['String']['input']>
}

export interface RegisterResponseOrderBuddy {
    __typename?: 'RegisterResponse'
    status: RegisterStatusOrderBuddy
}

export enum RegisterStatusOrderBuddy {
    /** SUCCESS */
    SUCCESS = 'SUCCESS'
}

export interface ResellerOrderBuddy {
    __typename?: 'Reseller'
    address?: Maybe<Scalars['String']['output']>
    apiKey?: Maybe<Scalars['String']['output']>
    city?: Maybe<Scalars['String']['output']>
    company?: Maybe<Scalars['String']['output']>
    contact?: Maybe<Scalars['String']['output']>
    country?: Maybe<Scalars['String']['output']>
    dateCreate?: Maybe<Scalars['String']['output']>
    deleted?: Maybe<Scalars['Int']['output']>
    email?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    invAddress?: Maybe<Scalars['String']['output']>
    invBic?: Maybe<Scalars['String']['output']>
    invCity?: Maybe<Scalars['String']['output']>
    invCocnr?: Maybe<Scalars['String']['output']>
    invCompany?: Maybe<Scalars['String']['output']>
    invContact?: Maybe<Scalars['String']['output']>
    invCountry?: Maybe<Scalars['String']['output']>
    invEmail?: Maybe<Scalars['String']['output']>
    invIban?: Maybe<Scalars['String']['output']>
    invPostalCode?: Maybe<Scalars['String']['output']>
    invVatnr?: Maybe<Scalars['String']['output']>
    login?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Scalars['Int']['output']>
    password?: Maybe<Scalars['String']['output']>
    postalCode?: Maybe<Scalars['String']['output']>
    subdomain?: Maybe<Scalars['String']['output']>
    tel?: Maybe<Scalars['String']['output']>
    website?: Maybe<Scalars['String']['output']>
}

export interface ResellerBelongsToOrderBuddy {
    connect?: InputMaybe<Scalars['ID']['input']>
}

export interface ResellerFilterOrderBuddy {
    company?: InputMaybe<Scalars['String']['input']>
    zipcode?: InputMaybe<Scalars['String']['input']>
}

/** A paginated list of Reseller items. */
export interface ResellerPaginatorOrderBuddy {
    __typename?: 'ResellerPaginator'
    /** A list of Reseller items. */
    data: Array<ResellerOrderBuddy>
    /** Pagination information about the list of items. */
    paginatorInfo: PaginatorInfoOrderBuddy
}

export interface ResendEmailVerificationInputOrderBuddy {
    email?: InputMaybe<Scalars['String']['input']>
}

export interface ResendEmailVerificationResponseOrderBuddy {
    __typename?: 'ResendEmailVerificationResponse'
    status: ResendEmailVerificationStatusOrderBuddy
}

export enum ResendEmailVerificationStatusOrderBuddy {
    /** EMAIL_SENT */
    EMAIL_SENT = 'EMAIL_SENT'
}

export interface ResetPasswordInputOrderBuddy {
    email?: InputMaybe<Scalars['String']['input']>
    password?: InputMaybe<Scalars['String']['input']>
    token?: InputMaybe<Scalars['String']['input']>
}

export interface ResetPasswordResponseOrderBuddy {
    __typename?: 'ResetPasswordResponse'
    message?: Maybe<Scalars['String']['output']>
    status: ResetPasswordStatusOrderBuddy
}

export enum ResetPasswordStatusOrderBuddy {
    /** PASSWORD_RESET */
    PASSWORD_RESET = 'PASSWORD_RESET'
}

export enum ShowExtrasCategoryOrderBuddy {
    NO = 'NO',
    YES_HIGH_PRIORITY = 'YES_HIGH_PRIORITY',
    YES_LOW_PRIORITY = 'YES_LOW_PRIORITY'
}

export interface SocialMediaSettingsOrderBuddy {
    __typename?: 'SocialMediaSettings'
    facebook?: Maybe<FacebookSettingsOrderBuddy>
    instagram?: Maybe<InstagramSettingsOrderBuddy>
    twitter?: Maybe<TwitterSettingsOrderBuddy>
}

/** Directions for ordering a list of records. */
export enum SortOrderOrderBuddy {
    /** Sort records in ascending order. */
    ASC = 'ASC',
    /** Sort records in descending order. */
    DESC = 'DESC'
}

export interface TipSettingsOrderBuddy {
    __typename?: 'TipSettings'
    defaultPercentage?: Maybe<Scalars['Int']['output']>
    enabled?: Maybe<Scalars['Boolean']['output']>
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum TrashedOrderBuddy {
    /** Only return trashed results. */
    ONLY = 'ONLY',
    /** Return both trashed and non-trashed results. */
    WITH = 'WITH',
    /** Only return non-trashed results. */
    WITHOUT = 'WITHOUT'
}

export interface TwitterSettingsOrderBuddy {
    __typename?: 'TwitterSettings'
    url?: Maybe<Scalars['String']['output']>
}

export interface UserOrderBuddy {
    __typename?: 'User'
    client: ClientOrderBuddy
    customer?: Maybe<CustomerOrderBuddy>
    delivererId?: Maybe<Scalars['ID']['output']>
    email: Scalars['String']['output']
    emailVerifiedAt?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    reseller?: Maybe<ResellerOrderBuddy>
    token?: Maybe<Scalars['String']['output']>
    uuid?: Maybe<Scalars['UUID']['output']>
}

export interface UserBelongsToOrderBuddy {
    connect?: InputMaybe<Scalars['ID']['input']>
}

export interface UserInputOrderBuddy {
    customer?: InputMaybe<CustomerBelongsToOrderBuddy>
    email?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    password?: InputMaybe<Scalars['String']['input']>
    uuid?: InputMaybe<Scalars['UUID']['input']>
}

export interface VerifyEmailInputOrderBuddy {
    email?: InputMaybe<Scalars['String']['input']>
    token?: InputMaybe<Scalars['String']['input']>
}

export interface VerifyEmailResponseOrderBuddy {
    __typename?: 'VerifyEmailResponse'
    message?: Maybe<Scalars['String']['output']>
    status: VerifyEmailStatusOrderBuddy
}

export enum VerifyEmailStatusOrderBuddy {
    /** EMAIL_VERIFIED */
    EMAIL_VERIFIED = 'EMAIL_VERIFIED'
}

export enum ViewTypeOrderBuddy {
    DEFAULT = 'DEFAULT',
    DROPDOWN = 'DROPDOWN',
    GRID = 'GRID'
}

export interface WebshopSettingsOrderBuddy {
    __typename?: 'WebshopSettings'
    additionalLanguages?: Maybe<Array<Scalars['String']['output']>>
    availableLanguages?: Maybe<Array<Scalars['String']['output']>>
    client?: Maybe<ClientOrderBuddy>
    contentBlocks?: Maybe<Array<ContentBlockOrderBuddy>>
    cssLogo?: Maybe<AssetOrderBuddy>
    defaultLanguage: Scalars['String']['output']
    delivery?: Maybe<DeliveryOrderBuddy>
    dispatchTypes: Array<DispatchTypeOrderBuddy>
    emergencyBrake?: Maybe<EmergencyBrakeOrderBuddy>
    gaEcommerce?: Maybe<Scalars['Boolean']['output']>
    gaProperty?: Maybe<Scalars['String']['output']>
    googleDirectionsMode?: Maybe<Scalars['String']['output']>
    googleTagManagerId?: Maybe<Scalars['String']['output']>
    hasLanguageSwitcher: Scalars['Boolean']['output']
    hero?: Maybe<HeroOrderBuddy>
    initialDeliveryTime?: Maybe<Scalars['Float']['output']>
    initialPickupTime?: Maybe<Scalars['Float']['output']>
    kiosk?: Maybe<KioskOrderBuddy>
    menus?: Maybe<Array<Scalars['String']['output']>>
    minimumDeliveryTime?: Maybe<Scalars['Float']['output']>
    minimumPickupTime?: Maybe<Scalars['Float']['output']>
    mobileApps?: Maybe<MobileAppsOrderBuddy>
    name?: Maybe<Scalars['String']['output']>
    openingHours: Array<Maybe<ClientOpenOrderBuddy>>
    orderAhead?: Maybe<OrderAheadOrderBuddy>
    paymentCashMaximum: Scalars['Float']['output']
    paymentCosts?: Maybe<PaymentCostsOrderBuddy>
    paymentMethods?: Maybe<Array<Maybe<PaymentMethodOrderBuddy>>>
    phoneNumber?: Maybe<Scalars['String']['output']>
    primaryColor: Scalars['String']['output']
    prizewheelActive?: Maybe<Scalars['Boolean']['output']>
    seoDescription?: Maybe<Scalars['String']['output']>
    seoTitle?: Maybe<Scalars['String']['output']>
    shouldShowStreetNumberForDelivery: Scalars['Boolean']['output']
    showDispatchTypeSelector?: Maybe<Scalars['Boolean']['output']>
    showProductRemarks?: Maybe<Scalars['Boolean']['output']>
    socialMedia?: Maybe<SocialMediaSettingsOrderBuddy>
    theme: Scalars['String']['output']
    tip?: Maybe<TipSettingsOrderBuddy>
    vatDefaultRate?: Maybe<Scalars['Int']['output']>
    webshopAgePopup: Scalars['Boolean']['output']
    webshopDeliveryTimeSteps?: Maybe<Scalars['String']['output']>
    webshopOptionsPlasticBag?: Maybe<Scalars['Boolean']['output']>
    webshopPickupTimeSteps?: Maybe<Scalars['String']['output']>
    webshopPlasticBag?: Maybe<Scalars['Float']['output']>
    webshopTimeSteps?: Maybe<Scalars['Boolean']['output']>
}

export enum WeekDayOrderBuddy {
    FRIDAY = 'FRIDAY',
    MONDAY = 'MONDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
    THURSDAY = 'THURSDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY'
}

export interface addressAutoCompleteResultOrderBuddy {
    __typename?: 'addressAutoCompleteResult'
    city?: Maybe<Scalars['String']['output']>
    coordinates?: Maybe<LatLongOrderBuddy>
    district?: Maybe<Scalars['String']['output']>
    municipality?: Maybe<Scalars['String']['output']>
    postalCode?: Maybe<Scalars['String']['output']>
    state?: Maybe<Scalars['String']['output']>
    street?: Maybe<Scalars['String']['output']>
    streetNumber?: Maybe<Scalars['Int']['output']>
}

export interface checkDeliveryAreaResultOrderBuddy {
    __typename?: 'checkDeliveryAreaResult'
    deliveryArea?: Maybe<DeliveryAreaOrderBuddy>
}

export interface financialInstitutionFilterOrderBuddy {
    issuerId?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
}

export interface kioskBuzzerTextOrderBuddy {
    __typename?: 'kioskBuzzerText'
    ar?: Maybe<Scalars['String']['output']>
    de?: Maybe<Scalars['String']['output']>
    default?: Maybe<Scalars['String']['output']>
    en?: Maybe<Scalars['String']['output']>
    fr?: Maybe<Scalars['String']['output']>
    it?: Maybe<Scalars['String']['output']>
    nl?: Maybe<Scalars['String']['output']>
}

export interface kioskInfoButtonTextOrderBuddy {
    __typename?: 'kioskInfoButtonText'
    de?: Maybe<Scalars['String']['output']>
    default?: Maybe<Scalars['String']['output']>
    en?: Maybe<Scalars['String']['output']>
    fr?: Maybe<Scalars['String']['output']>
    it?: Maybe<Scalars['String']['output']>
    nl?: Maybe<Scalars['String']['output']>
}
