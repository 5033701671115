import { shop } from '~/store/shop'
import { checkout } from '~/store/checkout'
import { DispatchTypeOrderBuddy } from '~/types/orderbuddy-types'

export default defineNuxtRouteMiddleware(async to => {
    if (to.fullPath.endsWith('/shop') || (to.fullPath.includes('/checkout') && checkout().input.dispatchType === DispatchTypeOrderBuddy.DELIVER)) {
        await shop().getDeliveryMenu()
    }
    if (to.fullPath.endsWith('/pickup') || (to.fullPath.includes('/checkout') && checkout().input.dispatchType === DispatchTypeOrderBuddy.PICK_UP)) {
        await shop().getPickupMenu()
    }
    return true
})
