import isEqual from 'lodash.isequal'
import cloneDeep from 'lodash.clonedeep'
import mapKeys from 'lodash.mapkeys'
import { SourceType } from 'orderbuddy-calculations/src/input/types/Input'
import { DiscountType } from 'orderbuddy-calculations/src/input/types/Discount'
import debounce from 'lodash.debounce'
import { eventTracker } from '~/utils/event-tracker'
import { cleanProduct, getCartProductId } from '~/utils/product-utils'
import { calculateQuery } from '~/graphql/queries/calculate.query.gql'
import { calculatorCall } from '~/composables/calculatorCall'
import { webshopSettings } from '~/store/webshopSettings'
import { shop } from '~/store/shop'
import {
    cleanCalculatorResponse,
    convertCartProductsForCalculator,
    convertGqlCategoriesForCalculator,
    convertGqlDispatchTypeForCalculator,
    convertGqlExtraItemsForCalculator,
    convertGqlExtrasForCalculator,
    convertGqlProductsForCalculator,
    convertGqlZipCodesForCalculator
} from '~/composables/calculatorConvertData'
import type { CalculatorLocalInputCart, CartProductsLocalWithPrices, CartState } from '~/types/local-types'
import { checkout } from '~/store/checkout'
import { type CalculateInputCalculatorService, PaymentMethodCalculatorService } from '~/types/calculator-service-types'
import { PaymentMethodOrderBuddy } from '~/types/orderbuddy-types'

export const cart = defineStore('cart', {
    state: (): CartState => <CartState>({
        cartIsLoading: false,
        cartItemsShouldAnimate: true,
        cart: {
            products: {},
            discounts: []
        },
        calculatorInputLastValidated: {},
        cartPricesLocal: {},
        cartPricesValidated: {},
        calculatorInputDatabase: {},
        devInfo: {
            localPayload: {},
            localLog: [],
            validatedPayload: {},
            validatedLog: []
        }
    } as CartState),
    persist: {
        storage: persistedState.localStorage
    },
    getters: {
        getCartProducts (): CartProductsLocalWithPrices {
            const currentCart = cloneDeep(this.cart.products)
            const targetCart: CartProductsLocalWithPrices = {}
            Object.keys(currentCart).forEach(key => {
                targetCart[key] = {
                    ...this.cart.products[key],
                    priceLocal: this.cartPricesLocal?.cart?.products[key]?.total,
                    priceVerified: this.cartPricesValidated?.cart?.products[key]?.total
                }
            })
            return targetCart
        },
        getCartQuantity (): number {
            return Object.values(this.getCartProducts).reduce((total: number, product: any) => {
                return total + product.quantity
            }, 0)
        },
        hasCartProducts (): boolean {
            return Object.keys(this.getCartProducts).length > 0
        },
        getCartTotalPriceLocal (): number {
            return this?.cartPricesLocal?.total || 0
        },
        getCartSubPriceLocal (): number {
            return this?.cartPricesLocal?.subtotal || 0
        },
        getTotalCartPriceValidated (): number {
            return this?.cartPricesValidated?.total || 0
        },
        getAdditionalCostsLocal (): any {
            return Object.fromEntries(
                Object.entries(this?.cartPricesLocal?.costs || {})
            )
        },
        getAdditionalCostsVerified (): any {
            return Object.fromEntries(
                Object.entries(this?.cartPricesValidated?.costs || {})
            )
        },
        hasAlchoholInCart (): boolean {
            return Object.values(this.cart.products).some((product: any) => shop().findProduct(product.id)!.hasAlcohol || false)
        },
        productAlreadyInCart () {
            return (product: any) => {
                const productWithoutQuantity: any = cleanProduct(cloneDeep(product))
                delete productWithoutQuantity.quantity
                return Object.values(this.cart.products).find((cartProduct: any) => {
                    const cartProductWithoutQuantity: any = cleanProduct(cloneDeep(cartProduct))
                    delete cartProductWithoutQuantity.quantity
                    return isEqual(cartProductWithoutQuantity, productWithoutQuantity) ? cleanProduct(cartProduct) : false
                })
            }
        },
        getTip (): number {
            // TODO: fix TS issue + make the calculation a helper function because the same calculation is now made in the tip component as well
            return checkout().input.tipData.tipRadio === 'custom' ? checkout().input.tipData.customTip : cart().getCartSubPriceLocal * (checkout().input.tipData.tipRadio / 100)
        }
    },
    actions: {
        addProduct (product: any) {
            if (!this.cartIsLoading) {
                const cartProductId = getCartProductId(product)
                if (this.productAlreadyInCart(product)) {
                    // TODO: make this aware of the maximum and minimum quantity of a product
                    this.cart.products[cartProductId].quantity = Number(this.cart.products[cartProductId].quantity) + Number(product.quantity)
                } else {
                    const { $event } = useNuxtApp()
                    $event('before-new-product-added-to-cart')
                    eventTracker('addProductFromCart', product)
                    this.cart.products[cartProductId] = cleanProduct(product)
                    this.updateLocalCartPrices()
                }
            }
        },
        updateProduct (product: any, index: number | string) {
            this.cartItemsShouldAnimate = false
            if (this.productAlreadyInCart(product)) {
                // TODO: when modifying a product, its position in the cart can change, this should be fixed
                this.deleteProduct(product, index)
                this.addProduct(product)
            } else {
                if (Object.prototype.hasOwnProperty.call(this.cart.products, index)) {
                    const newProductId = getCartProductId(product)
                    this.cart.products = mapKeys(this.cart.products, (_value, key) => {
                        if (key === index) {
                            return newProductId
                        }
                        return key
                    })
                    this.cart.products[newProductId] = cleanProduct(product)
                }
            }
            this.updateLocalCartPrices()
            setTimeout(() => {
                this.cartItemsShouldAnimate = true
            }, 300) // this should be the same as the transition duration in the cart
        },
        deleteProduct (product: object, index: number | string) {
            if (!this.cartIsLoading) {
                eventTracker('deleteProductFromCart', product)
                delete this.cart.products[index]
            }
        },
        setCalculatorInputDatabase () {
            this.calculatorInputDatabase = cloneDeep({
                is_loaded_from_order: false,
                language: 'NL',
                source: SourceType.ONLINE,
                cart: {
                    schedule: {
                        time: undefined,
                        date: '',
                        asap: false
                    },
                    packaging_costs_override: null,
                    free_products: []
                },
                client: {
                    id: Number.parseInt(webshopSettings()?.global?.client?.id || '0')
                },
                categories: convertGqlCategoriesForCalculator(shop().currentMenu),
                products: convertGqlProductsForCalculator(shop().allProducts),
                extras: convertGqlExtrasForCalculator(shop().allExtras),
                items: convertGqlExtraItemsForCalculator(shop().allExtrasItems),
                discounts: {
                    [DiscountType.SIMPLE]: [],
                    [DiscountType.DISCOUNTED_PRODUCT]: [],
                    [DiscountType.FREE_PRODUCT]: []
                },
                settings: [
                    {
                        setting: 'delivery_costs',
                        value: (webshopSettings()?.global?.delivery?.deliveryCosts || 0).toString()
                    },
                    {
                        setting: 'delivery_min',
                        value: (webshopSettings()?.global?.delivery?.minimumOrderThreshold || 0).toString()
                    },
                    {
                        setting: 'delivery_free',
                        value: (webshopSettings()?.global?.delivery?.freeDeliveryThreshold || 0).toString()
                    },
                    {
                        setting: 'vat_default_rate',
                        value: (webshopSettings()?.global?.vatDefaultRate || 0).toString()
                    },
                    {
                        setting: 'delivery_costs_percat',
                        value: (webshopSettings()?.global?.delivery?.deliveryCostsPerCategory || 0).toString()
                    },
                    {
                        setting: 'admin_price_ideal',
                        value: (webshopSettings().global?.paymentCosts?.ideal || 0).toString()
                    }
                ],
                vat_rates: [ 0, 9, 21 ]
            })
        },
        updateLocalCartPrices: debounce(async () => {
            await cart().calculateLocalCartPrices()
        }, 300),
        async calculateLocalCartPrices () {
            const { public: { cartDebugMode } } = useRuntimeConfig()
            const calculatorInputCart: CalculatorLocalInputCart = {
                zip_codes: convertGqlZipCodesForCalculator(webshopSettings()?.global?.delivery?.deliveryAreas || []),
                cart: {
                    zip_code: checkout().input.customer.addressGroup.postalCode ?? '',
                    tip: this.getTip,
                    payment_method: checkout()?.input?.payment?.method ? (checkout().input.payment.method).toLowerCase() : 'cash',
                    type: convertGqlDispatchTypeForCalculator(checkout().input.dispatchType),
                    products: convertCartProductsForCalculator(cloneDeep(this.cart.products)) // this needs to be done via cloneDeep else the cart will be modified
                }
            }
            const localCalculatorInput = {
                ...this.calculatorInputDatabase,
                ...calculatorInputCart
            }
            if (cartDebugMode) {
                this.devInfo.localPayload = localCalculatorInput
            }
            // See https://foodticket.atlassian.net/browse/CV-4 as to why we have to clone the input object here
            const localCalculatorData: any = await calculateLocal(cloneDeep(localCalculatorInput))
            if (localCalculatorData?._log) {
                if (cartDebugMode) {
                    this.devInfo.localLog = localCalculatorData._log
                }
                delete localCalculatorData._log
            } else {
                if (cartDebugMode) {
                    this.devInfo.localCalculatorData = []
                }
            }
            this.cartPricesLocal = cleanCalculatorResponse(localCalculatorData)
            // If the cash amount is lower than the total price, reset the cash amount
            if (checkout().input.payment.cashAmount! < this.getCartTotalPriceLocal) {
                checkout().resetCashPayment()
            }
        },
        async updateValidatedCartPrices () {
            const { public: { cartDebugMode } } = useRuntimeConfig()
            const calculatorInput: CalculateInputCalculatorService = {
                clientUuid: webshopSettings()?.global?.client?.uuid?.toString(),
                source: SourceType.ONLINE,
                cart: {
                    free_products: [],
                    tip: this.getTip,
                    payment_method: checkout()?.input?.payment?.method === PaymentMethodOrderBuddy.IDEAL ? PaymentMethodCalculatorService.IDEAL : PaymentMethodCalculatorService.CASH,
                    discounts: this?.cart?.discounts?.map((discount: string) => ({
                        code: discount,
                        type: 'code'
                    })),
                    zip_code: checkout().input.customer.addressGroup.postalCode, // TODO: enable when https://foodticket.atlassian.net/browse/WF-685 is done
                    products: convertCartProductsForCalculator(cloneDeep(this.cart.products)), // this needs to be done via cloneDeep else the cart will be modified
                    type: convertGqlDispatchTypeForCalculator(checkout().input.dispatchType)
                }
            }
            if (!isEqual(this.calculatorInputLastValidated, calculatorInput)) {
                this.cartIsLoading = true
                if (cartDebugMode) {
                    this.devInfo.validatedPayload = calculatorInput
                }
                const fetchedCalculatorData = await calculatorCall(calculateQuery, 'calculate', {
                    input: calculatorInput
                }, false)
                if (fetchedCalculatorData?._log) {
                    if (cartDebugMode) {
                        this.devInfo.validatedLog = fetchedCalculatorData._log
                    }
                    delete fetchedCalculatorData._log
                } else {
                    if (cartDebugMode) {
                        this.devInfo.validatedLog = []
                    }
                }
                this.cartPricesValidated = cleanCalculatorResponse(fetchedCalculatorData)
                this.calculatorInputLastValidated = calculatorInput
                this.cartIsLoading = false
            }
        },
        async addDiscountCode (discountCode: string) {
            this.cart.discounts[0] = discountCode
            await cart().updateValidatedCartPrices()
        },
        async removeDiscountCode (discountCode: string) {
            this.cart.discounts = this.cart.discounts.filter((code: string) => code !== discountCode)
            await cart().updateValidatedCartPrices()
        },
        resetCart () {
            this.cart.products = {}
        }
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(cart, import.meta.hot))
}
