<template>
    <NuxtLoadingIndicator :color="webshopSettings()?.hasPrimaryColor ? webshopSettings().global.primaryColor : '#E06633'" />
    <div class="space-y-4">
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>

<script setup lang="ts">
    import { useModalSlot } from 'vue-final-modal'
    import { webshopSettings } from '~/store/webshopSettings'
    import ModalContentAgeCheck from '~/components/Modal/Content/ModalContentAgeCheck.vue'
    import ModalContentAgeCheckBottom from '~/components/Modal/Content/ModalContentAgeCheckBottom.vue'
    import { messagesModal } from '~/composables/messagesModal'
    const route = useRoute()
    const i18nHead = useLocaleHead({
        addSeoAttributes: {
            canonicalQueries: [ 'foo' ]
        }
    })
    useHead({
        htmlAttrs: {
            lang: i18nHead.value.htmlAttrs!.lang
        },
        titleTemplate: titleChunk => {
            const title = webshopSettings()?.getSeoTitle || 'Foodticket Webshop'
            return titleChunk ? `${title} | ${titleChunk}` : title
        },
        meta: [
            {
                name: 'description',
                content: webshopSettings()?.global?.seoDescription || 'Foodticket Webshop Description'
            }
        ],
        style: [
            {
                innerHTML: webshopSettings().primaryColorCssVariable
            }
        ]
    })
    onMounted(() => {
        if (webshopSettings()?.global?.webshopAgePopup && route.fullPath !== !route.fullPath.includes('checkout/thanks')) {
            const modalInstance = messagesModal({
                default: useModalSlot({
                    component: ModalContentAgeCheck
                }),
                bottom: useModalSlot({
                    component: ModalContentAgeCheckBottom,
                    attrs: {
                        closeModal: () => {
                            modalInstance.close()
                        }
                    }
                })
            })
        }
    })
</script>
