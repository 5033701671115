export const checkoutQuery = gql`
     query FinancialInstitutions {
        financialInstitutions(filter: { type: "ideal" }) {
            data {
                id
                name
                issuerId
            }
        }
    }
`
