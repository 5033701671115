
import * as customProviderRuntime$uA6FG2OEVr from '/opt/atlassian/pipelines/agent/build/providers/custom.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "customProvider",
  "domains": [
    "diq1wl0g8dlu4.cloudfront.net",
    "imagedelivery.net"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['customProvider']: { provider: customProviderRuntime$uA6FG2OEVr, defaults: {"modifiers":{"quality":80,"format":"webp"}} }
}
        