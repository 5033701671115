import { useModal } from 'vue-final-modal'
import ModalMessage from '~/components/Modal/ModalMessage.vue'
import { blockScroll, unBlockScroll } from '~/composables/blockScroll'

export function messagesModal (slots: any) {
    const messagesModalInstance = useModal({
        component: ModalMessage,
        attrs: {
            onBeforeOpen () {
                blockScroll(window.scrollY)
            },
            onBeforeClose () {
                unBlockScroll()
            },
            onClose () {
                messagesModalInstance.close()
            }
        },
        slots
    })
    messagesModalInstance.open()
    return messagesModalInstance
}
