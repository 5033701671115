import type { FormKitOptionsItem } from '@formkit/inputs'
import { checkoutQuery } from '~/graphql/queries/checkout.query.gql'
import { countriesQuery } from '~/graphql/queries/countries.query.gql'
import type { CountryOrderBuddy, DispatchTypeOrderBuddy, QuerycheckDeliveryAreaOrderBuddyArgs } from '~/types/orderbuddy-types'
import { PaymentMethodOrderBuddy } from '~/types/orderbuddy-types'
import { cart } from '~/store/cart'
import type { CheckoutState, customerDefaultInfo } from '~/types/local-types'
import { orderingDatesQuery } from '~/graphql/queries/orderingDates.query.gql'
import { orderingTimesQuery } from '~/graphql/queries/orderingTimes.query.gql'
import { webshopSettings } from '~/store/webshopSettings'
import { apiCall } from '~/composables/apiCall'
const defaultCustomerInput: customerDefaultInfo = {
    birthday: undefined,
    firstName: '',
    lastName: '',
    email: '',
    phoneGroup: {
        phone: '',
        phoneCountry: 'NL'
    },
    type: 'CONSUMER',
    addressGroup: {
        street: '',
        city: '',
        postalCode: '',
        streetNumber: '',
        country: 'NL',
        invAddressSame: true
    }
}

export const checkout = defineStore('checkout', {
    state: (): CheckoutState => <CheckoutState>({
        input: {
            saveAddress: true,
            tip: 0,
            deliveryAtDate: undefined,
            tipData: {
                tipRadio: webshopSettings().global.tip?.defaultPercentage || 0,
                customTip: 0
            },
            payment: {
                method: webshopSettings()?.global?.paymentMethods?.length === 1 && webshopSettings()?.global.paymentMethods![0] === PaymentMethodOrderBuddy.IDEAL ? PaymentMethodOrderBuddy.IDEAL : undefined
            },
            customer: defaultCustomerInput,
            dispatchType: webshopSettings().getDefaultDispatchType
        },
        paymentMethods: {},
        autoCompleteAddress: [],
        dates: null,
        availableTimes: [],
        countries: []
    } as CheckoutState),
    persist: {
        storage: persistedState.localStorage
    },
    getters: {
        getCountriesForDropdown (): Array<FormKitOptionsItem> {
            return this?.countries?.map((country: CountryOrderBuddy) => ({
                label: country.alpha2Code,
                value: country.alpha2Code
            }))
        },
        isDateValid () {
            return (date?: Date) => {
                date = date ? new Date(date) : new Date()
                if (this?.dates?.startDate && date < new Date(this.dates.startDate)) {
                    return false
                }
                if (this?.dates?.endDate && date > new Date(this.dates.endDate)) {
                    return false
                }
                return !checkout()?.dates?.disallowedDates?.includes(date.toISOString().substring(0, 10))
            }
        }
    },
    actions: {
        resetCustomerInput () {
            this.input.tip = 0
            this.input.deliveryAtDate = undefined
            this.input.tipData.tipRadio = webshopSettings().global.tip?.defaultPercentage || 0
            this.input.tipData.customTip = 0
            this.input.payment.financialInstitution = undefined
            this.input.dispatchType = webshopSettings().getDefaultDispatchType
            this.paymentMethods = {}
            this.dates = null
            this.availableTimes = []
            this.countries = []
            if (!this.input.saveAddress) {
                this.input.customer = defaultCustomerInput
            }
        },
        async setDispatchType (name: DispatchTypeOrderBuddy, postActions: boolean = true) {
            this.input.dispatchType = name
            if (process.client) {
                cart().setCalculatorInputDatabase()
            }
            if (postActions) {
                const route = useRoute()
                if (route.path.includes('checkout')) {
                    await this.getDates()
                    await this.getAvailableTimes()
                }
                await cart().updateLocalCartPrices()
                await cart().updateValidatedCartPrices()
            }
        },
        setPaymentMethod (method: PaymentMethodOrderBuddy) {
            this.input.payment.method = method
        },
        resetCashPayment () {
            this.input.payment.cashAmount = null
        },
        setCustomerAdressGroup (postalCode: string, streetNumber: string) {
            this.input.customer.addressGroup.postalCode = postalCode
            this.input.customer.addressGroup.streetNumber = streetNumber
        },
        async fetchFinancialInstitutions () {
            if (Object.keys(this.paymentMethods).length === 0) {
                const data = await apiCall(checkoutQuery, 'financialInstitutions', {}, false)
                if (data?.data) {
                    this.paymentMethods = data.data
                }
            }
        },
        async getAvailableTimes () {
            if (this?.input?.deliveryAtDate) {
                // Dates should be formatted in YYYY-MM-DD
                const data = await apiCall(orderingTimesQuery, 'orderingTimes', {
                    dispatchType: this.input.dispatchType,
                    date: new Date(this.input.deliveryAtDate).toISOString().substring(0, 10)
                }, false)
                if (data?.times) {
                    this.availableTimes = data.times
                    this.setTargetTime()
                }
            }
        },
        setTargetTime () {
            if (this.availableTimes?.length === 0 || !this.availableTimes?.includes(this.input.deliveryAtTime)) {
                // If there are no times or if the selected time is not available, reset the time input value
                this.input.deliveryAtTime = undefined
            }
        },
        async getDates () {
            const data = await apiCall(orderingDatesQuery, 'orderingDates', {
                dispatchType: this.input.dispatchType
            }, false)
            if (data) {
                this.dates = data
                this.setTargetDate()
            }
        },
        setTargetDate () {
            const hasDeliveryAtDate = this?.input?.deliveryAtDate !== undefined
            if (!hasDeliveryAtDate && this.isDateValid()) {
                // If the today is a valid date, then set today as the deliveryAtDate
                this.input.deliveryAtDate = new Date()
            } else if (hasDeliveryAtDate && !this.isDateValid(this.input.deliveryAtDate)) {
                // If there is deliveryAtDate, but it is invalid
                if (new Date(this.input.deliveryAtDate) < new Date()) {
                    // If the current deliveryAtDate is in the past
                    if (this.isDateValid()) {
                        // If today is a valid date, then set today as the deliveryAtDate
                        this.input.deliveryAtDate = new Date()
                    } else {
                        // If today is not a valid date, then reset deliveryAtDate to force the user to make a new choice
                        this.input.deliveryAtDate = new Date()
                    }
                } else {
                    // If the deliveryAtDate is invalid, but not in the past, then reset deliveryAtDate to force the user to make a new choice
                    this.input.deliveryAtDate = undefined
                }
            }
        },
        async fetchCountries () {
            if (this.countries.length === 0) {
                this.countries = await apiCall(countriesQuery, 'countries', {}, false)
            }
        },
        setUserDeliveryAddress (data: QuerycheckDeliveryAreaOrderBuddyArgs) {
            data && data.streetNumber ? this.input.customer.addressGroup.streetNumber = data.streetNumber : this.input.customer.addressGroup.streetNumber = ''
            data && data.postalCode ? this.input.customer.addressGroup.postalCode = data.postalCode : this.input.customer.addressGroup.postalCode = ''
        }
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(checkout, import.meta.hot))
}
