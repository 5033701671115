import cloneDeep from 'lodash.clonedeep'
import {
    type CategoryOrderBuddy,
    DispatchTypeOrderBuddy,
    type ExtraOrderBuddy,
    type ItemOrderBuddy,
    type Maybe,
    type MenuOrderBuddy,
    type ProductOrderBuddy
} from '~/types/orderbuddy-types'
import type { ShopSettings } from '~/types/local-types'
import { checkout } from '~/store/checkout'
import { categoriesQuery } from '~/graphql/queries/categories.query.gql'

export const shop = defineStore('shop', {
    state: (): ShopSettings => <ShopSettings>({
        searchValue: '',
        deliveryMenu: markRaw([]),
        pickupMenu: markRaw([])
    } as ShopSettings),
    getters: {
        currentMenu (state) {
            return checkout().input.dispatchType === DispatchTypeOrderBuddy.DELIVER ? state.deliveryMenu : state.pickupMenu
        },
        allProducts (state) {
            return state?.currentMenu?.flatMap(({ products }) => products) || []
        },
        findProduct (state): ((productId: string | number) => Maybe<ProductOrderBuddy>) {
            return productId => state?.allProducts?.find(({ id }) => id === (typeof productId === 'string' ? Number.parseInt(productId) : productId)) || null
        },
        allExtras (state) {
            return state?.allProducts?.flatMap(({ extras }) => extras) || []
        },
        findExtra (state): ((extraId: string | number) => Maybe<ExtraOrderBuddy>) {
            return extraId => state?.allExtras?.find(({ id }) => id === (typeof extraId === 'string' ? Number.parseInt(extraId) : extraId)) || null
        },
        allExtrasItems (state) {
            return state?.allExtras?.flatMap(({ items }) => items) || []
        },
        findExtraItem (state): ((extraItemId: string) => Maybe<ItemOrderBuddy>) {
            return extraItemId => state?.allExtrasItems?.find(({ id }) => id === extraItemId) || null
        },
        filterProductsThatContainSearchValue (state): ((category: CategoryOrderBuddy) => Maybe<Array<ProductOrderBuddy>>) {
            return (category: CategoryOrderBuddy) => {
                const categoryMatches = category?.title?.toLowerCase().includes(state.searchValue!.toLowerCase()) || (category?.description && category.description.toLowerCase().includes(state.searchValue!.toLowerCase()))
                return category?.products?.filter(product => {
                    return categoryMatches || product?.title?.toLowerCase().includes(state.searchValue!.toLowerCase()) || (product?.description && product.description.toLowerCase().includes(state.searchValue!.toLowerCase()))
                }) || []
            }
        },
        filteredCategories (state): Maybe<Array<CategoryOrderBuddy>> {
            if (state.currentMenu && state.currentMenu.length > 0) {
                if (state.searchValue) {
                    return state?.currentMenu?.map((category: CategoryOrderBuddy) => ({
                        ...category,
                        products: this.filterProductsThatContainSearchValue(category)
                    })).filter((category: CategoryOrderBuddy) => category?.products && category.products.length > 0) || []
                } else {
                    return state.currentMenu
                }
            }
            return []
        }
    },
    actions: {
        setSearchValue (data: string) {
            this.searchValue = data
        },
        async getDeliveryMenu () {
            if (this.deliveryMenu!.length === 0) {
                const delivery: MenuOrderBuddy = await apiCall(categoriesQuery, 'menu', {
                    dispatchType: DispatchTypeOrderBuddy.DELIVER
                }, false)
                if (delivery?.categories) {
                    return this.deliveryMenu = cloneDeep(delivery?.categories?.filter(category => category.products?.length))
                }
            }
        },
        async getPickupMenu () {
            if (this.pickupMenu!.length === 0) {
                const pickup: MenuOrderBuddy = await apiCall(categoriesQuery, 'menu', {
                    dispatchType: DispatchTypeOrderBuddy.PICK_UP
                }, false)
                if (pickup?.categories) {
                    return this.pickupMenu = cloneDeep(pickup.categories.filter(category => category.products?.length))
                }
            }
        }
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(shop, import.meta.hot))
}
