export async function calculatorCall (query: any, returnObject?: string, variables: object = {}, cache: boolean = true) {
    const { public: { calculatorApiKey } } = useRuntimeConfig()
    const customHeaders = {
        'x-api-key': calculatorApiKey // TODO: proxy this somehow, need to find a way to use GQL on the api route
    }
    const { resolveClient } = useApolloClient()
    const client = resolveClient('calculator')
    const { data } = await client.query({
        query,
        variables,
        fetchPolicy: cache ? 'cache-first' : 'no-cache',
        context: {
            headers: {
                ...customHeaders
            }
        }
    })
    if (data && returnObject) {
        return data[returnObject]
    }
    return data?.value
}
