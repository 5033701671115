import type { ErrorResponse } from '@nuxtjs/apollo'
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'

export function hasValidation (error: ErrorResponse) {
    return error?.graphQLErrors?.length && error?.graphQLErrors[0]?.extensions?.validation
}
export default defineNuxtPlugin(nuxtApp => {
    if (process.env.ENV === 'local') {
        loadDevMessages()
        loadErrorMessages()
    }
    nuxtApp.hook('apollo:error', error => {
        if (!hasValidation(error)) {
            // eslint-disable-next-line no-console
            console.log('SentryDevInfo: GraphQL error', error)
            throw showError({ statusCode: 503, statusMessage: 'GraphQL error', data: error })
        }
    })
})
