export const calculateQuery = gql`
    query MyQuery ($input: CalculateInput!) {
        calculate(input: $input) {
            discount
            discounts {
                amount
                amount_type
                code
                label
                type
            }
            subtotal
            total
            cart {
                products {
                    id
                    order_product_id
                    quantity
                    remarks
                    total
                    discount_id
                    packaging_costs
                    category_packaging_costs
                    price
                    deposit
                    discount
                    total_extras
                    items {
                        id
                        extra_id
                        product_id
                        quantity
                        total
                        packaging_costs
                        deposit
                    }
                }
            }
            costs {
                payment
                delivery
                packaging
                deposit
                tip
            }
            vat {
                total
            }
            points {
                spent
                earned
            }
            delivery_fee_conditions {
                free_from
                minimum
            }
            choices {
                label
                product_ids
                count
                discount
            }
        }
    }
`
