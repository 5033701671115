export const webshopSettingsQuery = gql`
    query WebshopSettings {
        webshopSettings {
            additionalLanguages
            client {
                id
                company
                address
                postalCode
                country_code
                city
                tel
                vatNumber
                cocNumber
                uuid
            }
            availableLanguages
            cssLogo {
                disk
                path
                url
            }
            orderAhead {
                orderAheadActive
            }
            defaultLanguage
            delivery {
                deliveryAreas {
                    id
                    name
                    start
                    end
                    deliveryTime
                    deliveryCosts
                    minimumOrderThreshold
                    freeDeliveryThreshold
                    isAvailable
                }
                minimumOrderThreshold
                freeDeliveryThreshold
                deliveryCosts
                deliveryCostsPerCategory
            }
            tip {
                enabled,
                defaultPercentage
            },
            webshopAgePopup
            gaEcommerce
            gaProperty
            googleDirectionsMode
            googleTagManagerId
            hasLanguageSwitcher
            menus
            minimumDeliveryTime
            minimumPickupTime
            paymentMethods
            paymentCosts {
            ideal
        }
            paymentCashMaximum
            name
            openingHours {
                dispatchType
                dayNumber
                dayName
                date
                isOpen
                reasonClosed
                openingHoursRange {
                    start
                    end
                }
            }
            primaryColor
            prizewheelActive
            seoDescription
            seoTitle
            dispatchTypes
            showProductRemarks
            shouldShowStreetNumberForDelivery
            theme
            emergencyBrake {
                closedTemporarily
                deliveryClosedTemporarily
                deliveryMinTimeTemporarily
                pickupClosedTemporarily
                pickupMinTimeTemporarily
            },
            socialMedia {
                facebook {
                    pixel
                    url
                }
                instagram {
                    url
                }
                twitter {
                    url
                }
            }
            mobileApps {
                android
                ios
            }
        }
    }
`

export const translatedQuery = gql`
    query WebshopSettings {
        webshopSettings {
            hero {
                title
                subtitle
                image {
                    disk
                    path
                    url
                }
            }
            contentBlocks {
                buttonText
                buttonUrl
                image {
                    disk
                    path
                    url
                }
                imagePosition
                shouldShowButton
                text
                title
            }
            socialMedia {
                facebook {
                    pixel
                    url
                }
                instagram {
                    url
                }
                twitter {
                    url
                }
            }
            mobileApps {
                android
                ios
            }
        }
    }
`
