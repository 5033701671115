// TODO NOTE: In case we do need to pick data from the store, then we should convert these utils into composables
import cloneDeep from 'lodash.clonedeep'
import { type CartProduct as CartProductCalculatorLocal, DispatchType as DispatchTypeCalculatorLocal } from 'orderbuddy-calculations/src/input/types/Cart'
import type { Category as CategoryCalculatorLocal } from 'orderbuddy-calculations/src/input/types/Category'
import type { Extra as ExtraCalculatorLocal } from 'orderbuddy-calculations/src/input/types/Extra'
import type { Item as ItemCalculatorLocal } from 'orderbuddy-calculations/src/input/types/Item'
import type { Output as OutputCalculatorLocal } from 'orderbuddy-calculations/src/output/types/Output'
import type { Product as ProductCalculatorLocal } from 'orderbuddy-calculations/src/input/types/Product'
import type { ZipCode } from 'orderbuddy-calculations/src/input/types/ZipCode'
import { DispatchTypeOrderBuddy } from '~/types/orderbuddy-types'
import type { CategoryOrderBuddy, DeliveryAreaOrderBuddy, ExtraOrderBuddy, ItemOrderBuddy, Maybe, ProductOrderBuddy } from '~/types/orderbuddy-types'
import type { CartExtrasLocal, CartItemCalculatorLocalReduced, CartProductCalculatorLocalReduced, CartProductsLocal } from '~/types/local-types'
import { cart } from '~/store/cart'

export function cleanCalculatorResponse (calculator: OutputCalculatorLocal): OutputCalculatorLocal {
    const currentCalculator = cloneDeep(calculator)
    if (currentCalculator?.cart?.products.length) {
        currentCalculator.cart.products = currentCalculator.cart.products.filter((product: CartProductCalculatorLocal) => product.id !== -5) // TODO: Write ticket to move this behaviour to where the data gets submitted to the Order endpoint in all apps that use the underlying package
    }
    const calculatedProducts = currentCalculator.cart.products
    const localProducts = cart().cart.products
    const newProducts: any = {}
    let currentKey = 0
    Object.keys(localProducts).forEach((key: string) => {
        newProducts[key] = calculatedProducts[currentKey]
        currentKey++
    })
    currentCalculator.cart.products = newProducts
    return currentCalculator
}

// TODO ASK RIK: packaging_cost should be able to be omitted
export function convertCartProductsForCalculator (products: CartProductsLocal): CartProductCalculatorLocalReduced[] {
    return Object.values(products).map(product => ({
        id: product.id,
        items: convertCartExtraItemsForCalculator(product.extras || {}),
        quantity: product.quantity,
        remarks: product?.note
    }))
}

export function convertCartExtraItemsForCalculator (extras: CartExtrasLocal): CartItemCalculatorLocalReduced[] {
    return Object.entries(extras).flatMap(([ extraKey, extra ]) =>
        Object.entries(extra.items).map(([ itemKey, item ]) => {
            const itemAndProductId = itemKey.split('-p')
            return {
                id: Number.parseInt(itemAndProductId[0]),
                extra_id: Number.parseInt(extraKey),
                product_id: Number.parseInt(itemAndProductId[1]) || null,
                quantity: item.quantity
            }
        })
    )
}

export function convertGqlExtraItemsForCalculator (items: Maybe<Array<ItemOrderBuddy>>): ItemCalculatorLocal[] {
    return items?.map(item => {
        return {
            id: Number.parseInt(item.id.split('-p')[0]),
            price: item.price ?? null,
            packaging_costs: item.packagingCosts ?? null
        }
    }) || []
}

export function convertGqlExtrasForCalculator (extras: Maybe<Array<ExtraOrderBuddy>>): ExtraCalculatorLocal[] {
    return extras?.map(extra => {
        return {
            id: Number(extra?.id),
            item_ids: extra?.items?.map((item: ItemOrderBuddy) => {
                const itemAndProductId = item.id.split('-p')
                return Number.parseInt(itemAndProductId[0])
            }) ?? []
        }
    }) || []
}

export function convertGqlProductsForCalculator (products: Maybe<Array<ProductOrderBuddy>>): Array<ProductCalculatorLocal> {
    return products?.map(product => {
        return {
            packaging_costs: product?.packagingCosts ?? null,
            id: product.id,
            price: {
                default: product.price
            },
            deposit: product?.deposit ?? null,
            vat_rate: product?.vat ?? 0, // TODO: should the default be 0 or 9? Or is there a setting for this? change when https://foodticket.atlassian.net/browse/WSV3-271 is done
            vat_rate_alt: product?.vatAlt ?? null,
            extra_ids: product?.extras?.map((extra: ExtraOrderBuddy) => extra.id) ?? [],
            main_category_id: product?.category?.id || null
        }
    }) || []
}

export function convertGqlCategoriesForCalculator (categories: Maybe<Array<CategoryOrderBuddy>>): Array<CategoryCalculatorLocal> {
    return categories?.map(category => {
        return {
            id: category.id,
            parent_id: category?.parentCategory?.id || 0,
            delivery_costs: category.deliveryCosts ?? 0,
            packaging_costs: category.packagingCosts ?? null
        }
    }) || []
}

export function convertGqlDispatchTypeForCalculator (dispatchType: DispatchTypeOrderBuddy): DispatchTypeCalculatorLocal {
    return dispatchType === DispatchTypeOrderBuddy.DELIVER ? DispatchTypeCalculatorLocal.DELIVER : DispatchTypeCalculatorLocal.PICK_UP
}

export function convertGqlZipCodesForCalculator (deliveryAreas: Maybe<Array<Maybe<DeliveryAreaOrderBuddy>>>): Array<ZipCode> {
    return deliveryAreas?.map(deliveryArea => {
        return {
            id: Number.parseInt(deliveryArea!.id),
            from: deliveryArea?.start || '',
            to: deliveryArea?.end || null,
            minimum: deliveryArea?.minimumOrderThreshold ?? null,
            free_from: deliveryArea?.freeDeliveryThreshold ?? null,
            costs: deliveryArea?.deliveryCosts ?? null
        }
    }) || []
}
