export function hexToHSL (hex: string) {
    const result: RegExpExecArray | null = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    let r: number = 0
    let g: number = 0
    let b: number = 0

    if (result != null) {
        r = Number.parseInt(result[1], 16)
        g = Number.parseInt(result[2], 16)
        b = Number.parseInt(result[3], 16)
    }
    r /= 255
    g /= 255
    b /= 255
    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    let h: number = 0
    let s: number = 0
    let l: number = (max + min) / 2
    if (max === min) {
        h = s = 0 // achromatic
    } else {
        const d = max - min
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0)
                break
            case g:
                h = (b - r) / d + 2
                break
            case b:
                h = (r - g) / d + 4
                break
        }
        h /= 6
    }
    h = Math.round(h * 360)
    s = Math.round(s * 100)
    l = Math.round(l * 100)
    return { h, s, l }
}
