export const loginUser = gql`
    mutation loginUser(
        $email: String!,
        $password: String!
    ) {
        login(
            input:{
            email: $email,
            password: $password
        }) {
            token
        }
    }
`
