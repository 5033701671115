import type { RouteLocationNormalized } from 'vue-router'
import { translatedQuery, webshopSettingsQuery } from '~/graphql/queries/settings.query.gql'
import { webshopSettings } from '~/store/webshopSettings'
import { apiCall, apiCallSsr } from '~/composables/apiCall'

function extractLanguageFromRoute (route: RouteLocationNormalized) {
    const routeNameFragments = (route?.name as string)?.split('___')
    return routeNameFragments && routeNameFragments.length === 2 ? routeNameFragments[1] : null
}

export default defineNuxtRouteMiddleware(async (to, from) => {
    // TODO there is an SSR issue because Apollo does not know how to distinguish between the two different webshopSettings queries (since they are both the same) and it doesnt know what to write to its cache
    const toRouteLang = extractLanguageFromRoute(to)
    const fromRouteLang = extractLanguageFromRoute(from)
    if (webshopSettings().currentHost === null) {
        const { public: { foodticketHost } } = useRuntimeConfig()
        const host = foodticketHost || useRequestHeaders()['x-forwarded-host'] || useRequestHeaders().host
        webshopSettings().setCurrentHost(host)
    }
    if (toRouteLang !== null) {
        webshopSettings().setCurrentLang(toRouteLang)
    }
    if (Object.keys(webshopSettings().global).length === 0) {
        const settings = await apiCallSsr(webshopSettingsQuery, 'webshopSettings', {}, true)
        webshopSettings().setGlobalSettings(settings)
    }
    if (Object.keys(webshopSettings().translated).length === 0 || ((!process.server && toRouteLang !== fromRouteLang) && fromRouteLang !== null)) {
        const translated = await apiCall(translatedQuery, 'webshopSettings', {}, false)
        webshopSettings().setTranslated(translated)
    }
    return true
})
