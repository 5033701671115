import routerOptions0 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.13.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_typ_zbvvoyunohqgjrsyhyqxfkitmq/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/atlassian/pipelines/agent/build/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}