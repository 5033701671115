import pickBy from 'lodash.pickby'
import mapValues from 'lodash.mapvalues'
import { hash } from 'ohash'
import type { CartItemsLocal, CartProductLocal } from '~/types/local-types'
import type { ProductOrderBuddy } from '~/types/orderbuddy-types'

export function getCartProductId (product: any) {
    product = cleanProduct(product)
    product = Object.fromEntries(
        Object.entries(product).filter(([ key ]) => [ 'id', 'extras', 'note' ].includes(key))
    )
    return hash(product)
}

export function cleanProduct (product: CartProductLocal) {
    if (product?.extras) {
        // remove the extra items that are not true
        product.extras = mapValues(product.extras, (extra: CartItemsLocal) => {
            return mapValues(extra, extraItem => {
                return pickBy(extraItem, (value: any) => {
                    return Number(value.quantity) > 0
                })
            })
        })
        // remove the extras that are empty
        product.extras = pickBy(product.extras, (extra: CartItemsLocal) => {
            return Object.keys(extra.items).length > 0
        })
        if (product.extras && Object.keys(product.extras).length === 0) {
            delete product.extras
        }
    }
    return product
}

export function predictPriceOfProduct (product: ProductOrderBuddy, cartProduct: CartProductLocal) {
    const currentProductPrice: number = Number(product?.price) || 0
    const currentExtrasPrice = (): number => {
        let extrasTotal = 0
        if (cartProduct?.extras && Object.keys(cartProduct?.extras).length) {
            extrasTotal = Object.values(cartProduct.extras).reduce((total: number, extra: any) => {
                const selectedExtraItems: string[] = Object.keys(extra.items).filter(itemKey => extra.items[itemKey].selected)
                selectedExtraItems.forEach((itemKey: string) => {
                    const extraItems = product.extras?.flatMap(({ items }) => items) || []
                    if (extraItems) {
                        const extraItem = extraItems.find(({ id }) => id === itemKey) || null
                        if (extraItem) {
                            total += Number(extraItem.price) * Number(extra.items[itemKey].quantity)
                        }
                    }
                })
                return Number(total)
            }, 0)
        }
        return extrasTotal
    }
    return currencyFormat((currentProductPrice + currentExtrasPrice()) * cartProduct.quantity)
}
