import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as forgotPasswordP1MSa0GwnIMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/forgotPassword.vue?macro=true";
import { default as indexMyUOJMAwXCMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/index.vue?macro=true";
import { default as meCwaj0fblkyMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/me.vue?macro=true";
import { default as register5avPR2VtvoMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/register.vue?macro=true";
import { default as indexqJXazFsVc0Meta } from "/opt/atlassian/pipelines/agent/build/pages/checkout/index.vue?macro=true";
import { default as thanksXatxgcmdZHMeta } from "/opt/atlassian/pipelines/agent/build/pages/checkout/thanks.vue?macro=true";
import { default as checkout5qXjCYF1WDMeta } from "/opt/atlassian/pipelines/agent/build/pages/checkout.vue?macro=true";
import { default as _91_46_46_46page_93LQOgOGwcEDMeta } from "/opt/atlassian/pipelines/agent/build/pages/reviews/[...page].vue?macro=true";
import { default as reviews1OzbITw0auMeta } from "/opt/atlassian/pipelines/agent/build/pages/reviews.vue?macro=true";
import { default as indexDyhZ0ztkKBMeta } from "/opt/atlassian/pipelines/agent/build/pages/shop/index.vue?macro=true";
import { default as pickupMiK3F000SNMeta } from "/opt/atlassian/pipelines/agent/build/pages/shop/pickup.vue?macro=true";
import { default as shopypetHlg2TIMeta } from "/opt/atlassian/pipelines/agent/build/pages/shop.vue?macro=true";
import { default as signupnmDFzkRJBDMeta } from "/opt/atlassian/pipelines/agent/build/pages/signup.vue?macro=true";
import { default as terms_45and_45conditionsx0Uq77uAJOMeta } from "/opt/atlassian/pipelines/agent/build/pages/terms-and-conditions.vue?macro=true";
import { default as component_45stub0S93IkJfkwMeta } from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.13.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_typ_zbvvoyunohqgjrsyhyqxfkitmq/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub0S93IkJfkw } from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.13.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_typ_zbvvoyunohqgjrsyhyqxfkitmq/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue")
  },
  {
    name: "account-forgotPassword___en",
    path: "/en/account/forgotPassword",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/forgotPassword.vue")
  },
  {
    name: "account-forgotPassword___fr",
    path: "/fr/account/forgotPassword",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/forgotPassword.vue")
  },
  {
    name: "account-forgotPassword___nl",
    path: "/nl/account/forgotPassword",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/forgotPassword.vue")
  },
  {
    name: "account-forgotPassword___de",
    path: "/de/account/forgotPassword",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/forgotPassword.vue")
  },
  {
    name: "account-forgotPassword___it",
    path: "/it/account/forgotPassword",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/forgotPassword.vue")
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/index.vue")
  },
  {
    name: "account___fr",
    path: "/fr/account",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/index.vue")
  },
  {
    name: "account___nl",
    path: "/nl/account",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/index.vue")
  },
  {
    name: "account___de",
    path: "/de/account",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/index.vue")
  },
  {
    name: "account___it",
    path: "/it/account",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/index.vue")
  },
  {
    name: "account-me___en",
    path: "/en/account/me",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/me.vue")
  },
  {
    name: "account-me___fr",
    path: "/fr/account/me",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/me.vue")
  },
  {
    name: "account-me___nl",
    path: "/nl/account/me",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/me.vue")
  },
  {
    name: "account-me___de",
    path: "/de/account/me",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/me.vue")
  },
  {
    name: "account-me___it",
    path: "/it/account/me",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/me.vue")
  },
  {
    name: "account-register___en",
    path: "/en/account/register",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/register.vue")
  },
  {
    name: "account-register___fr",
    path: "/fr/account/register",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/register.vue")
  },
  {
    name: "account-register___nl",
    path: "/nl/account/register",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/register.vue")
  },
  {
    name: "account-register___de",
    path: "/de/account/register",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/register.vue")
  },
  {
    name: "account-register___it",
    path: "/it/account/register",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/register.vue")
  },
  {
    name: checkout5qXjCYF1WDMeta?.name,
    path: "/en/checkout",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout.vue"),
    children: [
  {
    name: "checkout___en",
    path: "",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout/index.vue")
  },
  {
    name: "checkout-thanks___en",
    path: "thanks",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout/thanks.vue")
  }
]
  },
  {
    name: checkout5qXjCYF1WDMeta?.name,
    path: "/fr/checkout",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout.vue"),
    children: [
  {
    name: "checkout___fr",
    path: "",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout/index.vue")
  },
  {
    name: "checkout-thanks___fr",
    path: "thanks",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout/thanks.vue")
  }
]
  },
  {
    name: checkout5qXjCYF1WDMeta?.name,
    path: "/nl/checkout",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout.vue"),
    children: [
  {
    name: "checkout___nl",
    path: "",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout/index.vue")
  },
  {
    name: "checkout-thanks___nl",
    path: "thanks",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout/thanks.vue")
  }
]
  },
  {
    name: checkout5qXjCYF1WDMeta?.name,
    path: "/de/checkout",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout.vue"),
    children: [
  {
    name: "checkout___de",
    path: "",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout/index.vue")
  },
  {
    name: "checkout-thanks___de",
    path: "thanks",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout/thanks.vue")
  }
]
  },
  {
    name: checkout5qXjCYF1WDMeta?.name,
    path: "/it/checkout",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout.vue"),
    children: [
  {
    name: "checkout___it",
    path: "",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout/index.vue")
  },
  {
    name: "checkout-thanks___it",
    path: "thanks",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout/thanks.vue")
  }
]
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue")
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue")
  },
  {
    name: "reviews___en",
    path: "/en/reviews",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/reviews.vue"),
    children: [
  {
    name: "reviews-page___en",
    path: ":page(.*)*",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/reviews/[...page].vue")
  }
]
  },
  {
    name: "reviews___fr",
    path: "/fr/reviews",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/reviews.vue"),
    children: [
  {
    name: "reviews-page___fr",
    path: ":page(.*)*",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/reviews/[...page].vue")
  }
]
  },
  {
    name: "reviews___nl",
    path: "/nl/reviews",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/reviews.vue"),
    children: [
  {
    name: "reviews-page___nl",
    path: ":page(.*)*",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/reviews/[...page].vue")
  }
]
  },
  {
    name: "reviews___de",
    path: "/de/reviews",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/reviews.vue"),
    children: [
  {
    name: "reviews-page___de",
    path: ":page(.*)*",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/reviews/[...page].vue")
  }
]
  },
  {
    name: "reviews___it",
    path: "/it/reviews",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/reviews.vue"),
    children: [
  {
    name: "reviews-page___it",
    path: ":page(.*)*",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/reviews/[...page].vue")
  }
]
  },
  {
    name: shopypetHlg2TIMeta?.name,
    path: "/en/shop",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/shop.vue"),
    children: [
  {
    name: "shop___en",
    path: "",
    meta: indexDyhZ0ztkKBMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/shop/index.vue")
  },
  {
    name: "shop-pickup___en",
    path: "pickup",
    meta: pickupMiK3F000SNMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/shop/pickup.vue")
  }
]
  },
  {
    name: shopypetHlg2TIMeta?.name,
    path: "/fr/shop",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/shop.vue"),
    children: [
  {
    name: "shop___fr",
    path: "",
    meta: indexDyhZ0ztkKBMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/shop/index.vue")
  },
  {
    name: "shop-pickup___fr",
    path: "pickup",
    meta: pickupMiK3F000SNMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/shop/pickup.vue")
  }
]
  },
  {
    name: shopypetHlg2TIMeta?.name,
    path: "/nl/shop",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/shop.vue"),
    children: [
  {
    name: "shop___nl",
    path: "",
    meta: indexDyhZ0ztkKBMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/shop/index.vue")
  },
  {
    name: "shop-pickup___nl",
    path: "pickup",
    meta: pickupMiK3F000SNMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/shop/pickup.vue")
  }
]
  },
  {
    name: shopypetHlg2TIMeta?.name,
    path: "/de/shop",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/shop.vue"),
    children: [
  {
    name: "shop___de",
    path: "",
    meta: indexDyhZ0ztkKBMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/shop/index.vue")
  },
  {
    name: "shop-pickup___de",
    path: "pickup",
    meta: pickupMiK3F000SNMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/shop/pickup.vue")
  }
]
  },
  {
    name: shopypetHlg2TIMeta?.name,
    path: "/it/shop",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/shop.vue"),
    children: [
  {
    name: "shop___it",
    path: "",
    meta: indexDyhZ0ztkKBMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/shop/index.vue")
  },
  {
    name: "shop-pickup___it",
    path: "pickup",
    meta: pickupMiK3F000SNMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/shop/pickup.vue")
  }
]
  },
  {
    name: "signup___en",
    path: "/en/signup",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/signup.vue")
  },
  {
    name: "signup___fr",
    path: "/fr/signup",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/signup.vue")
  },
  {
    name: "signup___nl",
    path: "/nl/signup",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/signup.vue")
  },
  {
    name: "signup___de",
    path: "/de/signup",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/signup.vue")
  },
  {
    name: "signup___it",
    path: "/it/signup",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/signup.vue")
  },
  {
    name: "terms-and-conditions___en",
    path: "/en/terms-and-conditions",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___fr",
    path: "/fr/terms-and-conditions",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___nl",
    path: "/nl/terms-and-conditions",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___de",
    path: "/de/terms-and-conditions",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___it",
    path: "/it/terms-and-conditions",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/terms-and-conditions.vue")
  },
  {
    name: component_45stub0S93IkJfkwMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub0S93IkJfkw
  },
  {
    name: component_45stub0S93IkJfkwMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stub0S93IkJfkw
  },
  {
    name: component_45stub0S93IkJfkwMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stub0S93IkJfkw
  },
  {
    name: component_45stub0S93IkJfkwMeta?.name,
    path: "/fr-FR-sitemap.xml",
    component: component_45stub0S93IkJfkw
  },
  {
    name: component_45stub0S93IkJfkwMeta?.name,
    path: "/nl-NL-sitemap.xml",
    component: component_45stub0S93IkJfkw
  },
  {
    name: component_45stub0S93IkJfkwMeta?.name,
    path: "/de-DE-sitemap.xml",
    component: component_45stub0S93IkJfkw
  },
  {
    name: component_45stub0S93IkJfkwMeta?.name,
    path: "/it-IT-sitemap.xml",
    component: component_45stub0S93IkJfkw
  }
]